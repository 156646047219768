import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'

import { withRouter } from "react-router-dom";

import * as globals from './globals.js'
import * as helpers from './helpers.js'

//import './index.css';


class ListCar extends Component {
   constructor(props) {
    super(props);
    this.toBook = false;

    this.handleClick = this.handleClick.bind(this);

    this.carTypeId = this.props.typeid;
    this.toBook = this.props.tobook;
  }


handleClick(event) {
   this.props.createBooking(this.carTypeId)
   this.props.bookingStepStart()
   this.props.clearBookingInfo()
   this.props.history.push('/atform')
}


 componentDidMount() {
 /*    if (this.sum>0) {
     helpers.alertMessage('Hellow to our system amount is '+this.sum);
   } else { helpers.alertMessage('Hellow to our system.') }
     setTimeout(()=>{
         helpers.closeMessage();
     }, 5000) */
  }

  render() {
  
   let content
   let cars = this.props.reduxDictionary.cars
 
   if (typeof cars === 'undefined') {
       content = <div>Loading</div>
     } 
   else {

       content = cars.filter(car => car.type_id === this.carTypeId).map( car => {
         return (<div>
                   <div class="columns">
                      <div class="column is-two-fifths">
                        <div class="centered v-centered" style={{height:"100%"}}>
                           <figure class="image is-rounded" style={{maxWidth: "30rem", height: "auto"}}>
                             <img src={car.path} />
                           </figure>
                        </div>
                      </div>
                      <div class="column">
                        <div class="card is-shadowless has-background-transparent">
                          <div class="card-header is-shadowless">
                           <h1 class="title has-text-right is-size-4-touch is-size-2-desktop list-car-font list-car-text-color">{car.name}</h1>
                          </div>
                          <div class="card-content is-shadowless">
                            <h2 class="subtitle list-car-subtitle is-size-6-touch"><b>Up to {car.number_pers} persones.</b></h2>
                            <div class="message is-dark has-background-transparent">
                               <div class="message-body" style={{textAlign:"justify",paddingLeft:"0.75rem",paddingRight:"0px"}}>
                                    &emsp;&emsp;{car.description}
                               </div>
                            </div>
                            <div class="is-size-7">{car.additional_info}</div>
                          </div>
                          
                        </div>
                      </div>
                   </div>
                </div>)
        })


       if (this.toBook) {
          content.push(<div class="container">

                      <div class="level">
                         <div class="level-left"></div>
                         <div class="level-right" style={{marginTop:"0px"}}>
                           <div class="level-item">
                            <PrintTransfPrice typeid={this.carTypeId}/>
                           </div>
                         </div>
                       </div>

                       <div class="level">
                         <div class="level-left"></div>
                         <div class="level-right">
                           <div class="level-item">

                               <button className="button is-large is-dark is-rounde is-outlined" onClick={this.handleClick}>
                                   <span class="icon is-large"><i class="fas fa-handshake"></i></span>
                                   <span>Book it now</span>
                               </button>

                           </div>
                         </div>
                       </div>

                       </div>)
       }

   
   }
 
   return ( 
       <div class="box list-car-box has-background-transparent">
        
         {content}         
        
       </div>
    )

  }
}


const mapStateToProps$ListCar = function (state) {
   return { reduxDictionary: state.dictionary }
}

const mapDispatchToProps$ListCar = function(dispatch) {
  return {
    createBooking: function(carTypeId) { dispatch(Actions.actionCreateBooking({car_type: carTypeId})) },
    bookingStepStart: function() { dispatch(Actions.actionBookingStepStart()) },
    clearBookingInfo: function() { dispatch(Actions.actionClearBookingInfo()) }
  }
}

ListCar = connect(mapStateToProps$ListCar, mapDispatchToProps$ListCar)(ListCar)


// withRouter wraps around the ListCar component to inject "history" from react router
export default withRouter(ListCar)




class PrintTransfPrice extends Component {
   constructor(props) {
    super(props);
    this.carTypeId = this.props.typeid;

    this.openLightbox = this.openLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.state = {viewerIsOpen: false}

  }

  openLightbox() {
    this.setState({viewerIsOpen: true})
  }

  closeLightbox() {
    this.setState({viewerIsOpen: false})
  }

   
  render() {

    let content
    let addOptContent
    let region=this.props.regionid || 1
    
   if (typeof this.props.reduxTransfPrice === 'undefined') {
       content = <div>Loading</div>
       return content
     }
      
      let reduxDictionary = this.props.reduxDictionary
      let transfPrice = this.props.reduxTransfPrice
      let transfTypes = this.props.reduxTransfTypes
      let AddOptPrice = this.props.reduxAddOptPrice
      let AddOpt      = this.props.reduxAddOpt
      let rowStyle={}

      let i=0
      content = transfPrice.filter(priceLine => priceLine.car_type === this.carTypeId && priceLine.region_id === region).map( priceLine => {
         i++
         let transfType = transfTypes.find( x => x.id === priceLine.ttype_id )
         let priceAZN = priceLine.price_azn
         let priceEUR = (reduxDictionary.use_current_rates != "yes") ? priceLine.price_eur : Math.ceil((priceLine.price_azn / reduxDictionary.currency_exchange_rates.eur_price) * (1 + reduxDictionary.currency_exchange_rates.add_pct/100))
         i===1 ? rowStyle={borderBottomStyle:"dashed"} : rowStyle={} 
         return (<tr style={rowStyle}><td style={{verticalAlign:"middle"}}>{transfType.name}</td> 
             <td><span class="is-size-4-touch is-size-3-desktop list-car-price-lebel">{priceEUR}&euro;</span>&nbsp; or <span class="is-size-4-touch is-size-3-desktop list-car-price-lebel">{priceAZN}<span class="ios">₼</span></span></td></tr>)
        })

      addOptContent = AddOptPrice.filter(priceLine => priceLine.car_type === this.carTypeId).map( priceLine => {
         let OptName =  AddOpt.find( x => x.opt_id === priceLine.opt_id )
         let priceAZN = priceLine.price_azn
         let priceEUR = (reduxDictionary.use_current_rates != "yes") ? priceLine.price_eur : Math.ceil((priceLine.price_azn / reduxDictionary.currency_exchange_rates.eur_price) * (1 + reduxDictionary.currency_exchange_rates.add_pct/100))
         return (<div class="is-size-7">{OptName.name}: {priceEUR}&euro; or {priceAZN}<span class="ios">₼</span></div>)
        })


      let allRegionsPrice = transfPrice.filter(priceLine => priceLine.car_type === this.carTypeId && priceLine.region_id !== region && priceLine.ttype_id === 1).map( priceLine => {
         let region = this.props.reduxRegions.find( x => x.id === priceLine.region_id ).name
         let priceAZN = priceLine.price_azn
         let priceEUR = (reduxDictionary.use_current_rates != "yes") ? priceLine.price_eur : Math.ceil((priceLine.price_azn / reduxDictionary.currency_exchange_rates.eur_price) * (1 + reduxDictionary.currency_exchange_rates.add_pct/100))
         return (<tr><td style={{verticalAlign:"middle"}}>{region}</td> 
             <td><span>{priceEUR}&euro;</span>&nbsp; or {priceAZN}<span class="ios">₼</span></td></tr>)
        })
 

   return ( 
       <div>
          <table class="list-car-price-tab"><tbody>{content}</tbody></table>


          <div class="has-text-right is-size-7" style={{margin:"0 2rem 1rem 1rem",textDecoration:"underline"}} 
              onClick={this.openLightbox}><span style={{cursor:"pointer"}}>Show prices for other destinations.</span></div>

            <div class={(this.state.viewerIsOpen) ? "modal is-active" : "modal"}>
              <div class="modal-background" onClick={this.closeLightbox}></div>
              <div class="modal-content" style={{width:"fit-content"}}>
                          
                 <div class="notification is-dark" style={{padding:"1rem",width:"fit-content"}}>
                    <div class="is-size-7-touch has-text-centered" style={{marginBottom:"0.5rem"}}><b>{this.props.reduxCars.find( x => x.type_id === this.carTypeId ).name}</b></div>
                    <div class="centered">
                      <div class="is-size-7 has-text-centered" style={{maxWidth:"200px"}}>For not listed destinations, price will be calculated on booking request.</div>
                    </div>
                    <div class="centered">
                      <table class="table has-background-transparent has-text-light is-size-7-touch"><tbody>{allRegionsPrice}</tbody></table>
                    </div>
                 </div>
            
              </div>

            </div>

          <div class="list-car-price-addopt">{addOptContent}</div>
       </div>          
    )

  }
}


const mapStateToProps$PrintTransfPrice = function (state) {
   return { reduxDictionary:  state.dictionary,
            reduxCars:        state.dictionary.cars,
            reduxRegions:     state.dictionary.az_regions,
            reduxTransfPrice: state.dictionary.transfer_price,
            reduxAddOptPrice: state.dictionary.additional_options_price,
            reduxAddOpt:      state.dictionary.additional_options,
            reduxTransfTypes: state.dictionary.transfer_types }
}


PrintTransfPrice = connect(mapStateToProps$PrintTransfPrice)(PrintTransfPrice)

