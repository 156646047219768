import React, { Component } from 'react';


class ContanctBanner extends Component {
   constructor(props) {
    super(props);

  }


  render() {
  
   return (           
           <div class={"notification has-background-transparent info-banner "+this.props.classadd}>
              <div class="has-text-centered is-size-5-desktop is-size-5-touch">Contanct us <span class="font-racing-sun is-size-4-desktop">24/7</span></div>
              <div class="has-text-centered is-size-6-desktop is-size-7-touch" style={{textDecoration:"overline"}}>We speak English, Russian and Turkish</div>
              <div class="has-text-centered is-size-3-desktop is-size-5-touch"><a style={{textDecoration:"none"}} href="tel:+994502284849"><b>+ 994 50 228 48 49</b></a></div>
              <div class="has-text-centered" style={{marginTop:"0.7rem"}}></div>
            
             <div class="columns is-mobile is-0">
                <div class="column has-text-right is-5" style={{paddingLeft:"0px",paddingRight:"0px"}}>    <a href="tel:+994502284849">
                  <span class="icon is-size-4"><i class="fas fa-mobile-alt"></i></span>
                </a></div>
                <div class="column has-text-centered is-two-thirds is-size-6-touch is-2" style={{paddingLeft:"0px",paddingRight:"0px"}}><a href="mailto:info@az-tur.com?subject=Regarding%20transfer%20services">
                <span class="icon is-size-4"><i class="far fa-envelope"></i></span> </a></div>

                <div class="column has-text-centered has-text-left is-5" style={{paddingLeft:"0px",paddingRight:"0px"}}><a href="https://wa.me/994502284849">
                  <span class="icon is-size-4"><i class="fab fa-whatsapp"></i></span>
                </a></div>
             </div>

           </div>
    )

  }
}


export default ContanctBanner



