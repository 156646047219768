export const SET_CURRENCY = 1
export const SET_SUM = 2
export const SET_VALIDITY = 3
export const ADD_POPUP = 4
export const REMOVE_POPUP = 5
export const SET_ALERT = 6
export const SET_SERVER_STATUS = 7
export const LOAD_DICTIONARY = 8
export const CREATE_BOOKING = 9
export const UPDATE_BOOKING = 10
export const BOOKING_STEP_START = 11
export const BOOKING_STEP_INC = 12
export const BOOKING_STEP_DEC = 13
export const CLEAR_BOOKING = 14
export const SET_BOOKING_INFO = 15
export const CLEAR_BOOKING_INFO = 16
export const CREATE_ONLINE_GUIDE_BOOKING = 17


export function actionSetCurrency(payload) {
  return { type: SET_CURRENCY, currency: payload }
}

export function actionSetSum(payload) {
  return { type: SET_SUM, sum: payload }
}

export function actionSetValidity(payload) {
  return { type: SET_VALIDITY, valid: payload }
}

export function actionAddPopup(payload) {
  return { type: ADD_POPUP, what: payload }
}

export function actionRemovePopup(payload) {
  return { type: REMOVE_POPUP, what: payload }
}

export function actionSetAlert(payload) {
  return { type: SET_ALERT, alert: payload }
}

export function actionSetServerStatus(payload) {
  return { type: SET_SERVER_STATUS, value: payload }
}

export function actionLoadDictionary(payload) {
  return { type: LOAD_DICTIONARY, value: payload }
}

export function actionCreateBooking(payload) {
  return { type: CREATE_BOOKING, value: payload }
}

export function actionCreateOnlineGuideBooking(payload) {
  return { type: CREATE_ONLINE_GUIDE_BOOKING, value: payload }
}

export function actionUpdateBooking(payload) {
  return { type: UPDATE_BOOKING, value: payload }
}

export function actionClearBooking() {
  return { type: CLEAR_BOOKING }
}

export function actionBookingStepStart(payload=0) {
  return { type: BOOKING_STEP_START, value: payload }
}

export function actionBookingStepInc() {
  return { type: BOOKING_STEP_INC }
}

export function actionBookingStepDec() {
  return { type: BOOKING_STEP_DEC }
}

export function actionSetBookingInfo(payload) {
  return { type: SET_BOOKING_INFO, value: payload }
}

export function actionClearBookingInfo() {
  return { type: CLEAR_BOOKING_INFO }
}
