import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import { DisappearedLoading } from 'react-loadingg';
import { withRouter } from "react-router-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {isSafari, isMobile} from 'react-device-detect';

//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'





class GalleryPage extends Component {
   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.openLightbox = this.openLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.state = {currentImage: 0, viewerIsOpen: false}

  }


  openLightbox(event, { photo, index }) {
    this.setState({currentImage: index})
    this.setState({viewerIsOpen: true})
  }

  closeLightbox() {
    this.setState({currentImage: 0})
    this.setState({viewerIsOpen: false})
  }


  render() {  

   if (! this.props.reduxDictionary) {
      return (<div style={{paddingBottom:"5rem"}} class="has-background-dark"><DisappearedLoading/></div>)
   }


 let Padding = isMobile ? "0.5rem" : "1rem" 
 let direction = isSafari ? "column" : "row"

 return ( 
       
<div style={{paddingBottom:"5rem"}} class="has-background-dark">



<section class="section" style={{paddingLeft:Padding, paddingRight:Padding}}> 

<Gallery photos={this.props.reduxDictionary.gallery} margin={isMobile ? 2 : 4} onClick={this.openLightbox} direction={direction}/>

     <ModalGateway>
        {this.state.viewerIsOpen ? (
          <Modal onClose={this.closeLightbox}>
            <Carousel
              currentIndex={this.state.currentImage}
              views={this.props.reduxDictionary.gallery.map(x => ({
                src: x.src,
                height: x.height,
                width: x.width,
                alt: x.alt,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>



</section>

</div>

    )

  }
}


const mapStateToProps$GalleryPage = function (state) {
   return { reduxDictionary: state.dictionary,
            serverReachable: state.serverStatus }
}

export default withRouter(connect(mapStateToProps$GalleryPage, null)(GalleryPage))



