import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import * as Actions from './redux-actions.js';
import request from 'request';
import { withRouter } from "react-router-dom";
import update from 'immutability-helper';
import moment from 'moment';
import Flatpickr from "react-flatpickr";


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

import "flatpickr/dist/themes/dark.css";



class DateSelector extends Component {

   constructor(props) {
    super(props);
    this.onCloseCalendar = this.onCloseCalendar.bind(this)
  }

  
  onCloseCalendar(selectedDates, dateStr, instance) {
    if (this.props.onSet) { 
        this.props.onSet(moment(selectedDates[0]).format("YYYY-MM-DD")) 
      }
  }

  
  render() {

    let errorClass
    let dD = this.props.defDate || moment().format('YYYY-MM-DD')
    
    dD = moment(dD, "YYYY-MM-DD").toDate()
    dD = moment(dD).format("DD.MM.YYYY")

    
    if (this.props.isError) {
       errorClass = "is-danger"
    }

    return( <div>
        
        <div class="control has-text-grey has-icons-left">
          <Flatpickr defaultValue={dD} className={"input has-text-grey c-date-picker " + errorClass } 
              options={{ dateFormat: "d.m.Y", minDate: this.props.minDate }} onClose={this.onCloseCalendar}/>
          <span class="icon is-small is-left"><i class="fas fa-calendar-alt"></i></span>
        </div>
        { (this.props.isError) && <Error>{this.props.errorMessage}</Error>}    

     </div>
    );

  }

}



class TimeSelector extends Component {

   constructor(props) {
    super(props);
    this.onCloseCalendar = this.onCloseCalendar.bind(this)
  }

  
  onCloseCalendar(selectedDates, dateStr, instance) {
    if (this.props.onSet) { this.props.onSet(moment(selectedDates[0]).format("HH:mm")) }
  }

  
  render() {

    let errorClass
    let dT = this.props.defTime || "15:43"

    if (this.props.isError) {
       errorClass = "is-danger"
    }


    return( <div>
        
    <div class="control has-text-grey has-icons-left">
      <Flatpickr step="60" defaultValue={dT} className={"input has-text-grey c-date-picker " + errorClass } options={{ 
        enableTime: true,
        noCalendar: true,
        minDate: this.props.minDate,
        dateFormat: "H:i",
        time_24hr: true,
        onClose: this.onCloseCalendar }} />
      <span class="icon is-small is-left"><i class="fas fa-clock"></i></span>
   </div>
      { (this.props.isError) && <Error>{this.props.errorMessage}</Error>}    


     </div>
    );

  }

}





class InputTransferType_ extends Component {

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.validateData = this.validateData.bind(this);

  }

 
  validateData() {
    if ( ! this.booking.transfer_types_selected || (this.booking.transfer_types_selected && this.booking.transfer_types_selected.length === 0 )) {
       helpers.alertMessage('At least one transfer type must be selected.', globals.WARN, true, 10000)
       return false
    }
    helpers.closeMessage()
    return true
  }

  handleNext(event) {
   if (this.validateData()) {   
     this.props.bookingStepInc()
    }
  }


  handleChange(event) {
    if (globals.ENV === 'dev') console.log('DEBUG', 'event.target.key', event.target.id)
    if ( ! this.booking.transfer_types_selected) {
        this.booking.transfer_types_selected = []
    } 

    const index = this.booking.transfer_types_selected.indexOf(Number(event.target.id));
    if ( index > -1) {
           this.booking.transfer_types_selected.splice(index, 1)      
    } else {
           this.booking.transfer_types_selected.push(Number(event.target.id))
    }

    this.booking.transfer_types_selected.sort((a,b) => {return a-b})

    this.validateData()
    this.props.updateBooking(this.booking)
    

  }

  componentDidMount() {
     // // helpers.animateBookingPage('ttypeWelcome', ['ttypeBody','ttypeButtons'], 1000)
  }
   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.mainText = 'Hi! Please choose the transfer types you want to book. :)'
   
   // "exclude" is a Object which keys are "exclude" field of transfer_types and value is array of the
   //  transfer_type.id with the same "exclude" value. It will be used to mutually exclude some transfer types.
   let exclude = {}
   this.props.reduxDictionary.transfer_types.filter(v => v.exclude != null).forEach(transfer_type => {
      if ( ! exclude[transfer_type.exclude] ) { exclude[transfer_type.exclude] = [] }
      exclude[transfer_type.exclude].push(transfer_type.id)
   })

   let selected
   let disabled
   let checkboxes = [] 

   checkboxes = this.props.reduxDictionary.transfer_types.map( transfer_type => {

      if ( ! this.booking.transfer_types_selected ) {
        selected = false
        disabled = false }
      else {
        selected = this.booking.transfer_types_selected.includes(transfer_type.id)

        
        if ( transfer_type.exclude != null && exclude[transfer_type.exclude].includes(transfer_type.id) && 
                      this.booking.transfer_types_selected.some(e => e != transfer_type.id && exclude[transfer_type.exclude].includes(e)) ) {
          disabled = true
        } else {
          disabled = false
        }
      }


      return (
          <div class="column" align="center">
            <div class="field is-grouped is-centered">
             <input class="is-checkradio has-background-color is-white is-medium" type="checkbox" key={transfer_type.id} id={transfer_type.id} onChange={this.handleChange} defaultChecked={selected} disabled={disabled}/>
             <label class="is-size-4-desktop is-size-5-touch" for={transfer_type.id}>
        
                <TransferTypeIcons type={transfer_type.id} />
                <p class="help has-text-left">{transfer_type.name}</p>

             </label>          
            </div>
          </div>  
        )

   })


  
  

 let buttons=[]

   buttons.push(
                <div id="ttypeButtons" class="level is-mobile">
                  <div class="level-left"></div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>
                )
    
   return (
     <div> 
       <div onClick={this.props.onClick}>
          <div id="ttypeWelcome" class="section"><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>
          <div id="ttypeBody" class="section">
           <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
               <div class="columns">
                 {checkboxes}
               </div>
             </div>
             <div class="column"></div>
           </div>
          </div>
       </div>

       
       <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
                    {buttons}
             </div>
             <div class="column"></div>
          </div>
       </div>

     </div>
    )

  }
}


const mapStateToProps$InputTransferType_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$InputTransferType_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const InputTransferType = connect(mapStateToProps$InputTransferType_, mapDispatchToProps$InputTransferType_)(InputTransferType_)




///////////////////////////////////////////////////////////////////////////////////////////////



class InputClientInfo_ extends Component {
 
   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    
    this.saveData = this.saveData.bind(this);
    this.validateData = this.validateData.bind(this);

    this.state = {full_name: true,
                  mail: true,
                  phone: true}

  }
 

  validateData(what, value) {
    let obj={}

    if (what) {
       obj[what] = helpers.validate(what, value)
       this.setState(obj)
       return obj[what]
    }
    
    let returnValue = true
    Object.keys(this.state).forEach((k) => {
      if (helpers.validate(k,this.booking.client[k])) {
        obj[k] = true
      } else {
        obj[k] = false
        returnValue = false
      }
    })

    this.setState(obj)
    
    return returnValue

  }

  saveData() {
     this.booking.client = {full_name: this.nameInputField.value,
                            mail: this.mailInputField.value,
                            phone: this.phoneInputField.value,
                            country: this.countryInputField.value}
  }

  handleNext(event) {
     this.saveData()
     if (this.validateData()) {this.props.updateBooking(this.booking); this.props.bookingStepInc()}
  }

  handlePrev(event) {
     this.saveData()
     if (this.validateData()) {this.props.updateBooking(this.booking); this.props.bookingStepDec()}
  }


  componentDidMount() {
     // helpers.animateBookingPage('cinfoWelcome', ['cinfoBody','cinfoButtons'], 1000)
  }
   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.mainText = 'We will need some information about you :)'

   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }



   let content = []
   let content_view = []
   let defName
   let defMail
   let defPhone
   let defCountry

   defCountry = window.sessionStorage.getItem('client_country_code') || null
   

   if (typeof this.booking.client !== 'undefined') {
   
       if (this.booking.client.full_name) {
         defName = this.booking.client.full_name
        } else {
         defName = null
        }

       if (this.booking.client.mail) {
         defMail = this.booking.client.mail
        } else {
         defMail = null
        }

       if (this.booking.client.phone) {
         defPhone = this.booking.client.phone
        } else {
         defPhone = null
        }

       if (this.booking.client.country) {
         defCountry = this.booking.client.country
        } 

   }


   let select = this.props.reduxDictionary.countries.map((c) => {
      return (<option key={c.code} value={c.code}>{c.name}</option>)
   })
   



  if (this.props.view_mode) {

  content_view.push(


<div class="columns">

<div class="column"></div>
<div class="column is-three-quarters">


<div onClick={this.props.onClick} style={{cursor: "pointer"}}>

<div class="columns is-gapless">
<div class="column">

<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Full name</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defName}</span>
  </div>
</div>


<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Email</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defMail}</span>
  </div>
</div>


</div>
<div class="column">

<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Phone number</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defPhone}</span>
  </div>
</div>


<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Country</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{this.props.reduxDictionary.countries.find(c=>c.code===defCountry).name}</span>
  </div>
</div>


</div>

</div>
</div>



</div>
<div class="column"></div>

</div>

)

   } else {

               
   content.push(<div class="columns is-desktop">
                   
                   <div class="column">
                     <div class="columns">
                
                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Your full name</label>
                             <div class="control has-text-grey has-icons-left">
                                <input class={"input " + (this.state.full_name ? "" : "is-danger")} type="text" 
                                   defaultValue={defName} ref={(x) => { this.nameInputField = x; }}
                                   onChange={(e)=>this.validateData('full_name',e.target.value)}/>
                                <span class="icon is-small is-left"><i class="fas fa-user"></i></span>
                             </div>
                             {! this.state.full_name && <Error>We cannot book you without name information.</Error>}
                           </div>
                        </div>
       
                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Your email</label>
                             <div class="control has-text-grey has-icons-left">
                                <input class={"input " + (this.state.mail ? "" : "is-danger")} type="email"  
                                   defaultValue={defMail} ref={(x) => { this.mailInputField = x; }}
                                   onChange={(e)=>this.validateData('mail',e.target.value)}/>
                                <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
                             </div>
                             {! this.state.mail && <Error>Email is very important, we will send confirmations to it. Enter correct email address.</Error>}
                           </div>
                        </div>
   
                     </div>
                   </div>

                   <div class="column">
                     <div class="columns">

                       <div class="column">
                        <div class="field" style={{minWidth: "15rem"}}>
                          <label class="label has-text-white-ter">Your phone number</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.phone ? "" : "is-danger")} type="tel"  
                                  defaultValue={defPhone} ref={(x) => { this.phoneInputField = x; }}
                                  onChange={(e)=>this.validateData('phone',e.target.value)}/>
                               <span class="icon is-small is-left"><i class="fas fa-phone-volume"></i></span>
                            </div>
                            {! this.state.phone && <Error>Phone number is important. Enter correct phone number please.</Error>}
                          </div>
                       </div>
      
                       <div class="column">
                        <div class="field" style={{minWidth: "15rem"}}>
                          <label class="label has-text-white-ter">Your country</label>
                            <div class="control has-icons-left">
                               <div class="select has-text-grey is-fullwidth" style={{margin: "0px"}}>
                                 <select defaultValue={defCountry} ref={(x) => { this.countryInputField = x; }}>
                                  {select}
                                 </select>
                               </div>
                               <div class="icon is-small is-left"><i class="fas fa-globe"></i></div>
                            </div>
                          </div>
                       </div>
                    
                    </div>
                  </div>

           </div>)

  }


  let buttons=[]

  if (this.props.edit_mode === "step") {

        buttons.push(
           <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

                <div id="cinfoButtons" class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handlePrev}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>

            </div>
             <div class="column"></div>
          </div>
       </div>

                )

  } else if (!this.props.view_mode) {

    buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.saveData(e); if (this.validateData()) {this.props.updateBooking(this.booking); this.props.onViewMode()}}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )
  }



   content = (<div style={containerStyle}>

          <div id="cinfoWelcome" class={"section "+isHidden}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id="cinfoBody" class="section">
           <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
                 {content}
             </div>
             <div class="column"></div>
           </div>
          </div>

    </div>)



   return ( 
     <div>  

      { this.props.view_mode && content_view || content }
      
      {buttons}
       
     </div>

    )

  }
}


const mapStateToProps$InputClientInfo_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$InputClientInfo_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const InputClientInfo = connect(mapStateToProps$InputClientInfo_, mapDispatchToProps$InputClientInfo_)(InputClientInfo_)





///////////////////////////////////////////////////////////////////////////////////////////////



class SpecialNotes_ extends Component {
   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    
    this.saveData = this.saveData.bind(this);
  }


  saveData() {
     this.booking.special_notes = this.notesInputField.value
     this.props.updateBooking(this.booking)
  }
 
  handleNext(event) {
     this.saveData()
     this.props.bookingStepInc()
  }

  handlePrev(event) {
     this.saveData()
     this.props.bookingStepDec()
  }

  handleChange(event) {
    if (globals.ENV === 'dev') console.log('DEBUG', 'dropdown', event)
     
  }

  componentDidMount() {
    if (!this.props.view_mode) {
      // helpers.animateBookingPage('snotesWelcome', ['snotesBody','snotesButtons'], 1000)
    }
  }

   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.mainText = 'Do you have any special notes for us?'
   
   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }

   let content = []
   let content_view = []
   let defNotes
  
   if (typeof this.booking.special_notes !== 'undefined') {
   
       if (this.booking.special_notes) {
         defNotes = this.booking.special_notes
        } else {
         defNotes = null
        }

   }


   if (this.props.view_mode) {

     content_view.push(
                     
                     <div class="columns">

                      <div class="column" style={{padding:0}}></div> 
                      <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:"1px"}}>

                         <div class="container" onClick={this.props.onClick} style={{cursor: "pointer"}} align="center">

                         <div class="field is-centered" style={{margin:"1rem", maxWidth: "35rem"}}>
                           <div class="field-label is-normal">
                             <label class="label has-text-white-ter" align="left">Special notes from you</label>
                           </div>
                           <div class="field-body">
                              <textarea readonly class="textarea is-outlined has-text-left is-normal is-expanded has-background-light has-text-dark" 
                                  style={{minWidth: "10rem"}}>{defNotes}</textarea>
                           </div>

                         </div>
                        </div>


                     </div>
                   <div class="column" style={{padding:0}}></div>

                  </div>
                            )


   } else {   
   content.push(

                        <div class="column" align="center">
                         <div class="container">
                         <div class="field is-centered" style={{maxWidth: "30rem"}}>
                           <label class="label has-text-white-ter" align="left">Your notes</label>
                             <div class="control has-text-grey">
                                <textarea class="textarea" type="text"  defaultValue={defNotes} ref={(x) => { this.notesInputField = x; }}/>
                             </div>
                           </div>
                        </div>
                        </div>)

  }

  let buttons=[]

  if (this.props.edit_mode === "step") {
     
             buttons.push(
       <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

                <div id="snotesButtons" class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handlePrev}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>
             </div>
             <div class="column"></div>
          </div>
       </div>

                )

  } else if (!this.props.view_mode) {

    buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.saveData(e); this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )

  }


   content = (
     <div style={containerStyle}>

          <div id="snotesWelcome" class={"section "+isHidden}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id="snotesBody" class="section">
           <div class="columns is-gapless is-centered">
                 {content}
           </div>
          </div>

       </div>
    )

    
   return ( 
       
       <div>

        { this.props.view_mode && content_view || content }
      
        {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$SpecialNotes_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$SpecialNotes_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const SpecialNotes = connect(mapStateToProps$SpecialNotes_, mapDispatchToProps$SpecialNotes_)(SpecialNotes_)




///////////////////////////////////////////////////////////////////////////////////////////////



class AdditionalOptions_ extends Component {
   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    
    this.saveData = this.saveData.bind(this);
  }


  saveData(optId, event) {
     let added=false
     if (!event.target.checked) {

         this.booking.additional_options.splice(this.booking.additional_options.indexOfObject({car_type: this.props.car_type, opt_id: optId}),1)

/*         this.booking.additional_options=this.booking.additional_options.map(i=>{
            if (i.car_type!==this.props.car_type && i.opt_id!==optId) {
              return {car_type: i.car_type, opt_id: i.opt_id}
            }
         })*/

     } else {
         this.booking.additional_options=this.booking.additional_options.map(i=>{
            if (i.car_type===this.props.car_type && i.opt_id===optId) {
              added=true
              return {car_type: this.props.car_type, opt_id: optId}
            } else {
              return {car_type: i.car_type, opt_id: i.opt_id}
            }
         })
    
         if (!added) {
            this.booking.additional_options.push({car_type: Number(this.props.car_type), opt_id: Number(optId)})
         }
     }

     this.props.updateBooking(this.booking)
  }
 
   
  render() {
   this.booking = JSON.parse(JSON.stringify(this.props.reduxBooking))


   if (! this.booking.additional_options || this.booking.additional_options.length === 0) {
       this.booking.additional_options=[]     
     }


   let car = this.props.reduxDictionary.cars.find(i=>i.type_id===this.props.car_type).name
   if (!car) { return null }

   this.mainText = 'Additional options'
   
   let content = []
   let options = []

   if (this.props.view_mode && typeof this.booking.additional_options !== 'undefined') {

      if (this.booking.additional_options.length === 0) { 
        return <div onClick={this.props.onClick}>No additional options was selected</div> 
      }

    options = this.booking.additional_options.filter(i=>i.car_type===this.props.car_type).map(opt=>{
       return (<div>
           <span>{this.props.reduxDictionary.additional_options.find(i=>i.opt_id===opt.opt_id).name}</span>
        </div>)
    })


   } else {   


   options = this.props.reduxDictionary.additional_options_price.filter(i=>i.car_type===this.props.car_type).map(opt=>{
      return (

             <div class="field is-grouped">
               <input class="is-checkradio has-background-color is-white is-small" type="checkbox" key={opt.opt_id+'-'+opt.car_type} 
                  id={"opt-"+opt.opt_id} onChange={(e)=>{this.saveData(opt.opt_id,e)}} 
                  defaultChecked={this.booking.additional_options.find(i=>i.opt_id===opt.opt_id && i.car_type===this.props.car_type)} />
               <label class="is-size-6" for={"opt-"+opt.opt_id} style={{paddingTop:"unset"}}>
                 <span>{this.props.reduxDictionary.additional_options.find(i=>i.opt_id===opt.opt_id).name}</span>
                 <span class="tag" style={{marginLeft: "5px"}}>{opt.price_azn+'AZN'}</span>
               </label>          
             </div>

            )

   })

  }


   if ( options.length === 0) { return null }
    
   return ( 
       
       <div style={{paddingTop:"20px",paddingBottom:"20px"}}>

          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

               <div class="notification is-link" style={{opacity:"80%"}}>
                 <h4 class="title is-size-5-desktop is-size-6-touch">{this.mainText}</h4>
                 
                  <div class="is-size-6-touch" style={{paddingBottom:"1rem"}}>You can select some of the available options for {car} for the additional price.</div>

                 {options}
               </div>

             </div>
             <div class="column"></div>
           </div>

         
       </div>
    )

  }
}


const mapStateToProps$AdditionalOptions_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$AdditionalOptions_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const AdditionalOptions = connect(mapStateToProps$AdditionalOptions_, mapDispatchToProps$AdditionalOptions_)(AdditionalOptions_)



///////////////////////////////////////////////////////////////////////////////////////////////




class Simcards_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);

   // this.pushChanges = this.pushChanges.bind(this);    
    this.saveData = this.saveData.bind(this);
    this.checkboxSimcards = []
    this.countSimcards = []
    this.displaySum = []
  }


  saveData() {
     this.booking.simcards = []
     this.checkboxSimcards.forEach((checkbox, simcard_id) => {
        if (checkbox.checked) {
           this.booking.simcards.push({id: Number(simcard_id), count: Number(this.countSimcards[simcard_id].value)})
        } 
     })
     this.props.updateBooking(this.booking)
  }
 
  handleNext(event) {
     this.saveData()
     this.props.bookingStepInc()
  }

  handlePrev(event) {
     this.saveData()
     this.props.bookingStepDec()
  }


  componentDidMount() {
     if (!this.props.view_mode) { 
       // helpers.animateBookingPage('simsWelcome', ['simsBody','simsButtons','simsInfo'], 1000)
     }
  }

   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.dictionary = Object.assign({}, this.props.reduxDictionary)
   this.mainText = 'We can offer you local simcards with internet'
   
   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }


   let content = []
   let content_view = []
   let defName

   // Oracle simcards by id
   this.dictionary.sim_cards.sort((a,b)=>{
    if (a.id < b.id) {
     return -1;
    }
    if (a.id > b.id) {
     return 1;
    }
     return 0;
   })


   if (typeof this.booking.simcards !== 'undefined') {
   
       if (this.booking.simcards.full_name) {
         defName = this.booking.simcards.full_name
        } else {
         defName = null
        }

   }

   let Booking = this.booking 

   function getSelectedSimcardByID(simcard_id) {
     if (typeof Booking.simcards === 'undefined') { return false }
  
     let simcard = Booking.simcards.find(e=>e.id===simcard_id)
     if (simcard !== undefined) {
        return simcard
     } else {
        return false
     }
         
   }

   let dictionary_ = this.dictionary
   
   function getDictionarySimcardByID(simcard_id) {
     if (typeof dictionary_.sim_cards === 'undefined') { return false }
  
     let simcard = dictionary_.sim_cards.find(e=>e.id===simcard_id)
     if (simcard !== undefined) {
        return simcard
     } else {
        return false
     }
         
   }


   function calculateSum(curr) {
      if (typeof Booking.simcards === 'undefined') { return "" }
      let SumAzn = 0
      let SumEur = 0
      let Cost
      Booking.simcards.forEach((c, idx) => {
         Cost = dictionary_.sim_cards.find(f=>f.id===c.id)
         SumAzn += Number(c.count) * Number(Cost.price_azn)
         SumEur += Number(c.count) * Number((dictionary_.use_current_rates != "yes") ? Cost.price_eur : Math.ceil((Cost.price_azn / dictionary_.currency_exchange_rates.eur_price) * (1 + dictionary_.currency_exchange_rates.add_pct/100)))
      })
      if (curr === 'AZN') {
        return SumAzn
      } else if (curr === 'EUR') {
        return SumEur
      }
      
   }



   let fieldset

   if (this.props.view_mode && typeof Booking.simcards !== 'undefined' ) {


       if (Booking.simcards.length === 0) { 
        content_view.push(
          <div class="field is-centered" style={{paddingTop:"50px", paddingBottom:"50px"}}>          

            <div style={{display:"table",height:"100%", margin:"5px"}}><div style={{display:"table-cell",verticalAlign:"middle"}}>
             <div class="tags">
              <span class="is-outlined is-white is-medium tag is-rounded has-text-info">No Simcards selected. Click to select.</span>
             </div>
            </div></div>

           </div>
          )

      } else {

      fieldset = Booking.simcards.map((c) => {
      return (
        <tr key={c.id}>
           <td>
              <label>
                    {getDictionarySimcardByID(c.id).name}
              </label>
           </td>
           <td>
              <span>{getDictionarySimcardByID(c.id).price_azn + ' AZN/' + ((dictionary_.use_current_rates != "yes") ? getDictionarySimcardByID(c.id).price_eur : Math.ceil((getDictionarySimcardByID(c.id).price_azn / dictionary_.currency_exchange_rates.eur_price) * (1 + dictionary_.currency_exchange_rates.add_pct/100))) + ' EUR'}</span>
           </td>
           <td>
              <label>
                {c.count}
              </label>
           </td>
           <td>
              <span>{c.count * getDictionarySimcardByID(c.id).price_azn }</span>
           </td>
           <td>
              <span>{c.count * (dictionary_.use_current_rates != "yes") ? getDictionarySimcardByID(c.id).price_eur : Math.ceil((getDictionarySimcardByID(c.id).price_azn / dictionary_.currency_exchange_rates.eur_price) * (1 + dictionary_.currency_exchange_rates.add_pct/100)) }</span>
           </td>
        </tr>
        )
   })

      


   content_view.push(

                        <div align="center">

                         <div class="field is-centered" style={{paddingTop:"50px",paddingBottom:"50px",maxWidth: "70rem"}}>
                             <label class="label has-text-white-ter">Simcards selected</label>
                             <fieldset ref={(x) => { this.fieldSet = x; }}>
                              <table class="table" style={{backgroundColor: "unset", color:"unset"}}>
                               <thead>
                                 <tr>
                                 <th></th><th style={{color:"white",padding:"4px"}}>Price</th><th style={{color:"white",padding:"4px"}}>Count</th>
                                 <th style={{color:"white",padding:"4px"}}>AZN</th><th style={{color:"white",padding:"4px"}}>EUR</th>
                                 </tr>
                               </thead>
                               <tbody>
                                   {fieldset}
                               <tr class="is-size-6-touch is-size-5-desktop"><td></td><td></td>
                                  <td><b>Total:</b></td>
                                  <td style={{fontFamily:"Source Code Pro"}}><b><span>{calculateSum('AZN')}</span></b></td>
                                  <td style={{fontFamily:"Source Code Pro"}}><span>{calculateSum('EUR')}</span></td></tr>
                               </tbody></table>
                             </fieldset>

                           </div>
                        </div>

                   
        )

  }


  content_view = (
                     <div class="columns">

                      <div class="column" style={{padding:0}}></div> 
                      <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:"1px"}}>

                         <div class="container" onClick={this.props.onClick} style={{cursor: "pointer"}} align="center">

                         {content_view}

                        </div>


                     </div>
                   <div class="column" style={{padding:0}}></div>

                  </div>

    )



   } else {



    let selectCount = []
    let i
    for (i=1; i<=20; i++) {
      selectCount.push(<option key={i} value={i}>{i}</option>)
    }

 
    fieldset = this.dictionary.sim_cards.map((c) => {
      return (
        <tr key={c.id}>
           <td style={{padding:"4px"}}>

             <div class="field is-grouped">
               <input class="is-checkradio has-background-color is-white is-small" type="checkbox" name={c.id} key={c.id} id={"sim-"+c.id} ref={(x) => { this.checkboxSimcards[c.id] = x; }} onChange={(e)=>{this.saveData()}} defaultChecked={getSelectedSimcardByID(c.id)} />
               <label class="is-size-6-desktop is-size-7-touch" for={"sim-"+c.id} style={{paddingTop:"unset"}}>{c.name}</label>          
             </div>

       
           </td>
           <td style={{padding:"4px",verticalAlign:"middle",fontFamily:"Source Code Pro"}}>
              <span class="is-size-6-desktop"><b>{c.price_azn + ' AZN'}</b></span><span class="tag is-size-7-desktop" style={{marginLeft:"0.5rem"}}>{(this.dictionary.use_current_rates != "yes") ? c.price_eur : Math.ceil((c.price_azn / this.dictionary.currency_exchange_rates.eur_price) * (1 + this.dictionary.currency_exchange_rates.add_pct/100)) + ' EUR'}</span>
           </td>
           <td style={{padding:"4px",verticalAlign:"middle"}}>

                 <div class="select has-text-grey is-small" style={{width: "100%", margin: "0px", fontFamily:"Source Code Pro"}}>
                                 <select disabled={!getSelectedSimcardByID(c.id)} 
                                         defaultValue={getSelectedSimcardByID(c.id) && getSelectedSimcardByID(c.id).count || 1} 
                                         ref={(x) => { this.countSimcards[c.id] = x; }} onChange={(e)=>{this.saveData()}}>
                                          {selectCount}
                                 </select>
                               </div>          
          
           </td>
           <td style={{padding:"4px",verticalAlign:"middle",fontFamily:"Source Code Pro"}}>
              <span class="is-size-6-touch is-size-4-desktop" ref={(x) => { this.displaySum[c.id] = x; }}>{getSelectedSimcardByID(c.id) && (getSelectedSimcardByID(c.id).count * c.price_azn) }</span>
           </td>
           <td style={{padding:"4px",verticalAlign:"middle",fontFamily:"Source Code Pro"}}>
              <span class="is-size-7-touch is-size-5-desktop" ref={(x) => { this.displaySum[c.id] = x; }}>{getSelectedSimcardByID(c.id) && (getSelectedSimcardByID(c.id).count * ((this.dictionary.use_current_rates != "yes") ? c.price_eur : Math.ceil((c.price_azn / this.dictionary.currency_exchange_rates.eur_price) * (1 + this.dictionary.currency_exchange_rates.add_pct/100)))) }</span>
           </td>
        </tr>
        )
   })

   

        content.push(

                        <div align="center">

                         <div class="field is-centered" style={{maxWidth: "70rem"}}>
                             <fieldset ref={(x) => { this.fieldSet = x; }}>
                              <table class="table" style={{backgroundColor: "unset", color:"unset"}}>
                               <thead>
                                 <tr>
                                 <th></th><th style={{color:"white",padding:"4px"}}>Price</th><th style={{color:"white",padding:"4px"}}>Count</th>
                                 <th style={{color:"white",padding:"4px"}}>AZN</th><th style={{color:"white",padding:"4px"}}>EUR</th>
                                 </tr>
                               </thead>
                               <tbody>
                                   {fieldset}
                               <tr class="is-size-6-touch is-size-4-desktop"><td></td><td></td>
                                  <td><b>Total:</b></td>
                                  <td style={{fontFamily:"Source Code Pro"}}><b><span>{calculateSum('AZN')}</span></b></td>
                                  <td style={{fontFamily:"Source Code Pro"}}><span>{calculateSum('EUR')}</span></td></tr>
                               </tbody></table>
                             </fieldset>

                           </div>
                        </div>

                   
        )




   }
  

   


  let buttons=[]


  if (this.props.edit_mode === "step") {

             buttons.push(
        <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

                <div id="simsButtons" class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handlePrev}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>

             </div>
             <div class="column"></div>
          </div>
       </div>

                )


  } else if (!this.props.view_mode) {

    buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons is-centered">
          <button className="button is-rounded is-danger" onClick={(e)=>{this.saveData(e); this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )

  }


  content = (
    <div style={containerStyle}>

          <div id="simsWelcome" class={"section "+isHidden}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>
          <p id="simsInfo" class={isHidden}>Just click on next if you dont want any.</p>

          <div id="simsBody" class="section">
           <div class="columns is-gapless is-centered">
               <div class="column"></div>
               <div class="column is-two-thirds">
                     <div class="notification is-link" style={{opacity:"70%"}}>
                        Our driver will bring ready to use simcards to the airport or other pickup location. It is very convinient for you. <strong>Note, that the copy of the passport is
                        required to register simcards.</strong> You can send it to us by email as reply to the booking confirmation email.
                    </div>

                 {content}


               </div>
               <div class="column"></div>

           </div>
          </div>

       </div>

    )

    
   return ( 
       
       <div>
       
        { this.props.view_mode && content_view || content }
         

        {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$Simcards_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$Simcards_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const Simcards = connect(mapStateToProps$Simcards_, mapDispatchToProps$Simcards_)(Simcards_)




///////////////////////////////////////////////////////////////////////////////////////////////



class BookingSumme_ extends Component {

   constructor(props) {
    super(props);   
  }

   
  render() {
   let Dictionary = Object.assign({}, this.props.reduxDictionary)
   let Sum

   if (typeof this.props.reduxBooking.SUM === 'undefined') {
      return null
   } else {
      Sum = this.props.reduxBooking.SUM
   }
  
   let content = []
   let content_footer = []

   let transfer
   let transfer_price_azn
   let transfer_price_eur
   let transfer_name

   function sum_mid_row(col1,col2,col3) {
     return (<tr>
     <td>
       <span>{col1}</span>
     </td>
     <td style={{fontFamily:"Source Code Pro"}}>
       <span>{col2}</span>
     </td>
     <td style={{fontFamily:"Source Code Pro"}}>
      <span>{col3}</span>
     </td>
    </tr>
    )
   }

  function sum_footer(col1, col2, col3) {
     return (<tr class="is-size-6-touch is-size-5-desktop">
     <th class="has-text-white-ter" style={{fontFamily:"Source Code Pro"}}>
       <span>{col1}</span>
     </th>
     <th class="has-text-white-ter" style={{fontFamily:"Source Code Pro"}}>
       <span>{col2 + ' AZN'}</span>
     </th>
     <th class="has-text-white-ter" style={{fontFamily:"Source Code Pro"}}>
      <span>{col3 + ' EUR'}</span>
     </th>
    </tr>
    )
   }

   let sum_empty_row = (
     <tr>
       <td><span>&nbsp;</span>
     </td>
       <td><span>&nbsp;</span>
     </td>
       <td><span>&nbsp;</span>
     </td>
    </tr> )


   //Sum.transfer_prices.forEach(async (transfer) => {
   for (var x = 0; x < Sum.transfer_prices.length; x++) {
     transfer=Sum.transfer_prices[x]
     transfer_name = Dictionary.transfer_types.find(t=>t.id===transfer.transfer_type_id).name
    if (transfer.price_to) {
       if (transfer.price_to === -1) {
          transfer_price_azn = '-'
          transfer_price_eur = '-'
       } else {
          transfer_price_azn = transfer.price_to.AZN
          transfer_price_eur = transfer.price_to.EUR
       }
       
      content.push(sum_mid_row(transfer_name,transfer_price_azn,transfer_price_eur))
    }

    if (transfer.price_from) {
       transfer_name = ' + Return trip'
       if (transfer.price_from === -1) {
          transfer_price_azn = '-'
          transfer_price_eur = '-'
       } else {
          transfer_price_azn = transfer.price_from.AZN
          transfer_price_eur = transfer.price_from.EUR
       }
       
      content.push(sum_mid_row(transfer_name,transfer_price_azn,transfer_price_eur)) 
    }

  }


  if (Sum.simcards) {
     if (Sum.simcards.AZN > 0) {
      var isSimcards = true
      content.push(sum_empty_row)
      content.push(sum_mid_row('SimCards price',Sum.simcards.AZN,Sum.simcards.EUR))
     }  
  }

  if (Sum.additional_options) {
     if (Sum.additional_options.AZN > 0) {
      if (!isSimcards) {content.push(sum_empty_row)}
      content.push(sum_mid_row('Additional options price',Sum.additional_options.AZN,Sum.additional_options.EUR))
     }
  }

  if (Sum.manual_price_calc === 'NO') {
    content_footer.push(sum_footer('Total amount to pay',Sum.grand_total.AZN,Sum.grand_total.EUR))
  } else {
    content_footer.push(sum_footer('Total price will be calculated','-','-'))
  }

  content_footer=(<tfoot>
                     {content_footer}
                  </tfoot>)

  content = (<table class="table has-text-white-ter is-fullwidth" style={{backgroundColor:"transparent",maxWidth:"800px"}}>
               <thead>
                  <tr><th></th><th class="has-text-white-ter">AZN</th><th class="has-text-white-ter">EUR</th></tr>
               </thead>
               <tbody>
                  {content}
               </tbody>
               {content_footer}
    </table>)


    
   return ( 
       
         <div style={{paddingTop:"20px",paddingBottom:"20px"}}>

          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

               <div class="notification is-success" style={{opacity:"90%"}}>
                 <h4 class="title is-size-5-desktop is-size-6-touch">Price summary</h4>
                 <div style={{display: "flex", justifyContent: "center"}}>
                   {content}
                 </div>
               </div>

             </div>
             <div class="column"></div>
           </div>

         
       </div>
  )

  }
}


const mapStateToProps$BookingSumme_ = function (state) {
   return { reduxBooking: state.booking, 
            reduxDictionary: state.dictionary }
}



export const BookingSumme = connect(mapStateToProps$BookingSumme_)(BookingSumme_)




///////////////////////////////////////////////////////////////////////////////////////////////



class PaymentOptions_ extends Component {

   constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    this.booking.payment_option = event.target.value
    this.props.updateBooking(this.booking)
  }
   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   let Dictionary = this.props.reduxDictionary
   let content = []

   
   if (this.booking.SUM) {
      if (this.booking.SUM.manual_price_calc === 'YES') {
      // Only cache payment possible
      if (this.booking.payment_option !== 'cash') {
         this.booking.payment_option = 'cash'
         this.props.updateBooking(this.booking)        
      }

      content.push(<table><tbody><tr><td>We will calculate the best possible price for your selected destinations and will send
                      it to your email. If you will accept the price then you can pay online with credit card or just by cash to the driver.</td></tr></tbody></table>)

      } else {
      // Card payment possible

      if (! this.booking.payment_option) {
         this.booking.payment_option = 'cash'
         this.props.updateBooking(this.booking)
      }


      content.push(<table><tbody>
         <tr><td style={{paddingBottom:"1rem"}}><span>How do you want to pay?</span></td></tr>
         <tr><td>
         <div class="field is-grouped" style={{marginBottom:"0px"}}>
           <input type="radio" class="is-checkradio is-normal is-white" id="pay-cash" name="payment" value="cash" defaultChecked={this.booking.payment_option === 'cash'} onChange={this.handleChange}/>
           <label htmlFor="pay-cash"><b>Cash to the driver</b></label>
         </div>
          <article class="message is-dark" style={{marginLeft:"2rem"}}>
            <div class="message-body is-size-7-touch">
               You will pay to the driver directly by cash. Payment can be in Azerbaijan manats (AZN), euro or dollar equivalent.
            </div>
          </article>
         <div class="field is-grouped" style={{marginBottom:"0px"}}>
           <input type="radio" class="is-checkradio is-normal is-white" id="pay-card" name="payment" value="card" defaultChecked={this.booking.payment_option === 'card'} onChange={this.handleChange}/>
           <label htmlFor="pay-card"><b>Online with credit card</b></label><br/>
         </div>
         <article class="message is-dark" style={{marginLeft:"2rem"}}>
            <div class="message-body is-size-7-touch">
               More convinient way for you. Please note that to keep our prices as low as possible <b style={{color:"red"}}>payments by credit cards are not refundable!</b>
               &nbsp;We will refund only in case of our failure, if there will be something wrong on our side.
            </div>
          </article>

         </td></tr></tbody></table>
         )
      }
   }


   return ( 
       
         <div style={{paddingTop:"20px",paddingBottom:"20px"}}>

          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

               <div class="notification is-danger" style={{opacity:"80%"}}>
                 <h4 class="title is-size-5-desktop is-size-6-touch">Payment</h4>

                 {content}
               </div>

             </div>
             <div class="column"></div>
           </div>

         
       </div>
    )

  }
}


const mapStateToProps$PaymentOptions_ = function (state) {
   return { reduxBooking: state.booking,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$PaymentOptions_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) }
       }
}


export const PaymentOptions = connect(mapStateToProps$PaymentOptions_, mapDispatchToProps$PaymentOptions_)(PaymentOptions_)





///////////////////////////////////////////////////////////////////////////////////////////////





class Summary_ extends Component {
   constructor(props) {

    window.scrollTo(0, 0);

    super(props);
    this.setEditMode = this.setEditMode.bind(this);
    this.setViewMode = this.setViewMode.bind(this);

//    this.handleClick = this.handleClick.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    
    this.saveData = this.saveData.bind(this);
    this.CancelBooking = this.CancelBooking.bind(this);

    this.BookingInfo = {booking_id: null,
                        client_email: null,
                        payment_option: null,
                        amount_to_pay_azn: null}

    this.state = {ClinetInfoViewMode: true,
                  SpecialNotesViewMode: true,
                  SimcardsViewMode: true}
  }


  saveData() {
     this.props.updateBooking(this.booking)
  }
 
  CancelBooking() {
     this.props.clearBooking()
     this.props.history.push('/#top')
  }

  handleSubmit(event) {
      helpers.postAPI(
         { func: function(resp) {
              //After receiving response from server
              if (resp.status === 'OK') {
                 this.BookingInfo = {booking_id: resp.booking_id,
                                     client_email: this.booking.client.mail,
                                     payment_option: this.booking.payment_option,
                                     amount_to_pay_azn: (this.booking.SUM.manual_price_calc === 'NO') ? this.booking.SUM.grand_total.AZN : null}
                 this.props.setBookingInfo(this.BookingInfo)

                 if (this.booking.payment_option === 'cash') {
                       this.props.history.replace('/bookcf')                       
                 } else {
                       this.props.history.replace('/payme')
                 }
                       
                 // Destroy Booking from State
                 this.props.clearBooking()

              }
          }.bind(this), 
           uri: globals.myAPIurl+'/bookit',
           data: this.booking
         })
  }

  handlePrev(event) {
     this.saveData()
     this.props.bookingStepDec()
  }

  setEditMode(id, event) {
    console.log('EVENT-2', event)
    event.screenX=100
    switch (id) {
      case 'ClientInfo':
       this.setState({ClinetInfoViewMode: false})
       break;
      case 'SpecialNotes':
       this.setState({SpecialNotesViewMode: false})
       break;
      case 'Simcards':
       this.setState({SimcardsViewMode: false})
       break;
    }
  }
  
  setViewMode(id, event) {
    console.log('EVENT', event)
    switch (id) {
      case 'ClientInfo':
       this.setState({ClinetInfoViewMode: true})
       break;
      case 'SpecialNotes':
       this.setState({SpecialNotesViewMode: true})
       break;
      case 'Simcards':
       this.setState({SimcardsViewMode: true})
       break;
    }
  }
  

  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)

   let content = []

   this.mainText = 'Your booking summary'
   
   let destinations = []

   destinations = this.booking.transfer_types_selected.map((ttype) => {
      return <Destination edit_mode="inline" view_mode="true" transf_type_id={ttype}/>
   })

   // <input type="text"  defaultValue={defName} ref={(x) => { this.nameInputField = x; }}/>

   //let Sum = helpers.calculateBookingSum()
   //this.booking.SUM = Sum

   content.push(<div>
 
           <CarSelector />
             
           <InputClientInfo edit_mode="inline" view_mode={this.state.ClinetInfoViewMode} onClick={(e)=>{this.setEditMode('ClientInfo',e)}} 
                                                                      onViewMode={(e)=>{this.setViewMode('ClientInfo',e)}} />
   
           {destinations}

           <SpecialNotes edit_mode="inline" view_mode={this.state.SpecialNotesViewMode} onClick={(e)=>{this.setEditMode('SpecialNotes',e)}} 
                                                                      onViewMode={(e)=>{this.setViewMode('SpecialNotes',e)}} />

           <Simcards edit_mode="inline" view_mode={this.state.SimcardsViewMode} onClick={(e)=>{this.setEditMode('Simcards',e)}} 
                                                                      onViewMode={(e)=>{this.setViewMode('Simcards',e)}} />

           <AdditionalOptions edit_mode="inline" car_type={this.booking.car_type}/>

           <BookingSumme />

           <PaymentOptions />

           </div>)


   let buttons = (

      <div class="columns is-mobile">

        <div class="column">

        <div class="buttons is-centered is-mobile" style={{marginTop:"4rem", marginBottom:"6rem"}}>
          <button className="button is-rounded is-outlined is-white is-large" onClick={this.CancelBooking}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger is-large" onClick={this.handleSubmit}>
              <span class="icon"><i class="fas fa-check-circle"></i></span><span>Confirm</span></button>
        </div>

         </div>
        
      </div>

     )

    
   return ( 
       
       <div>

          <div class="section" style={{display:"flex",justifyContent:"center",paddingBottom:"10px"}}>
              <h3 class="is-size-4-touch is-size-2-desktop"><b>{this.mainText}</b></h3>
          </div>
              <p class="is-size-7-touch">Click on car image or on any of the fields to update.</p>

          {content}

          {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$Summary_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$Summary_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     setBookingInfo: function(booking_info) { dispatch(Actions.actionSetBookingInfo(booking_info)) },
     clearBooking: function() { dispatch(Actions.actionClearBooking()) }
  }
}


export const Summary = withRouter(connect(mapStateToProps$Summary_, mapDispatchToProps$Summary_)(Summary_))



///////////////////////////////////////////////////////////////////////////////////////////////


class CarSelector_ extends Component {
   constructor(props) {
    window.scrollTo(0, 0);
    super(props);

    this.openSelector = this.openSelector.bind(this)
    this.closeSelector = this.closeSelector.bind(this)

    this.state = {selectorOpen: false}
  }


  openSelector() {
    this.setState({selectorOpen: true})
  }

  closeSelector(cartype) {

    let maxPers = Math.max.apply(Math, this.booking.destinations.map(function(e) { return e.persons; }))
    let carMaxPers = this.cars.find((car)=>car.type_id===cartype).number_pers

    if (carMaxPers >= maxPers ) {
      helpers.closeMessage()
      this.setState({selectorOpen: false})
      this.booking.car_type = cartype
      this.props.updateBooking(this.booking)
    } else {
      helpers.closeMessage()
      helpers.alertMessage(`Number of persons you selected is ${maxPers}, but this car can take maximum ${carMaxPers} persons. Select another car type or change the number of persons.`, globals.WARN)
    }
  }

  render() {

   this.booking = JSON.parse(JSON.stringify(this.props.reduxBooking))

   let carTypeId = this.props.reduxBooking.car_type
   this.cars = this.props.reduxDictionary.cars

   let content = []

 
    if (! this.state.selectorOpen) {
 

    let car = this.cars.filter(car => car.type_id === carTypeId).map( car => {
     return (<div style={{cursor: "pointer"}} onClick={this.openSelector}>
           
           <div class="centered">
           <figure class="image is-rounded" style={{maxWidth: "7rem", height: "auto"}}>
                             <img src={car.path} />
                           </figure>
           </div>

           <div class="font-racing-sun is-size-5-touch is-size-4-desktop">{car.name}</div>


      </div>)

   })

    content = (
         <div class="notification has-background-transparent centered">
           
           {car}

         </div>
      )
    
    } else {


    let car_list = this.cars.map( car => {
     return (<div style={{cursor: "pointer"}} onClick={(e)=>this.closeSelector(car.type_id)}>
           
             <div class="centered">
             <figure class="image is-rounded" style={{maxWidth: "7rem", height: "auto", border: (car.type_id === this.booking.car_type) ? "solid 2px" : "none" }}>
                             <img src={car.path} />
                           </figure>
             </div>

             <div class="font-racing-sun is-size-5-touch is-size-4-desktop has-text-centered">{car.name}</div>

      </div>)

   })

    content = (
         <div class="notification has-background-transparent">
           
           {car_list}

         </div>
      )    


    }



    
   return content

  }
}


const mapStateToProps$CarSelector_ = function (state) {
   return { reduxBooking: state.booking,
            reduxDictionary: state.dictionary }
}

const mapDispatchToProps$CarSelector_ = function(dispatch) {
  return {
    updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) }
  }
}


export const CarSelector = withRouter(connect(mapStateToProps$CarSelector_,mapDispatchToProps$CarSelector_)(CarSelector_))





///////////////////////////////////////////////////////////////////////////////////////////////


class Confirmation_ extends Component {
   constructor(props) {
    window.scrollTo(0, 0);
    super(props);
  }

  componentWillUnmount() {
    this.props.clearBookingInfo()
  }


  render() {
 
   let content = []

   if (this.props.reduxBookingInfo == null) {
      this.props.history.replace('/')
      return null
   }

   window.scrollTo(0, 0);

    if (this.props.reduxBookingInfo.amount_to_pay_azn !== null) {
       content.push(<div class="section is-size-6-touch is-size-3-desktop" style={{textAlign:"center"}}>
               Your booking is confirmed. Thank you for choosing us! We sent you a confirmation email to <b>{this.props.reduxBookingInfo.client_email}</b>.
               <div style={{marginTop:"3rem"}}>Check your spam folder if you will not receive anything.</div>
            </div>)
    } else {
       content.push(
             <div class="section is-size-6-touch is-size-3-desktop" style={{textAlign:"center"}}>
              We will calculate the best price for you and will send it to your email <b>{this.props.reduxBookingInfo.client_email}</b> for approval in a fewe minutes.
              <div style={{marginTop:"3rem"}}>Check your spam folder if you will not receive anything.</div>
            </div>)
    }


    content = (
         <div class="section">
            
          <h1 class="section is-size-4-touch is-size-1-desktop" style={{display:"flex",justifyContent:"center"}}>
            <div>Your booking number is <b class="booking-id num">{this.props.reduxBookingInfo.booking_id}</b></div></h1>

            {content}
            
         </div>
      )
   
    
   return ( 
       
       <div class="hero is-fullheight-with-navbar has-background-info has-text-white-ter" style={{justifyContent:"start"}}>

          {content}
         
       </div>
    )

  }
}


const mapStateToProps$Confirmation_ = function (state) {
   return { reduxBookingInfo: state.booking_info,
            reduxDictionary: state.dictionary }
}

const mapDispatchToProps$Confirmation_ = function(dispatch) {
  return {
    clearBookingInfo: function() { dispatch(Actions.actionClearBookingInfo()) }
  }
}


export const Confirmation = withRouter(connect(mapStateToProps$Confirmation_,mapDispatchToProps$Confirmation_)(Confirmation_))





//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




class Destination_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);

    this.setEditMode = this.setEditMode.bind(this);
    this.setViewMode = this.setViewMode.bind(this);

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);
    
    this.saveData = this.saveData.bind(this);    

    this.state = {PickuptimeViewMode: this.props.view_mode,
                  PickupAddrViewMode: this.props.view_mode,
                  FlightNumberViewMode: this.props.view_mode,
                  DestAddrViewMode: this.props.view_mode,
                  PersonsInfoViewMode: this.props.view_mode}

  }


  destStepInc() {
    this.booking.destinations[this.index].step_id++
    this.props.updateBooking(this.booking)
  }

  destStepDec() {
    if (this.booking.destinations[this.index].step_id > 0) { 
      this.booking.destinations[this.index].step_id-- 
      this.props.updateBooking(this.booking)
    }
  }

  saveData() {
     this.booking.client = {full_name: this.nameInputField.value,
                            mail: this.mailInputField.value,
                            phone: this.phoneInputField.value,
                            country: this.countryInputField.value}
     this.props.updateBooking(this.booking)
  }
 
  handleNext(event) {
     this.props.bookingStepInc()
  }

  handlePrev(event) {
     this.props.bookingStepDec()
  }

  handleChange(event) {
    if (globals.ENV === 'dev') console.log('DEBUG', 'dropdown', event)
     
  }

  setEditMode(id, event) {
    switch (id) {
      case 'Pickuptime':
       this.setState({PickuptimeViewMode: false})
       break;
      case 'PickupAddr':
       this.setState({PickupAddrViewMode: false})
       break;
      case 'FlightNumber':
       this.setState({FlightNumberViewMode: false})
       break;
      case 'DestAddr':
       this.setState({DestAddrViewMode: false})
       break;
      case 'PersonsInfo':
       this.setState({PersonsInfoViewMode: false})
       break;
    }
  }
  
  setViewMode(id, event) {

    switch (id) {
      case 'Pickuptime':
       this.setState({PickuptimeViewMode: true})
       break;
      case 'PickupAddr':
       this.setState({PickupAddrViewMode: true})
       break;
      case 'FlightNumber':
       this.setState({FlightNumberViewMode: true})
       break;
      case 'DestAddr':
       this.setState({DestAddrViewMode: true})
       break;
      case 'PersonsInfo':
       this.setState({PersonsInfoViewMode: true})
       break;
    }
  }

   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)

   let isHidden = null
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
   }


   let content = []
   let currentDestStep

   if ( this.booking.destinations.find(e=>e.transfer_type_id===this.props.transf_type_id) === undefined  ) {
     this.booking.destinations.push({transfer_type_id: this.props.transf_type_id, step_id: 0})
     this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
     currentDestStep = 0
     }
   else {
     this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
     currentDestStep = this.booking.destinations[this.index].step_id
   }


   let StepsOrderDependingOnTransferType = [
    {transfer_type: 1, steps: [
                              <Pickuptime transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PickuptimeViewMode} onClick={(e)=>{this.state.PickuptimeViewMode && this.setEditMode('Pickuptime',e)}} onViewMode={(e)=>{this.setViewMode('Pickuptime',e)}}/>,
                              <FlightNumber transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.FlightNumberViewMode} onClick={(e)=>{this.state.FlightNumberViewMode && this.setEditMode('FlightNumber',e)}} onViewMode={(e)=>{this.setViewMode('FlightNumber',e)}}/>,
                              <DestAddr transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.DestAddrViewMode} onClick={(e)=>{this.state.DestAddrViewMode && this.setEditMode('DestAddr',e)}} onViewMode={(e)=>{this.setViewMode('DestAddr',e)}}/>,
                              <PersonsInfo transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PersonsInfoViewMode} onClick={(e)=>{this.state.PersonsInfoViewMode && this.setEditMode('PersonsInfo',e)}} onViewMode={(e)=>{this.setViewMode('PersonsInfo',e)}}/>
                              ]},
    {transfer_type: 2, steps: [
                              <Pickuptime transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PickuptimeViewMode} onClick={(e)=>{this.state.PickuptimeViewMode && this.setEditMode('Pickuptime',e)}} onViewMode={(e)=>{this.setViewMode('Pickuptime',e)}}/>,                              
                              <PickupAddr transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PickupAddrViewMode} onClick={(e)=>{this.state.PickupAddrViewMode && this.setEditMode('PickupAddr',e)}} onViewMode={(e)=>{this.setViewMode('PickupAddr',e)}}/>,
                              <FlightNumber transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.FlightNumberViewMode} onClick={(e)=>{this.state.FlightNumberViewMode && this.setEditMode('FlightNumber',e)}} onViewMode={(e)=>{this.setViewMode('FlightNumber',e)}}/>,
                              <PersonsInfo transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PersonsInfoViewMode} onClick={(e)=>{this.state.PersonsInfoViewMode && this.setEditMode('PersonsInfo',e)}} onViewMode={(e)=>{this.setViewMode('PersonsInfo',e)}}/>                              
                              ]},
    {transfer_type: 3, steps: [
                              <Pickuptime transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PickuptimeViewMode} onClick={(e)=>{this.state.PickuptimeViewMode && this.setEditMode('Pickuptime',e)}} onViewMode={(e)=>{this.setViewMode('Pickuptime',e)}}/>,
                              <PickupAddr transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PickupAddrViewMode} onClick={(e)=>{this.state.PickupAddrViewMode && this.setEditMode('PickupAddr',e)}} onViewMode={(e)=>{this.setViewMode('PickupAddr',e)}}/>,
                              <DestAddr transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.DestAddrViewMode} onClick={(e)=>{this.state.DestAddrViewMode && this.setEditMode('DestAddr',e)}} onViewMode={(e)=>{this.setViewMode('DestAddr',e)}}/>,
                              <PersonsInfo transf_type_id={this.props.transf_type_id} edit_mode={this.props.edit_mode} view_mode={this.state.PersonsInfoViewMode} onClick={(e)=>{this.state.PersonsInfoViewMode && this.setEditMode('PersonsInfo',e)}} onViewMode={(e)=>{this.setViewMode('PersonsInfo',e)}}/>
                              ]}

    ]
   


    if (this.props.view_mode) {
      content = StepsOrderDependingOnTransferType.find(e=>e.transfer_type===this.props.transf_type_id).steps
    } else {
      content = StepsOrderDependingOnTransferType.find(e=>e.transfer_type===this.props.transf_type_id).steps[currentDestStep]
    }

/*
  switch (true) {

    case currentDestStep === 4:
       content = (<span>Step {currentDestStep}
                   <button className="btn" onClick={this.destStepDec}>Prev</button>
                   <button className="btn" onClick={this.handleNext}>Next</button>
                  </span>)
       break;
   } */

    
   return ( 
       
       <div style={{paddingBottom:"2rem"}}>

          <div class="level" style={{marginTop: "1rem",marginBottom: "0"}}><div class="level-item is-size-4-desktop">
                 <TransferTypeIcons type={this.props.transf_type_id} /> </div></div>
                 <p class="help" style={{marginBottom: "2rem"}}>{this.props.reduxDictionary.transfer_types.find(e=>e.id===this.props.transf_type_id).name}</p>

          { isHidden && <div></div> || <Steps current_step={currentDestStep+1} />}
          {content}
         
       </div>
    )

  }
}


const mapStateToProps$Destination_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$Destination_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const Destination = connect(mapStateToProps$Destination_, mapDispatchToProps$Destination_)(Destination_)




///////////////////////////////////////////////////////////////////////////////////////////////





class Pickuptime_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);

    this.initializeCalendar = this.initializeCalendar.bind(this)

    this.saveData = this.saveData.bind(this);
    this.pushChanges = this.pushChanges.bind(this);

    this.currDate = null
    this.currTime = null

    this.validateData = this.validateData.bind(this);

    this.state = {pickup_date: true,
                  pickup_time: true}

  }


  validateData(what, value) {
    let obj={}

    if (what) {
       obj[what] = helpers.validate(what, value)
       this.setState(obj)
       return obj[what]
    }
    
    let returnValue = true
    Object.keys(this.state).forEach((k) => {
      if (helpers.validate(k,this.booking.destinations[this.index][k])) {
        obj[k] = true
      } else {
        obj[k] = false
        returnValue = false
      }
    })

    this.setState(obj)
    
    return returnValue

  }


  destStepInc() {
    this.booking.destinations[this.index].step_id++
    this.props.updateBooking(this.booking)
  }

  destStepDec() {
    if (this.booking.destinations[this.index].step_id > 0) { 
      this.booking.destinations[this.index].step_id--
      this.props.updateBooking(this.booking)
    }
  }


  saveData() {
     //this.booking.destinations[this.index].pickup_date = this.dateInputField.value
     this.booking.destinations[this.index].pickup_date = this.currDate
     this.booking.destinations[this.index].pickup_time = this.currTime
  }


  pushChanges() {
    this.saveData()
    if (this.validateData()) {
      this.props.updateBooking(this.booking)
      this.props.onViewMode()
    }
  }


  handleNext(event) {
     this.saveData()
  
     if (this.validateData()) {
        this.props.updateBooking(this.booking)
        this.destStepInc()
     }
  }

  handlePrev(event) {
     this.saveData()
     
     if (this.validateData()) {
       this.props.updateBooking(this.booking)
       this.props.bookingStepDec()
     }
  }

  handleChange(event) {
    if (globals.ENV === 'dev') console.log('DEBUG', 'dropdown', event)

  }


  initializeCalendar() { /* eslint-disable */

   let currDest = this.booking.destinations[this.index]
   let defDate 
   let defTime
    if (typeof currDest !== 'undefined') {
       if (currDest.pickup_date) {
         defDate = new Date(currDest.pickup_date)
         this.currDate = currDest.pickup_date
        } else {
         defDate = new Date()
         this.currDate = moment().format('YYYY-MM-DD')
        }

       if (currDest.pickup_time) {
         defTime = new Date(currDest.pickup_date+' '+currDest.pickup_time)
         this.currTime = currDest.pickup_time
        } else {
         defTime = "00:00"
         this.currTime = defTime
        }

    }

  }


  componentDidMount() {
    if (! this.props.view_mode) {
      // helpers.animateBookingPage('pktimeWelcome-'+this.props.transf_type_id, ['pktimeBody-'+this.props.transf_type_id,'pktimeButtons-'+this.props.transf_type_id], 1000)
    }
  }


  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   
   switch (this.props.transf_type_id) {
      case 1: this.mainText = 'Please give us the date and time of your arrival'
              break;
      default: this.mainText = 'When should we pick you up?'
   }
   

   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }


   let content = []
   let content_view = []
   let currentDestStep

   this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
   currentDestStep = this.booking.destinations[this.index].step_id
  
   let defDate
   let defTime


   let currDest = this.booking.destinations[this.index]

   if (typeof currDest !== 'undefined') {
   
       if (currDest.pickup_date) {
         defDate = currDest.pickup_date
        } else {
         defDate = null
        }

       if (currDest.pickup_time) {
         defTime = currDest.pickup_time
        } else {
         defTime = "00:00"
        }

   }

   if (this.props.view_mode) {
      content_view.push(

<div class="columns">

<div class="column" style={{padding:0}}></div>
<div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:0}}>


<div onClick={this.props.onClick} style={{cursor: "pointer"}}>

<div class="columns is-gapless">

<div class="column">

<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Pick-up date</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">
       { moment(moment(defDate).format('YYYY-MM-DD')).format("DD.MM.YYYY") }</span>
  </div>
</div>

</div>

<div class="column">

<div class="field" style={{margin:"1rem"}}>
  <div class="field-label is-normal">
    <label class="label has-text-white-ter" align="left">Pick-up time</label>
  </div>
  <div class="field-body">
     <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defTime}</span>
  </div>
</div>
</div>


</div>
</div>



</div>
<div class="column" style={{padding:0}}></div>

</div>

)


   } else {

//#AG

   this.initializeCalendar()

   content.push(<div class="columns">

                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Pick-Up Date</label>
                               
                               <DateSelector defDate={this.currDate} minDate="today" onSet={(d)=>{this.currDate=d}} isError={!this.state.pickup_date} errorMessage="The date is required"/>

                           </div>
                        </div>
       
                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Pick-Up Time</label>

                               <TimeSelector defTime={this.currTime} onSet={(t)=>{this.currTime=t}} isError={!this.state.pickup_time} errorMessage="The time is required"/>

                           </div>
                        </div>


           </div>)
  }

  let buttons = []

  if (this.props.edit_mode === "step") {

        buttons.push(

                 <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

                <div id={"pktimeButtons-"+this.props.transf_type_id} class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handlePrev}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>

             </div>
             <div class="column"></div>
          </div>
       </div>
                )

  } else if (!this.props.view_mode) {

    buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.pushChanges(e)}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )


  }
  

   content = ( <div style={containerStyle}>

            <div id={"pktimeWelcome-"+this.props.transf_type_id} class={"section "+isHidden} style={{paddingTop: "1.5rem"}}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id={"pktimeBody-"+this.props.transf_type_id} class="section">
           <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-two-fifths-desktop">
                 {content}
             </div>
             <div class="column"></div>
           </div>
          </div>

          </div>
    )  



   return ( 
       
       <div>

       { this.props.view_mode && content_view || content }
          
       {buttons}            
         
       </div>
    )

  }
}


const mapStateToProps$Pickuptime_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$Pickuptime_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const Pickuptime = connect(mapStateToProps$Pickuptime_, mapDispatchToProps$Pickuptime_)(Pickuptime_)



///////////////////////////////////////////////////////////////////////////////////////////////


class TransferTypeIcons extends Component {

   static defaultProps = {
      type: 1,
      icon_class: "is-medium" 
   };

   constructor(props) {
    super(props);
   }

   shouldComponentUpdate(nextProps) {
        return true
    }

  render() {

    
   let content = []
  

   switch (this.props.type) {
          case 1: 
                  content.push(<span key="A1" className={'icon '+this.props.icon_class}><i className="fas fa-plane-arrival"></i></span>)
                  content.push(<span key="A2" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="A3" className={'icon '+this.props.icon_class}><i className="fas fa-shuttle-van"></i></span>)
                  content.push(<span key="A4" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="A5" className={'icon '+this.props.icon_class}><i className="fas fa-city"></i></span>)
            break;
          case 2: 
                  content.push(<span key="B1" className={'icon '+this.props.icon_class}><i className="fas fa-city"></i></span>)
                  content.push(<span key="B2" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="B3" className={'icon '+this.props.icon_class}><i className="fas fa-shuttle-van"></i></span>)                  
                  content.push(<span key="B4" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="B5" className={'icon '+this.props.icon_class}><i className="fas fa-plane-departure"></i></span>)
            break;
          case 3: 
                  content.push(<span key="C1" className={'icon '+this.props.icon_class}><i className="fas fa-city"></i></span>)
                  content.push(<span key="C2" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="C3" className={'icon '+this.props.icon_class}><i className="fas fa-shuttle-van"></i></span>)
                  content.push(<span key="C4" className={'icon '+this.props.icon_class}><i className="fas fa-arrow-right"></i></span>)
                  content.push(<span key="C5" className={'icon '+this.props.icon_class}><i className="fas fa-city"></i></span>)
            break;

   }


   return ( 
     <div class="field is-grouped">
      {content}
     </div>
    )

  }
}





///////////////////////////////////////////////////////////////////////////////////////////////



class Error extends Component {

   constructor(props) {
    super(props);
   }

  render() {

   return ( 
       
          <div class="help"><div class="notification is-danger is-size-6-desktop">
            <span class="icon is-normal is-left" style={{marginRight:"0.5rem"}}><i class="fas fa-exclamation-triangle"></i></span>
            <span>{this.props.children}</span></div>
          </div>

    )

  }
}



///////////////////////////////////////////////////////////////////////////////////////////////


class Steps extends Component {

   static defaultProps = {
      current_step: 1,
      steps: 4
   };

   constructor(props) {
    super(props);
   }

  render() {

   let completed_icon = (<span class="icon"><i class="fa fa-check"></i></span>)
   let finish_icon = (<span class="icon"><i class="fa fa-flag"></i></span>)
    
   let content = []
   let i
   for (i=1; i <= this.props.steps; i++) {
     
      if (i < this.props.current_step) {
         content.push(<li key={i} class="step-item is-completed is-link">
                         <div class="step-marker">{completed_icon}</div>
                      </li>)
      } else if (i === this.props.steps && i === this.props.current_step) {
         content.push(<li key={i} class="step-item is-active is-link">
                         <div class="step-marker">{finish_icon}</div>
                      </li>)
      } else if (i === this.props.current_step) {
         content.push(<li key={i} class="step-item is-active is-link">
                         <div class="step-marker">{i}</div>
                      </li>)
      } else if (i === this.props.steps) {
         content.push(<li key={i} class="step-item is-link">
                         <div class="step-marker" style={{background: "#477ee1"}}>{finish_icon}</div>
                      </li>)
      } else {
         content.push(<li key={i} class="step-item is-link">
                         <div class="step-marker" style={{background: "#477ee1"}}>{i}</div>
                      </li>)
      }

   }

   return ( 
       
       <div class="centered">
         
         <div style={{width:"75%"}}>
           <ul class="steps">
             {content}
           </ul>
         </div>
         


    </div>
    )

  }
}










class FlightNumber_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);
    
    this.saveData = this.saveData.bind(this);
    this.pushChanges = this.pushChanges.bind(this);

    this.validateData = this.validateData.bind(this);

    this.state = {flight_number: true}

  }


  validateData(what, value) {
    
    if (this.props.transf_type_id !== 1) {
       return true
    }

    let obj={}

    if (what) {
       obj[what] = helpers.validate(what, value)
       this.setState(obj)
       return obj[what]
    }
    
    let returnValue = true
    Object.keys(this.state).forEach((k) => {
      if (helpers.validate(k,this.booking.destinations[this.index][k])) {
        obj[k] = true
      } else {
        obj[k] = false
        returnValue = false
      }
    })

    this.setState(obj)
    
    return returnValue

  }


  destStepInc() {
    this.saveData()
    
    if (this.validateData()) {
      this.booking.destinations[this.index].step_id++
      this.props.updateBooking(this.booking)
    }
  }

  destStepDec() {
    this.saveData() 

    if (this.validateData()) {
      if (this.booking.destinations[this.index].step_id > 0) { 
        this.booking.destinations[this.index].step_id--
        this.props.updateBooking(this.booking)
      }
    }
  }

  saveData() {
     if (this.props.transf_type_id === 1) { this.booking.destinations[this.index].pickup_location_id = 1 }
     this.booking.destinations[this.index].flight_number = this.flightInputField.value
  }
 

  pushChanges() {
     this.saveData()
     
     if (this.validateData()) {
       this.props.updateBooking(this.booking)
       this.props.onViewMode()
     }
  }


  componentDidMount() {
    if (! this.props.view_mode) {
      // helpers.animateBookingPage('flightWelcome-'+this.props.transf_type_id, ['flightBody-'+this.props.transf_type_id,'flightButtons-'+this.props.transf_type_id], 1000)
    }
  }
   
  render() {
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.mainText = 'Please, provide your flight number'
   
   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }


   let content = []
   let content_view = []
   let currentDestStep

   this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
   currentDestStep = this.booking.destinations[this.index].step_id
  
   let defFlight


   let currDest = this.booking.destinations[this.index]

   if (typeof currDest !== 'undefined') {
   
       if (currDest.flight_number) {
         defFlight = currDest.flight_number
        } else {
         defFlight = null
        }

   }


   if (this.props.view_mode) {
     content_view.push(
                     
                     <div class="columns">

                      <div class="column" style={{padding:0}}></div> 
                      <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:"1px"}}>

                         <div class="container" onClick={this.props.onClick} style={{cursor: "pointer"}} align="center">

                         <div class="field is-centered" style={{margin:"1rem", maxWidth: "25rem"}}>
                           <div class="field-label is-normal">
                             <label class="label has-text-white-ter" align="left">Flight number</label>
                           </div>
                           <div class="field-body">
                              <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark" 
                                  style={{minWidth: "10rem", textTransform: "uppercase"}}>{defFlight}</span>
                           </div>

                         </div>
                        </div>


                     </div>
                   <div class="column" style={{padding:0}}></div>

                  </div>
                            )

   } else {
     content.push(                     
                        <div class="column is-narrow">
                         <div class="container">
                         <div class="field is-centered">
                           <label class="label has-text-white-ter">Flightnumber</label>
                             <div class="control has-text-grey has-icons-left">
                                <input class={"input is-large-desktop " + (this.state.flight_number ? "" : "is-danger")} type="text" style={{minWidth: "10rem", textTransform: "uppercase"}} 
                                  defaultValue={defFlight} ref={(x) => { this.flightInputField = x; }}
                                  onChange={(e)=>this.validateData('flight_number',e.target.value)}/>
                                <span class="icon is-small is-left"><i class="fas fa-plane"></i></span>
                             </div>
                             {! this.state.flight_number && <Error>We need flight number to check any changes in flights. It must not include spaces.</Error>}
                             {this.props.transf_type_id !== 1 && <span class="help">Not mandatory.</span>}

                           </div>
                        </div>
                        </div>          

           )

  }


  let buttons = []


  if (this.props.edit_mode === "step") {


        buttons.push(
         <div class="section">
           <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
 

                <div id={"flightButtons-"+this.props.transf_type_id} class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepDec}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepInc}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>

          </div>
            <div class="column"></div>
        </div>
       </div>


                )

  } else if (!this.props.view_mode) {

        buttons.push(

           <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.pushChanges(e)}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div> )

  }


   content = ( <div style={containerStyle}>


            <div id={"flightWelcome-"+this.props.transf_type_id} class={"section "+isHidden} style={{paddingTop: "1.5rem"}}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id={"flightBody-"+this.props.transf_type_id} class="section">
           <div class="columns is-gapless is-centered">
                 {content}
           </div>
          </div>

          </div>
)

    
   return ( 
       
       <div>

       { this.props.view_mode && content_view || content }

       {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$FlightNumber_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$FlightNumber_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const FlightNumber = connect(mapStateToProps$FlightNumber_, mapDispatchToProps$FlightNumber_)(FlightNumber_)







///////////////////////////////////////////////////////////////////////////////////////////////



class PickupAddr_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0,0);    

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);
    
    this.saveData = this.saveData.bind(this);
    this.pushChanges = this.pushChanges.bind(this);

    this.validateData = this.validateData.bind(this);

    this.state = {pickup_location: true}

  }

  validateData(what, value) {
    let obj={}

    if (what) {
       obj[what] = helpers.validate(what, value)
       this.setState(obj)
       return obj[what]
    }
    
    let returnValue = true
    Object.keys(this.state).forEach((k) => {
      if (helpers.validate(k,this.booking.destinations[this.index][k])) {
        obj[k] = true
      } else {
        obj[k] = false
        returnValue = false
      }
    })

    this.setState(obj)
    
    return returnValue

  }

  destStepInc() {
    this.saveData()
    
    if (this.validateData()) {
      this.booking.destinations[this.index].step_id++
      this.props.updateBooking(this.booking)
    }
  }

  destStepDec() {
    this.saveData() 
    
    if (this.validateData()) {
      if (this.booking.destinations[this.index].step_id > 0) { 
        this.booking.destinations[this.index].step_id--
        this.props.updateBooking(this.booking)
      }
    }
  }

  saveData() {
     if (this.props.transf_type_id !== 1) { this.booking.destinations[this.index].pickup_location_id = 2 }
     this.booking.destinations[this.index].pickup_region = Number(this.pickupRegionInputField.value)
     this.booking.destinations[this.index].pickup_location = this.pickupLocationInputField.value
  }

  pushChanges() {
     this.saveData()
     
     if (this.validateData()) {
        this.props.updateBooking(this.booking)
        this.props.onViewMode()
     }
  }

 
  componentDidMount() {
    if (!this.props.view_mode) {
      // helpers.animateBookingPage('paddrWelcome-'+this.props.transf_type_id, ["paddrBody-"+this.props.transf_type_id,"paddrButtons-"+this.props.transf_type_id], 1000)
    }
  }

   
  render() {
   this.booking = JSON.parse(JSON.stringify(this.props.reduxBooking))
   this.mainText = 'Where should we pick you up? :)'
   
   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }

   let content = []
   let content_view = []
   let currentDestStep

   this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
   currentDestStep = this.booking.destinations[this.index].step_id
  
   let defPickupRegion
   let defPickupLocation

   let currDest = this.booking.destinations[this.index]

   if (typeof currDest !== 'undefined') {
   
       if (currDest.pickup_region) {
         defPickupRegion = currDest.pickup_region
        } else {
            if (typeof this.booking.tmp !== 'undefined') {
               this.booking.tmp.destRegion ? defPickupRegion = this.booking.tmp.destRegion : defPickupRegion = null
            } else {
               defPickupRegion = null
            }

        }

      if (currDest.pickup_location) {
         defPickupLocation = currDest.pickup_location
        } else {
             if (typeof this.booking.tmp !== 'undefined') {
               this.booking.tmp.destAddr ? defPickupLocation = this.booking.tmp.destAddr : defPickupLocation = null
            } else {
               defPickupLocation = null
            }
         
        }


   }


 let select = this.props.reduxDictionary.az_regions.map((c) => {
      return (<option key={c.id} value={c.id}>{c.name}</option>)
   })
    

  if (this.props.view_mode) {


     content_view.push(
   <div class="columns">

     <div class="column" style={{padding:0}}></div>
     <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:0}}>

       <div onClick={this.props.onClick} style={{cursor: "pointer"}}>
         <div class="columns is-gapless">
            
            <div class="column">
               <div class="field" style={{margin:"1rem"}}>
                 <div class="field-label is-normal">
                   <label class="label has-text-white-ter" align="left">Pick-up region</label>
                 </div>
                 <div class="field-body">
                    <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">
                      {this.props.reduxDictionary.az_regions.find(r=>r.id===defPickupRegion).name}</span>
                 </div>
               </div>
            </div>

            <div class="column">
               <div class="field" style={{margin:"1rem"}}>
                 <div class="field-label is-normal">
                   <label class="label has-text-white-ter" align="left">Pick-up hotel or address</label>
                 </div>
                 <div class="field-body">
                    <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defPickupLocation}</span>
                 </div>
               </div>
            </div>

       </div>
     </div>
   </div>
   <div class="column" style={{padding:0}}></div>
 </div>
)



  } else {
     content.push(

                     <div class="columns">
      
                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Your pickup region</label>
                            <div class="control has-icons-left">
                               <div class="select has-text-grey is-fullwidth" style={{width: "100%", margin: "0px"}}>
                                 <select defaultValue={defPickupRegion} ref={(x) => { this.pickupRegionInputField = x; }}>
                                  {select}
                                 </select>
                               </div>
                               <div class="icon is-small is-left"><i class="fas fa-search-location"></i></div>
                            </div>
                          </div>
                       </div>


                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Hotel or address</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.pickup_location ? "" : "is-danger")} type="text"  defaultValue={defPickupLocation} 
                                  ref={(x) => { this.pickupLocationInputField = x; }}
                                  onChange={(e)=>this.validateData('pickup_location',e.target.value)}/>
                               <span class="icon is-small is-left"><i class="fas fa-hotel"></i></span>
                            </div>
                             {! this.state.pickup_location && <Error>Pickup location is requiered.</Error>}
                          </div>
                       </div>
                    
                    </div>)
   }

  let buttons = []

  if (this.props.edit_mode === "step") {

     buttons.push(
      <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
       
                <div id={"paddrButtons-"+this.props.transf_type_id} class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepDec}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepInc}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>
             </div>
             <div class="column"></div>
          </div>
       </div>

                )

  } else if (!this.props.view_mode) {

    buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.pushChanges(e)}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )

  }


   content = (
      <div style={containerStyle}>

            <div id={"paddrWelcome-"+this.props.transf_type_id} class={"section "+isHidden} style={{paddingTop: "1.5rem"}}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id={"paddrBody-"+this.props.transf_type_id} class="section">
           <div class="columns is-gapless is-centered">
              <div class="column is-three-fifths">
                 {content}
              </div>
           </div>
          </div>

      </div>
    )

    
   return ( 
       
       <div>
    
         { this.props.view_mode && content_view || content }
       
         {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$PickupAddr_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$PickupAddr_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const PickupAddr = connect(mapStateToProps$PickupAddr_, mapDispatchToProps$PickupAddr_)(PickupAddr_)












///////////////////////////////////////////////////////////////////////////////////////////////



class DestAddr_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0,0);    

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);
    
    this.saveData = this.saveData.bind(this);
    this.pushChanges = this.pushChanges.bind(this);
    this.pushChangesInline = this.pushChangesInline.bind(this);
    this.checkRDate = this.checkRDate.bind(this)

    this.initializeCalendar = this.initializeCalendar.bind(this)

    this.currDate = null
    this.currTime = null

    this.validateData = this.validateData.bind(this);

    this.state = {destination: true,
                  return_from: true}

  }

  validateData(what, value) {
    let obj={}

    if (what) {
       obj[what] = helpers.validate(what, value)
       this.setState(obj)
       return obj[what]
    }
    
    let returnValue = true
    Object.keys(this.state).forEach((k) => {
      if (helpers.validate(k,this.booking.destinations[this.index][k])) {
        obj[k] = true
      } else {

        if ( k==='return_from' && 
            ! ( this.booking.destinations[this.index].return_trip && this.booking.destinations[this.index].return_trip === "YES" &&
            this.booking.destinations[this.index].return_same_dest && this.booking.destinations[this.index].return_same_dest === "NO")) {
          obj[k] = true
        } else {
          obj[k] = false
          returnValue = false
        }

      }
    })

    this.setState(obj)
    
    return returnValue

  }


  checkRDate() {
    let returnDate = this.booking.destinations[this.index].return_date +' '+this.booking.destinations[this.index].return_time
    let pickupDate = this.booking.destinations[this.index].pickup_date +' '+this.booking.destinations[this.index].pickup_time

    if ( this.booking.destinations[this.index].return_date && 
       ( this.booking.destinations[this.index].return_trip && this.booking.destinations[this.index].return_trip === "YES") &&
         ( moment(returnDate, 'YYYY-MM-DD hh:mm') <= moment(pickupDate, 'YYYY-MM-DD hh:mm') )
      ) {
      helpers.alertMessage('Check the return date, it cannot be before your pickup date '+pickupDate+'!', globals.WARN, true, 15000)
      return false
    } else {
      helpers.closeMessage()
      return true
    }

  }

  destStepInc() {
    this.saveData()

    if (this.validateData() && this.checkRDate()) {
      this.booking.destinations[this.index].step_id++
      this.props.updateBooking(this.booking)
    }
  }

  destStepDec() {
    this.saveData() 

    if (this.validateData()  && this.checkRDate()) {
      if (this.booking.destinations[this.index].step_id > 0) { 
        this.booking.destinations[this.index].step_id--
        this.props.updateBooking(this.booking)
      }
    }
  }

  saveData() {
     this.booking.destinations[this.index].destination_region = Number(this.regionInputField.value)
     this.booking.destinations[this.index].destination = this.destInputField.value
     this.booking.destinations[this.index].return_trip = (this.rTripInputField.checked) ? 'YES' : 'NO'
     if (this.currDate) { this.booking.destinations[this.index].return_date = this.currDate }
     if (this.currTime) { this.booking.destinations[this.index].return_time = this.currTime }
     if (this.rTripSamePointInputField) { this.booking.destinations[this.index].return_same_dest = (this.rTripSamePointInputField.checked) ? 'YES' : 'NO' }
     if (this.rRegionInputField) { this.booking.destinations[this.index].return_region = this.rTripSamePointInputField.value === "YES" ? this.booking.destinations[this.index].destination_region : Number(this.rRegionInputField.value) }
     if (this.rFromInputField) { this.booking.destinations[this.index].return_from = this.rFromInputField.value }

     if (typeof this.booking.tmp === 'undefined') { this.booking.tmp = {} } 
     !this.booking.tmp.destRegion && (this.booking.tmp.destRegion = Number(this.regionInputField.value))
     !this.booking.tmp.destAddr && (this.booking.tmp.destAddr = this.destInputField.value)
          
  }

  pushChanges() {
    this.saveData()

   // if (this.validateData()) {
      this.props.updateBooking(this.booking)
   // }
  }
 
  pushChangesInline() {
    this.saveData()

    if (this.validateData() && this.checkRDate()) {
      this.props.updateBooking(this.booking)
      this.props.onViewMode()
    }
  }


  initializeCalendar() { /* eslint-disable */

   let currDest = this.booking.destinations[this.index]
   let defRDate 
   let defRTime
    if (typeof currDest !== 'undefined') {
       if (currDest.return_date) {
         defRDate = new Date(currDest.return_date)
         this.currDate = currDest.return_date
        } else {
         defRDate = new Date()
         this.currDate = moment().format('YYYY-MM-DD')
        }

       if (currDest.return_time) {
         defRTime = new Date(currDest.return_date+' '+currDest.return_time)
         this.currTime = currDest.return_time
        } else {
         defRTime = "00:00"
         this.currTime = defRTime
        }

    }

  }


  componentDidMount() {
    if (! this.props.view_mode) { 
      // helpers.animateBookingPage('destWelcome-'+this.props.transf_type_id, ['destBody-'+this.props.transf_type_id,'destButtons-'+this.props.transf_type_id], 1000)
    }
  }

   
  render() {
   
   //this.booking = Object.assign({}, this.props.reduxBooking)
   this.booking = JSON.parse(JSON.stringify(this.props.reduxBooking))

   this.mainText = 'We will need your destination information'
   

   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }
   

   let content = []
   let content_view = []
   let currentDestStep

   this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
   currentDestStep = this.booking.destinations[this.index].step_id
  
   let defRegion
   let defDest
   let defRTrip
   let defRDate
   let defRTime
   let defRRegion
   let defRFrom
   let defRTripSameDest

   let currDest = this.booking.destinations[this.index]

   if (typeof currDest !== 'undefined') {
   
       if (currDest.destination_region) {
         defRegion = currDest.destination_region
        } else {
         defRegion = null
        }

      if (currDest.destination) {
         defDest = currDest.destination
        } else {
         defDest = null
        }

      if (currDest.return_trip) {
         defRTrip = currDest.return_trip
        } else {
         defRTrip = null
        }

      if (currDest.return_same_dest) {
         defRTripSameDest = currDest.return_same_dest
        } else {
         defRTripSameDest = 'YES'
        }

      if (currDest.return_date) {
         defRDate = currDest.return_date
        } else {
         defRDate = null
        }

      if (currDest.return_time) {
         defRTime = currDest.return_time
        } else {
         defRTime = "00:00"
        }

      if (currDest.return_region) {
         defRRegion = currDest.return_region
        } else {
         defRRegion = null
        }

      if (currDest.return_from) {
         defRFrom = currDest.return_from
        } else {
         defRFrom = null
        }

   }


 let select = this.props.reduxDictionary.az_regions.map((c) => {
      return (<option key={c.id} value={c.id}>{c.name}</option>)
   })
    

  if (this.props.view_mode) {
  // View mode

       if (defRTrip !== "YES" && defRTrip !== "NO") {
         defRTrip = "NO"
       } 
   
       content_view.push(

         <div class="columns is-gapless" style={{marginBottom:"0rem"}}>
           <div class="column">

             <div class="field" style={{margin:"1rem"}}>
               <div class="field-label is-normal">
                 <label class="label has-text-white-ter" align="left">Destination region</label>
               </div>
               <div class="field-body">
                  <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">
                    {this.props.reduxDictionary.az_regions.find(r=>r.id===defRegion).name}</span>
               </div>
             </div>

           </div>
           <div class="column">          

               <div class="field" style={{margin:"1rem"}}>
                 <div class="field-label is-normal">
                   <label class="label has-text-white-ter" align="left">Destination hotel or address</label>
                 </div>
                 <div class="field-body">
                   <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defDest}</span>
                 </div>
               </div>

           </div>
           <div class="column">          

            <div style={{display:"table",height:"100%", margin:"5px"}}><div style={{display:"table-cell",verticalAlign:"middle"}}>
             <div class="tags has-addons">
              <span for="showRTripYN" class="tag is-large is-rounded is-info has-text-white-ter is-size-6"><b>Return trip</b></span>

              <span id="showRTripYN" class="is-outlined is-white is-large tag is-rounded has-text-info">{defRTrip}</span>
             </div>
            </div></div>

           </div>
    
      </div>

    )



  if (defRTrip === "YES") {
    
     content_view.push(
         <div class="columns is-gapless">
           <div class="column">

             <div class="field" style={{margin:"1rem", marginTop:"0rem"}}>
               <div class="field-label is-normal">
                 <label class="label has-text-white-ter" align="left">Return date</label>
               </div>
               <div class="field-body">
                  <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">
                  {moment(moment(defRDate).format('YYYY-MM-DD')).format("DD.MM.YYYY")}</span>
               </div>
             </div>

           </div>

           <div class="column">

             <div class="field" style={{margin:"1rem", marginTop:"0rem"}}>
               <div class="field-label is-normal">
                 <label class="label has-text-white-ter" align="left">Return time</label>
               </div>
               <div class="field-body">
                  <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defRTime}</span>
               </div>
             </div>

           </div>
       </div>)


  let ShowReturnPointInfo
  if (defRTripSameDest === 'YES') {
      //content_view.push(<span>I will retrun from same destination.</span>)
      ShowReturnPointInfo = false
  } 
  else { 
      ShowReturnPointInfo = true
  }
  
  if (ShowReturnPointInfo) {
       content_view.push(
        <div class="columns is-gapless">
           <div class="column">

             <div class="field" style={{margin:"1rem"}}>
               <div class="field-label is-normal">
                 <label class="label has-text-white-ter" align="left">Return from region</label>
               </div>
               <div class="field-body">
                  <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">
                    {this.props.reduxDictionary.az_regions.find(r=>r.id===defRRegion).name}</span>
               </div>
             </div>

           </div>

           <div class="column">

             <div class="field" style={{margin:"1rem"}}>
               <div class="field-label is-normal">
                 <label class="label has-text-white-ter" align="left">Return from Hotel or address</label>
               </div>
               <div class="field-body">
                  <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defRFrom}</span>
               </div>
             </div>

           </div>
       </div>
              )
     }

  } 


   content_view = (
     <div class="columns">

       <div class="column" style={{padding:0}}></div>
       <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:0}}>
         <div onClick={this.props.onClick} style={{cursor: "pointer"}}>
           {content_view}
         </div>
       </div>
       <div class="column" style={{padding:0}}></div>
     </div>

    )



  } // End view mode 
  else {
   
  // Edit mode
    content.push(   
                     <div class="columns">
      
                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Your destination region</label>
                            <div class="control has-icons-left">
                               <div class="select has-text-grey is-fullwidth" style={{width: "100%", margin: "0px"}}>
                                 <select defaultValue={defRegion} ref={(x) => { this.regionInputField = x; }}>
                                  {select}
                                 </select>
                               </div>
                               <div class="icon is-small is-left"><i class="fas fa-search-location"></i></div>
                            </div>
                          </div>
                       </div>


                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Hotel or address</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.destination ? "" : "is-danger")} type="text" defaultValue={defDest} ref={(x) => { this.destInputField = x; }}
                                 onChange={(e)=>this.validateData('destination',e.target.value)}/>
                               <span class="icon is-small is-left"><i class="fas fa-hotel"></i></span>
                            </div>
                             {! this.state.destination && <Error>Destination information is required.</Error>}
                          </div>
                       </div>
                    
                    </div>
                
                )


  let RTripMsg
  if (defRTrip !== "YES" && defRTrip !== "NO") {
      RTripMsg = "Do you want return trip?"
      defRTrip = "NO"
  } else {
      RTripMsg = "Return trip"
  }
  
  content.push( 
            <div class="columns">
               <div class="column">
              
 
             <div class="field is-grouped">
              <input id="switchRT" type="checkbox" class="switch is-rounded is-outlined is-white is-medium is-rtl" 
                           defaultChecked={(defRTrip === 'YES') ? true : false} 
                           ref={(x) => { this.rTripInputField = x; }} onChange={this.pushChanges}/>
              <label for="switchRT" class="label has-text-white-ter is-size-6">{RTripMsg}</label>
              <span class="tag is-small is-rounded" style={{marginLeft: "1rem"}}>{defRTrip}</span>

             </div>

            </div>
          </div>

           )


  if (defRTrip === "YES") {

     this.initializeCalendar()
    
     content.push(


                  <div class="columns">

                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Return Date</label>
                              <DateSelector defDate={this.currDate} minDate="today" onSet={(d)=>{this.currDate=d}} />
                           </div>
                        </div>
       
                        <div class="column">
                         <div class="field" style={{minWidth: "15rem"}}>
                           <label class="label has-text-white-ter">Return Time</label>
                              <TimeSelector defTime={this.currTime} onSet={(t)=>{this.currTime=t}} />
                           </div>
                        </div>


                </div>)


  let ShowReturnPointDialog
  if (defRTripSameDest === 'YES') {
      defRRegion = defRegion
      defRFrom = defDest
      ShowReturnPointDialog = false
  } 
  else { 
      if (defRRegion === null) { defRRegion = defRegion }
      ShowReturnPointDialog = true
  }
  
  content.push( 

          <div class="columns">
               <div class="column">
              
 
             <div class="field is-grouped">
              <input id="switchRSD" type="checkbox" class="switch is-rounded is-outlined is-medium is-white is-rtl" 
                           defaultChecked={(defRTripSameDest === 'YES') ? true : false} 
                           ref={(x) => { this.rTripSamePointInputField = x; }} onChange={this.pushChanges}/>
              <label for="switchRSD" class="label has-text-white-ter is-size-6">Will you return from the same destination?</label>
              <span class="tag is-small is-rounded" style={{marginLeft: "1rem"}}>{defRTripSameDest}</span>

             </div>

            </div>
          </div>

           )

  if (ShowReturnPointDialog) {
       content.push(

                     <div class="columns">
      
                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Your return region</label>
                            <div class="control has-icons-left">
                               <div class="select has-text-grey is-fullwidth" style={{width: "100%", margin: "0px"}}>
                                 <select defaultValue={defRRegion} ref={(x) => { this.rRegionInputField = x; }}>
                                  {select}
                                 </select>
                               </div>
                               <div class="icon is-small is-left"><i class="fas fa-search-location"></i></div>
                            </div>
                          </div>
                       </div>


                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Return hotel or address</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.return_from ? "" : "is-danger")} type="text" defaultValue={defRFrom} ref={(x) => { this.rFromInputField = x; }}
                                 onChange={(e)=>this.validateData('return_from',e.target.value)}/>
                               <span class="icon is-small is-left"><i class="fas fa-hotel"></i></span>
                            </div>
                            {! this.state.return_from && <Error>Enter some address or Hotel name please.</Error>}
                          </div>
                       </div>
                    
                    </div>)

     }

  } 
  
  } // End edit mode

  let buttons = []

  if (this.props.edit_mode === "step") {

        buttons.push(
        <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">

                <div id={"destButtons-"+this.props.transf_type_id} class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepDec}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepInc}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>
                   </div>
             <div class="column"></div>
          </div>
       </div>
                )

   
  } else if (!this.props.view_mode) {

        buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.pushChangesInline(e)}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )


  }



   content = (
       <div style={containerStyle}>

            <div id={"destWelcome-"+this.props.transf_type_id} class={"section "+isHidden} style={{paddingTop: "1.5rem"}}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id={"destBody-"+this.props.transf_type_id} class="section">
           <div class="columns is-gapless is-centered">
              <div class="column is-three-fifths">
                 {content}
              </div>

           </div>
          </div>

      </div>
        

    )

    
   return ( 
       
       <div>

         { this.props.view_mode && content_view || content }
          
         {buttons}
         
       </div>
    )

  }
}


const mapStateToProps$DestAddr_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$DestAddr_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const DestAddr = connect(mapStateToProps$DestAddr_, mapDispatchToProps$DestAddr_)(DestAddr_)








///////////////////////////////////////////////////////////////////////////////////////////////



class PersonsInfo_ extends Component {

   static defaultProps = {
      view_mode: false,
      edit_mode: 'step'
   };

   constructor(props) {
    super(props);
    window.scrollTo(0,0);    

    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);

    this.destStepInc = this.destStepInc.bind(this);
    this.destStepDec = this.destStepDec.bind(this);

    
    this.saveData = this.saveData.bind(this);
    this.pushChanges = this.pushChanges.bind(this);
  }


  destStepInc() {
    this.booking.destinations[this.index].step_id++
    this.saveData()
    this.props.updateBooking(this.booking)
  }

  destStepDec() {
    if (this.booking.destinations[this.index].step_id > 0) { 
      this.booking.destinations[this.index].step_id--
      this.saveData() 
      this.props.updateBooking(this.booking)
    }
  }

  saveData() {

     let personsN = Number(this.personsInputField.value)
     if (! this.booking.car_type || this.booking.car_type === -1 ) {

        let cars=JSON.parse(JSON.stringify(this.props.reduxDictionary.cars))
        cars.sort((a,b)=>{ return a.number_pers - b.number_pers; })

        for (let i = 0; i < cars.length; i++) {
            if (cars[i].number_pers >= personsN ) {
              this.booking.car_type = cars[i].type_id
              break; 
            } 
        }

     }

     this.booking.destinations[this.index].persons = personsN
     this.booking.destinations[this.index].baggage = this.baggageInputField.value
     if (typeof this.booking.tmp === 'undefined') { this.booking.tmp = {} } 
     !this.booking.tmp.persons && (this.booking.tmp.persons = personsN)
     !this.booking.tmp.baggage && (this.booking.tmp.baggage = this.baggageInputField.value)
     
  }


  pushChanges() {
    this.saveData()
    this.props.updateBooking(this.booking)
  }

 
  handleNext(event) {
     this.saveData()
     this.props.updateBooking(this.booking)
     this.props.bookingStepInc()
  }

  handlePrev(event) {
     this.saveData()
     this.props.updateBooking(this.booking)
     this.props.bookingStepDec()
  }

  handleChange(event) {
    if (globals.ENV === 'dev') console.log('DEBUG', 'dropdown', event)
     
  }


  componentDidMount() {
    if (! this.props.view_mode) {
      // helpers.animateBookingPage('pinfoWelcome-'+this.props.transf_type_id, ['pinfoBody-'+this.props.transf_type_id,'pinfoButtons-'+this.props.transf_type_id], 1000)
    }
  }

   
  render() {
   //this.booking = Object.assign({}, this.props.reduxBooking)
   this.booking = JSON.parse(JSON.stringify(this.props.reduxBooking))
   this.mainText = 'How many persons will travel in this trip? :)'
   
   let isHidden = null
   let containerStyle = null   
   if (this.props.view_mode || this.props.edit_mode !== "step") {
     isHidden = "is-hidden"
     containerStyle={backgroundColor: globals.edit_mode_bg_color,
                     backgroundImage: globals.edit_mode_bg_image}
   }


   let content = []
   let content_view = []
   let currentDestStep

   this.index = this.booking.destinations.indexOfObject('transfer_type_id', this.props.transf_type_id)
   currentDestStep = this.booking.destinations[this.index].step_id
  
   let defPersons
   let defBaggage


   let currDest = this.booking.destinations[this.index]

   if (typeof currDest !== 'undefined') {
   
       if (currDest.persons) {
         defPersons = currDest.persons
        } else {
            if (typeof this.booking.tmp !== 'undefined') {
               this.booking.tmp.persons ? defPersons = this.booking.tmp.persons : defPersons = null
            } else {
               defPersons = null
            }
        }

       if (currDest.baggage) {
         defBaggage = currDest.baggage
        } else {
            if (typeof this.booking.tmp !== 'undefined') {
               this.booking.tmp.baggage ? defBaggage = this.booking.tmp.baggage : defBaggage = null
            } else {
               defBaggage = null
            }
         
        }

   }


   if (this.props.view_mode) {

     content_view.push(
   <div class="columns">

     <div class="column" style={{padding:0}}></div>
     <div class="column is-three-quarters" style={{paddingBottom:0,paddingTop:0}}>

       <div onClick={this.props.onClick} style={{cursor: "pointer"}}>
         <div class="columns is-gapless">
            
            <div class="column">
               <div class="field" style={{margin:"1rem"}}>
                 <div class="field-label is-normal">
                   <label class="label has-text-white-ter" align="left">Number of persons</label>
                 </div>
                 <div class="field-body">
                    <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defPersons}</span>
                 </div>
               </div>
            </div>

            <div class="column">
               <div class="field" style={{margin:"1rem"}}>
                 <div class="field-label is-normal">
                   <label class="label has-text-white-ter" align="left">Baggage information</label>
                 </div>
                 <div class="field-body">
                    <span class="input is-outlined is-white is-normal is-expanded has-background-light has-text-dark">{defBaggage}</span>
                 </div>
               </div>
            </div>

       </div>
     </div>
   </div>
   <div class="column" style={{padding:0}}></div>
 </div>
)


   } else {

   
    let selectCount = []
    let i
    let maxPers

    if (! this.booking.car_type || this.booking.car_type === -1 ) {
       maxPers = Math.max.apply(Math, this.props.reduxDictionary.cars.map(function(e) { return e.number_pers; }))
    } else {
       maxPers = this.props.reduxDictionary.cars.find(c=>c.type_id===this.booking.car_type).number_pers 
    }       

    for (i=1; i<=maxPers; i++) {
      selectCount.push(<option key={i} value={i}>{i}</option>)
    }


   content.push(

                     <div class="columns">
      
                      <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Number of persons</label>
                            <div class="control has-text-grey has-icons-left">
                              <div class="select has-text-grey is-fullwidth" style={{width: "100%", margin: "0px"}}>                 
                               <select defaultValue={defPersons} ref={(x) => { this.personsInputField = x; }}>
                                 {selectCount}
                               </select>
                               </div>
                               <span class="icon is-small is-left"><i class="fas fa-user-friends"></i></span>
                            </div>
                          </div>
                       </div>

                       <div class="column">
                        <div class="field">
                          <label class="label has-text-white-ter">Baggage information</label>
                            <div class="control has-text-grey">
                               <textarea class="textarea" type="text" rows="2" defaultValue={defBaggage} ref={(x) => { this.baggageInputField = x; }}/>
                            </div>
                          </div>
                       </div>
                    
                    </div> )

  }


  let buttons = []

  if (this.props.edit_mode === "step") {


        buttons.push(
        <div class="section">
          <div class="columns is-gapless">
             <div class="column"></div>
             <div class="column is-four-fifths">
  
                <div id={"pinfoButtons-"+this.props.transf_type_id} class="level is-mobile">
                  <div class="level-left">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.destStepDec}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-left"></i></span>
                       <span>Back</span>
                    </button>
                  </div>
                  <div class="level-right">
                    <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleNext}>
                       <span class="icon is-large"><i class="fas fa-chevron-circle-right"></i></span>
                       <span>Next</span>
                    </button>
                  </div>
                </div>
                     </div>
             <div class="column"></div>
          </div>
       </div>
                )


  } else if (!this.props.view_mode) {
        buttons.push(

      <div class="columns is-mobile" style={containerStyle}>

        <div class="column"></div>
         <div class="column is-three-quarters">

        <div class="buttons has-addons is-centered">
          <button className="button is-rounded is-outlined is-white" onClick={(e)=>{this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-window-close"></i></span><span>Cancel</span></button>
          <button className="button is-rounded is-danger" onClick={(e)=>{this.pushChanges(e); this.props.onViewMode()}}>
              <span class="icon"><i class="fas fa-file-download"></i></span><span>Save</span></button>
        </div>

         </div>
        <div class="column"></div>
      </div>

     )

  }
  

   content = (
      <div style={containerStyle}>

        <div id={"pinfoWelcome-"+this.props.transf_type_id} class={"section "+isHidden} style={{paddingTop: "1.5rem"}}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

          <div id={"pinfoBody-"+this.props.transf_type_id} class="section">
           <div class="columns is-gapless is-centered">
              <div class="column is-three-fifths">
                 {content}
              </div>
           </div>
          </div>

      </div>
    )

    
   return ( 
       
       <div style={{paddingTop:"5px"}}>

        { this.props.view_mode && content_view || content }

        {buttons}

       </div>
    )

  }
}


const mapStateToProps$PersonsInfo_ = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}


const mapDispatchToProps$PersonsInfo_ = function(dispatch) {
  return {
     updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
     bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
     bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}


export const PersonsInfo = connect(mapStateToProps$PersonsInfo_, mapDispatchToProps$PersonsInfo_)(PersonsInfo_)





///////////////////////////////////////////////////////////////////////////////////////////////



class InputOnlineGuide_ extends Component {
 
  static defaultProps = {
     view_mode: false,
     edit_mode: 'step'
  };

  constructor(props) {
   super(props);
   window.scrollTo(0, 0);

   this.calculatePrice = this.calculatePrice.bind(this);
   this.saveData = this.saveData.bind(this);
   this.validateData = this.validateData.bind(this);

   this.state = {booking: {startDate: this.props?.reduxBooking?.booking.startDate || moment().format('YYYY-MM-DD'),
                           endDate: this.props?.reduxBooking?.booking.endDate || moment().format('YYYY-MM-DD')},
                 validation: {name: true,
                              mail: true,
                              phone: true,
                              startDate: true,
                              endDate: true}
                } 

 }


 calculatePrice() {
    // Function to check if numDays falls within the specified range
    function isDaysInRange(minDays, maxDays, numDays) {
      let maxDaysNum = maxDays !== null ? maxDays : Infinity;
      return numDays >= minDays && numDays <= maxDaysNum;
    }

    let total_price_azn
    let total_price_eur
    let days
    if (this.state.booking.startDate === this.state.booking.endDate) {
      days = 1;
    } else {
      days = moment(this.state.booking.endDate).diff(moment(this.state.booking.startDate), 'days') + 1;
    }
    
    if (days <= 0) {
      days = 0
      total_price_azn = 0;
      total_price_eur = 0; 
    } else {
       // Find the pricing object based on the number of days
       let pricingObject = this.props.reduxDictionary.online_guide_price.find(pricing => {
           let { min_days, max_days } = pricing;
           return isDaysInRange(min_days, max_days, days);
       });

      total_price_azn = pricingObject.price_azn;
      total_price_eur = pricingObject.price_eur;
    }
    this.setState(prevState => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        days,
        current_price_azn: 0,
        current_price_eur: 0,
        total_price_azn,
        total_price_eur
      }
    })); 
 }

 async validateData(what, value) {
   let obj={}

   if (what) {
      await this.setState(prevState => ({
        ...prevState,
        validation: { ...prevState.validation, [what]: helpers.validate(what, value) }
        }))
      return
   }
   
   let returnValue = true
   Object.keys(this.state.validation).forEach((k) => {
     if (helpers.validate(k, this.state.booking[k])) {
       obj[k] = true
     } else {
       obj[k] = false
       returnValue = false
     }
   })

   console.log(this.state)

   if (this.state.booking.startDate > this.state.booking.endDate) {
     obj.startDate = false
     returnValue = false
   }

   obj.status = returnValue
   await this.setState(prevState => ({
    ...prevState,
    validation: { ...obj }
    }));
    
    if (returnValue === true) {
      this.props.updateOnlineGuideBooking(this.state)
    }

    console.log('Return value', returnValue)
    return this.state

 }


 async saveData() {
   await this.setState(prevState => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        name: this.nameInputField.value,
        mail: this.mailInputField.value,
        phone: this.phoneInputField.value,
        country: this.countryInputField.value,
        payment_option: this.paymentOptionField.value
      }
    }));

 }

 
 componentDidMount() {
    if (this.props.reduxBooking) {
      this.setState(this.props.reduxBooking);
    }
    this.calculatePrice();
 }
  
 render() {
  this.mainText = 'Provide some details and book now :)'

  let isHidden = null
  let containerStyle = null   
  if (this.props.view_mode || this.props.edit_mode !== "step") {
    isHidden = "is-hidden"
    containerStyle={backgroundColor: globals.edit_mode_bg_color,
                    backgroundImage: globals.edit_mode_bg_image}
  }

  
  let content = []
  let content_view = []
  let defName
  let defMail
  let defPhone
  let defCountry

  defCountry = window.sessionStorage.getItem('client_country_code') || null
  

  if (typeof this.state.booking !== 'undefined') {
  
      if (this.state.booking.name) {
        defName = this.state.booking.name
       } else {
        defName = null
       }

      if (this.state.booking.mail) {
        defMail = this.state.booking.mail
       } else {
        defMail = null
       }

      if (this.state.booking.phone) {
        defPhone = this.state.booking.phone
       } else {
        defPhone = null
       }

      if (this.state.booking.country) {
        defCountry = this.state.booking.country
       } 

  }


  let select = this.props.reduxDictionary.countries.map((c) => {
     return (<option key={c.code} value={c.code}>{c.name}</option>)
  })
  



   content.push(<div class="columns is-desktop">
                  
                  <div class="column">
                        <div class="field" style={{minWidth: "15rem"}}>
                          <label class="label has-text-white-ter">Your name</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.validation.name ? "" : "is-danger")} type="text" 
                                  defaultValue={defName} ref={(x) => { this.nameInputField = x; }}
                                  />
                               <span class="icon is-small is-left"><i class="fas fa-user"></i></span>
                            </div>
                            {! this.state.validation.name && <Error>Provide your name, how we can call you.</Error>}
                          </div>
                       </div>
      
                       <div class="column">
                        <div class="field" style={{minWidth: "15rem"}}>
                          <label class="label has-text-white-ter">Your email</label>
                            <div class="control has-text-grey has-icons-left">
                               <input class={"input " + (this.state.validation.mail ? "" : "is-danger")} type="email"  
                                  defaultValue={defMail} ref={(x) => { this.mailInputField = x; }}
                                  />
                               <span class="icon is-small is-left"><i class="fas fa-envelope"></i></span>
                            </div>
                            {! this.state.validation.mail && <Error>Email is very important, we will send confirmations to it. Enter correct email address.</Error>}
                          </div>
                       </div>
  
                    </div>
   )

   content.push(<div class="columns">

   <div class="column">
    <div class="field" style={{minWidth: "15rem"}}>
      <label class="label has-text-white-ter">Your phone number</label>
        <div class="control has-text-grey has-icons-left">
           <input class={"input " + (this.state.validation.phone ? "" : "is-danger")} type="tel"  
              defaultValue={defPhone} ref={(x) => { this.phoneInputField = x; }}
              />
           <span class="icon is-small is-left"><i class="fas fa-phone-volume"></i></span>
        </div>
        {! this.state.validation.phone && <Error>Phone number is important. Enter valid phone number please.</Error>}
      </div>
   </div>

   <div class="column">
    <div class="field" style={{minWidth: "15rem"}}>
      <label class="label has-text-white-ter">Your country</label>
        <div class="control has-icons-left">
           <div class="select has-text-grey is-fullwidth" style={{margin: "0px"}}>
             <select defaultValue={defCountry} ref={(x) => { this.countryInputField = x; }}>
              {select}
             </select>
           </div>
           <div class="icon is-small is-left"><i class="fas fa-globe"></i></div>
        </div>
      </div>
   </div>

</div>)

   content.push(<div class="columns">

                     <div class="column">
                      <div class="field" style={{minWidth: "15rem"}}>
                        <label class="label has-text-white-ter">From Date</label>
                            
                            <DateSelector defDate={this.state.booking.startDate} minDate="today" onSet={(d)=>{this.setState(prevState=>({...prevState, booking: {...prevState.booking, startDate:d}})); this.calculatePrice()}} isError={!this.state.validation.startDate} errorMessage="This date is required and must be same as or before end date"/>

                        </div>
                     </div>
    
                     <div class="column">
                      <div class="field" style={{minWidth: "15rem"}}>
                        <label class="label has-text-white-ter">To Date</label>

                        <DateSelector defDate={this.state.booking.endDate} minDate="today" onSet={(d)=>{this.setState(prevState=>({...prevState, booking: {...prevState.booking, endDate:d}})); this.calculatePrice()}} isError={!this.state.validation.endDate} errorMessage="This date is required"/>

                        </div>
                     </div>


        </div>)


       content.push(<div class="columns is-centered">

                     <div class="column is-half">
                      <div class="field" style={{minWidth: "15rem"}}>
                        <div class="box has-background-info"><label class="label is-size-4-desktop is-size-5-touch has-text-white-ter has-text-centered">Total price: {this.state.booking.total_price_azn} AZN for {this.state.booking.days} days</label></div>

                        </div>
                     </div>
    
        </div>)


       content.push(
        <div class="columns is-vcentered is-centered">
        <div class="column is-half">
        <div class="field" style={{margin:"1rem"}}>
               <label class="label has-text-white-ter" align="left">Pay now or later? Choose your option:</label>
               
               <div class="select has-text-grey is-fullwidth" style={{margin: "0px"}}>
                  <select defaultValue='Pay now' ref={(x) => { this.paymentOptionField = x; }}>
                      <option key="1" value="BEFORE">Pay now</option>
                      <option key="2" value="AFTER">Pay on last day</option>
                  </select>
                 </div>
             </div>
        </div>
        </div>
       )

   content = (<div style={containerStyle}>

         <div id="cinfoWelcome" class={"section "+isHidden}><p class="title has-text-white-ter is-size-1-widescreen is-size-2-desktop is-size-4-touch">{this.mainText}</p></div>

         <div id="cinfoBody" class="section">
          <div class="columns is-gapless is-vcentered is-centered">
            <div class="column"></div>
            <div class="column is-four-fifths">
                {content}
            </div>
            <div class="column"></div>
          </div>
         </div>

   </div>)



  return ( 
    <div>  

     { this.props.view_mode && content_view || content }
     
  
    </div>

   )

 }
}


const mapStateToProps$InputOnlineGuide_ = function (state) {
  return { reduxDictionary: state.dictionary,
           reduxBooking: state.online_guide_booking }
}


const mapDispatchToProps$InputOnlineGuide_ = function(dispatch) {
 return {
    updateOnlineGuideBooking: function(booking) { dispatch(Actions.actionCreateOnlineGuideBooking(booking)) }
 }
}


const InputOnlineGuide__ = connect(mapStateToProps$InputOnlineGuide_, mapDispatchToProps$InputOnlineGuide_)(InputOnlineGuide_)

// Wrapping the component using forwardRef, to forward the ref to this component to parents. This way I can call its functions from parent.
export const InputOnlineGuide = forwardRef((props, ref) => (
  <InputOnlineGuide__ forwardedRef={ref} {...props} />
));




///////////////////////////////////////////////////////////////////////////////////////////////


