import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
//import request from 'request'
import Reaptcha from 'reaptcha';

import './App.css';

//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

class Payment extends Component {
   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {
      verified: false,
      inProgress: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.sum = 0
    this.currency = 'none'
    this.bookid = null
    this.email = null
    this.bookingInfo = null
    this.service = this.props.reduxDictionary.services['TRANSFER']
  }

  onVerify = recaptchaResponse => {
    this.setState(prevState => ({
      ...prevState,
      verified: true
    }));
  };

  onExpire = recaptchaResponse => {
    this.setState(prevState => ({
      ...prevState,
      verified: false
    }));
  };

  handleClick(event) {
     let Sum = this.props.reduxSum || this.sum
     let Curr = this.props.reduxCurrency || this.currency

     if (this.props.reduxDictionary.processor === "ibar") {
        if ( ! this.verifyEmail()) {
          return
        } else {
          if (! this.email && this.emailInputField) { this.email = this.emailInputField.value }
        }
     }


     if (Sum > 0) {
        this.props.setValidity(1)
     }
      else { 
        this.props.setValidity(0)
        return false
     }

     this.setState(prevState => ({...prevState, inProgress: true}))
     helpers.getAPI(
         { func: (resp) => {
              if (resp.status === 'ERROR') {
                 helpers.alertMessage('Error message from server: '+resp.data, globals.ERRO);
                 this.setState(prevState => ({...prevState, inProgress: false}))
              } else {
                 window.location.href = resp.data;
                }
            }, 
           uri: globals.myAPIurl+'/pay?sum='+Sum+'&curr='+Curr+'&bookid='+this.bookid+'&email='+this.email+'&service='+this.service
         })

/*
     request.get({uri: 'https://az-tur.sigvamo.com/testapi/pay?sum='+Sum+'&curr='+Curr},
               function (error, response) {
                  if (error) { 
                       console.log('Cannot get data from API, Error: ', error);
                       return false
                    }

                  if (response.statusCode === 200) {
                       console.log('Received URL', response.body);
                       window.location.href = response.body;

                  } else {
                       console.log('Cannot get data from API: ', response.body)
                       return false
                  }
               })  */


  }
   

  verifyEmail() {
    if (! this.emailInputField) return true;
    let bgdef = this.emailInputField.style.backgroundColor
    if (! this.emailInputField.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && this.emailInputField.value) {
      this.emailInputField.focus();
       this.emailInputField.style.backgroundColor="#ff9b96"
       setTimeout(()=>(this.emailInputField.style.backgroundColor=bgdef), 300)
      helpers.alertMessage('Enter correct value for email', globals.WARN, true, 5000)
      return false
    }
    return true
  }

  componentWillMount() {
    this.sum = this.props.match.params.sum || 0;
    this.currency = this.props.match.params.curr || 'none'
    this.bookid = this.props.match.params.bookid || null
    this.email = this.props.match.params.email || null
    this.service = this.props.match.params.service || this.props.reduxDictionary.services['TRANSFER']
    if (globals.ENV === 'dev') console.log('DEBUG','sum',this.sum)
  }

 componentDidMount() {
 /*    if (this.sum>0) {
     helpers.alertMessage('Hellow to our system amount is '+this.sum);
   } else { helpers.alertMessage('Hellow to our system.') }
     setTimeout(()=>{
         helpers.closeMessage();
     }, 5000) */
  }

  render() {
   window.scrollTo(0, 0);

   this.bookingInfo = this.props.reduxBookingInfo

   let content;
   let curr=this.props.reduxCurrency || this.currency.toUpperCase()
   switch (curr) {
     case 'NONE': this.currency = 'AZN'
          break;
     case 'AZN': this.currency = 'AZN'
          break;
     case 'EUR': this.currency = 'EUR'
          break;
     default: 
       
     helpers.alertMessage('Wrong currency name provided \''+curr+'\'. Choose currency from the list please.', globals.WARN, true, 7000);
   
        return (
         <div class="hero is-fullheight-with-navbar has-background-success has-text-white-ter" style={{justifyContent:"start"}}>
          <div className="section" style={{display:"flex",justifyContent:"center"}}>
                   <ChooseCurrency nodefault/>
                </div>
        </div>);
   }

   let mail_confirmation

   if (this.props.reduxDictionary.processor === "ibar") {

   mail_confirmation = (
           <div className="field has-addons" style={{display:"flex",justifyContent:"center"}}>
                   <div className="section" style={{width:"100%", maxWidth:"500px"}}>
                    <div><p className="white-text">Enter your email to get email confirmation:</p></div>
                                  <div className="field">
                                   <input name="email" type="email" className="input is-medium is-rounded has-text-grey is-size-6-touch" style={{textAlign:"center"}} 
                                                          ref={(x) => { this.emailInputField = x; }} />
                                  </div>
                    
                       
                    </div>
            </div>
                  )
    }

   let booking_info = []


   if (this.bookingInfo) {
      this.currency = 'AZN'
      this.sum = this.bookingInfo.amount_to_pay_azn
      this.bookid = this.bookingInfo.booking_id
      this.email = this.bookingInfo.client_email
   }

   if (this.sum === 0) {
      content = (
        <div> 
          <h3 class="is-size-6-touch is-size-4-desktop" style={{marginBottom:"1rem"}}>Enter the amount and currency</h3>
          <div className="field has-addons" style={{display:"flex",justifyContent:"center"}}> 
            <EnterSum />
            <ChooseCurrency /> 
          </div>
          {mail_confirmation}
        </div>

          );
   } else {

  
      content = (
        <div>
             <h2 class="is-size-6-touch is-size-4-desktop">
              You will pay <b class="num">{this.props.reduxSum || this.sum}</b> {this.props.reduxCurrency || this.currency} to our account</h2>
              {(this.email === null) && mail_confirmation}
        </div>
          );
   }

    let serviceName = 'Transfer'
    if (this.service === 'TRANSFER') {
      serviceName = 'Transfer'
    } else if (this.service === 'ONLINE_GUIDE') {
      serviceName = 'Online Guide'
    } else {
      serviceName = this.service
    }


    if (this.bookid != null) {
      booking_info.push(
         <div className="section" style={{textAlign:"center"}}>
            <span class="is-size-6-touch is-size-4-desktop">
              Payment for Booking Number <b class="booking-id num green">{this.bookid}</b> and client <b>{this.email} for {serviceName} service.</b>
            </span>
         </div>              
        )

      }


   return ( 
       <div class="hero is-fullheight-with-navbar has-background-success has-text-white-ter" style={{justifyContent:"start"}}>
         <div class="section">
           <h1 class="is-size-5-touch is-size-2-desktop"><b>Welcome to AZ-TUR payment system</b></h1>
         </div>

         {booking_info}

         <div className="section"> {content} </div>
         
        { this.props.reduxValididy == 0 ? <div class="notification is-danger has-text-centered">The amount must be number and between 1 and 10000!</div> : null }
        <div className="container" style={{flexGrow:"0"}}>
            <Reaptcha sitekey={globals.RECAPTCHA_SITE_KEY} badge="inline" onVerify={this.onVerify} onExpire={this.onExpire}/>
        </div>
         <div className="section" style={{textAlign:"center"}}>
          <button className="button is-large is-light is-rounded is-outlined" 
             onClick={this.handleClick} disabled={((this.props.reduxValididy === 0 || this.props.reduxServerStatus === false) || !this.state.verified || this.state.inProgress) ? true : false}>
                <span class="icon is-large"><i class="far fa-credit-card"></i></span>
                &nbsp; <span>Pay {this.props.reduxSum || this.sum} {this.props.reduxCurrency || this.currency}</span>
          </button>
          
         </div>
         
       </div>
    )

  }
}


const mapStateToProps$Payment = function (state) {
   return { reduxCurrency: state.currency, 
            reduxSum: state.sum,
            reduxBookingInfo: state.booking_info,
            reduxOnlineGuideBookingInfo: state.online_guide_booking, 
            reduxValididy: state.valid,
            reduxServerStatus: state.serverStatus,
            reduxDictionary: state.dictionary }
}

const mapDispatchToProps$Payment = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

Payment = connect(mapStateToProps$Payment, mapDispatchToProps$Payment)(Payment)








class EnterSum extends Component {
   constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    this.currInputField.focus();
  }

  handleChange(event) {
    if (event.target.validity.valid && event.target.value > 0 && event.target.value <= 10000) {
        this.props.setSum(event.target.value) 
        this.props.setValidity(1)
    }
    else { 
       this.props.setValidity(0)
    }
  }
   
  render() {
    
   let mail_confirmation

   return ( 
       <div className="control">

         <input type="number" className="input is-rounded is-large has-text-grey" style={{maxWidth:"150px"}} pattern="[1-9]*" onChange={this.handleChange} 
              ref={(x) => { this.currInputField = x; }}
          />
         
       </div>          
    )

  }
}

const mapDispatchToProps$EnterSum = function(dispatch) {
  return {
    setSum: function(sum) { dispatch(Actions.actionSetSum(sum)) },
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

EnterSum = connect(null, mapDispatchToProps$EnterSum)(EnterSum)














class ChooseCurrency extends Component {
   constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.props.setCurrency(event.target.value);
  }
   
  render() {
  
/*   if (this.props.def == 'AZN') {
      let options = (<option value="AZN" selected>AZN</option>
                     <option value="EUR">EUR</option>)
   } else {
      let options = (<option value="AZN">AZN</option>
                     <option value="EUR" selected>EUR</option>)
   } */


   return ( 
     <div class="control">
       <div className="select is-large is-rounded has-text-grey">
         <select onChange={this.handleChange} >
            { this.props.nodefault ? <option value="NONE" disabled selected>-press to select-</option> : null }
               <option value="AZN" >AZN</option>
               <option value="EUR" >EUR</option>
         </select>
       </div>
     </div>
    )

  }
}

const mapDispatchToProps$ChooseCurrency = function(dispatch) {
  return {
    setCurrency: function(currency) { dispatch(Actions.actionSetCurrency(currency)) }
  }
}

ChooseCurrency = connect(null, mapDispatchToProps$ChooseCurrency)(ChooseCurrency)



export default Payment



