import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'
import { DisappearedLoading } from 'react-loadingg';
import { withRouter } from "react-router-dom";


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'


import ContactBanner from './ContactBanner.jsx'



class ContactPage extends Component {
   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleClick = this.handleClick.bind(this)
    this.validateData = this.validateData.bind(this)

    this.state = {name: true,
                  email: true,
                  message: true,
                  loading: false}

  }


  validateData() {
     let valid = true
     if (helpers.validate('full_name',this.f_name.value)) {
        this.setState({name: true})
     } else {
        this.setState({name: false})
        valid = false
     }
     if (helpers.validate('mail',this.f_email.value)) {
        this.setState({email: true})
     } else {
        this.setState({email: false})
        valid = false
     }
     if (helpers.validate('full_name',this.f_message.value)) {
        this.setState({message: true})
     } else {
        this.setState({message: false})
        valid = false
     }

    return valid

  }
  
  handleClick() {
  
     if (this.validateData()) {
        
         this.setState({loading: true})
             helpers.postAPI(
              { func: function(resp) {
              //After receiving response from server
              if (resp.status === 'OK') {                 
                 helpers.closeMessage()
                 helpers.alertMessage('Your message was successfully sent. We will contact you soon.', globals.INFO, true, 20000)
                 if (this.f_name) {
                   this.f_name.value = null
                   this.f_company.value = null
                   this.f_email.value = null
                   this.f_message.value = null
                 }
                 this.setState({loading: false})
              } else {
                 helpers.closeMessage()
                 helpers.alertMessage('Failed to send email. Some problems on the server side, try later or contact us on whatsApp.', globals.ERRO, true, 20000)
                 this.setState({loading: false})

              }

          }.bind(this), 
           uri: globals.myAPIurl+"/contact",
           data: {name: this.f_name.value, company: this.f_company.value, email: this.f_email.value, message: this.f_message.value}
         })

     }
  }

  render() {
  

   let content

 
   if (typeof this.props.reduxDictionary === 'undefined') {
       return (<div><DisappearedLoading color="#000000" size="large"/></div>)
     } 
 
   let loading
   console.log('STATUS', this.props.serverReachable)
   if (this.props.serverReachable && this.state.loading === false) {
      loading=null 
    } else { 
      loading='is-loading'
    }

   return ( 
       <div style={{marginBottom:"5rem"}}>


<a class="anchor" id="about"></a>
<section class="hero is-dark is-medium contact-page-image">

<div class="hero-head">
  </div>

  {/* Hero content: will be in the middle */}
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title is-size-4-mobile is-size-1-desktop">
        Contact us
      </h1>
      <h2 class="subtitle is-size-6-mobile">
      
      </h2>
        
    </div>
  </div>


</section>



<section class="section">

<div class="columns">
  <div class="column is-2"></div>

  <div class="column">


  <div class="notification has-background-transparent is-size-5-desktop">
    <p style={{margin:"0.5rem"}} dangerouslySetInnerHTML={{__html: helpers.cnt('contact_1')}}></p> 
    <p style={{margin:"0.5rem"}} dangerouslySetInnerHTML={{__html: helpers.cnt('contact_2')}}></p>
    <p style={{margin:"0.5rem"}} dangerouslySetInnerHTML={{__html: helpers.cnt('contact_3')}}></p>
  </div>


    
     <div>
        
        <div class="field">
  <label class="label">Name</label>
  <div class="control">
    <input class={! this.state.name && "input is-danger" || "input"} type="text" ref={(x)=>this.f_name=x}/>
  </div>
  {! this.state.name && <div class="help is-danger"><b>Please, enter your name.</b></div> }
</div>


        <div class="field">
  <label class="label">Company name</label>
  <div class="control">
    <input class="input" type="text" ref={(x)=>this.f_company=x}/>
  </div>
</div>



<div class="field">
  <label class="label">E-mail</label>
  <div class="control has-icons-left">
    <input class={! this.state.email && "input is-danger" || "input"} type="email" ref={(x)=>this.f_email=x}/>
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
  </div>
  {! this.state.email && <div class="help is-danger"><b>Please, enter your e-mail address.</b></div> }
</div>


<div class="field">
  <label class="label">Message</label>
  <div class="control">
    <textarea class={! this.state.message && "textarea is-danger" || "textarea"} ref={(x)=>this.f_message=x}></textarea>
  </div>
  {! this.state.message && <div class="help is-danger"><b>You forgot the message.</b></div> }
</div>


<div class="field centered" style={{margin:"3rem"}}>
  <div class="control">
                               <button className={"button is-large is-dark is-rounded is-outlined "+loading} onClick={this.handleClick}>
                                   <span class="icon is-large"><i class="far fa-paper-plane"></i></span>
                                   <span>Send</span>
                               </button>
  </div>
 </div>

     </div>
 
  </div>

  <div class="column is-2"></div>


</div>

</section>








       </div>
    )

  }
}


const mapStateToProps$ContactPage = function (state) {
   return { reduxDictionary: state.dictionary,
            serverReachable: state.serverStatus }
}

const mapDispatchToProps$ContactPage = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

export default withRouter(connect(mapStateToProps$ContactPage, mapDispatchToProps$ContactPage)(ContactPage))



