import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'
import { DisappearedLoading } from 'react-loadingg';
import { withRouter } from "react-router-dom";


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'





class RegionsPage extends Component {
   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.handleClick = this.handleClick.bind(this)


  }

  
  handleClick(event) {
    this.props.clearBooking()
    this.props.clearBookingInfo()
    this.props.createBooking(-1, 3)
    this.props.bookingStepStart(1)
    this.props.history.push('/atform')
  }



  render() {
  
   if (! this.props.reduxDictionary) {
      return (<div style={{paddingBottom:"5rem"}} class="has-background-dark"><DisappearedLoading/></div>)
   }

   let content


 return ( 
       
<div style={{marginBottom:"5rem"}}>


<section class="hero is-dark is-medium regions-page-image">

<div class="hero-head">
  </div>

  {/* Hero content: will be in the middle */}
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title is-size-4-mobile is-size-1-desktop">
        Travel to our regions
      </h1>
      <h2 class="subtitle is-size-6-mobile">
      
      </h2>
        
    </div>
  </div>


</section>


<section class="section"> 


  <div class="columns">

  <div class="column is-2"></div>

  <div class="column">

<div class="notification has-background-transparent has-text-centered is-size-4-desktop is-size-5-touch" dangerouslySetInnerHTML={{__html: helpers.cnt('region_page_1')}}></div>

<div class="notification has-background-transparent has-text-centere is-size-5-desktop" style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: helpers.cnt('region_page_2')}}></div>

<div class="notification has-background-transparent has-text-centered is-size-7-touch" dangerouslySetInnerHTML={{__html: helpers.cnt('region_page_3')}}></div>

<div class="notification has-background-transparent has-text-centered is-size-7-touch" dangerouslySetInnerHTML={{__html: helpers.cnt('region_page_4')}}></div>



<div class="field centered" style={{margin:"3rem"}}>
  <div class="control">
                               <button className={"button is-large is-dark is-rounded is-outlined"} onClick={this.handleClick}>
                                   <span class="icon is-large"><i class="fas fa-handshake"></i></span>
                                   <span>Book online now</span>
                               </button>
  </div>
 </div>

     </div>

  <div class="column is-2"></div>

</div>

</section>

</div>

    )

  }
}


const mapStateToProps$RegionsPage = function (state) {
   return { reduxDictionary: state.dictionary,
            serverReachable: state.serverStatus }
}

const mapDispatchToProps$RegionsPage = function(dispatch) {
  return {
    createBooking: function(carTypeId, transferType) { dispatch(Actions.actionCreateBooking({car_type: carTypeId, transfer_type: transferType})) },
    bookingStepStart: function(step) { dispatch(Actions.actionBookingStepStart(step)) },
    clearBookingInfo: function() { dispatch(Actions.actionClearBookingInfo()) },
    clearBooking: function() { dispatch(Actions.actionClearBooking()) }
  }
}

export default withRouter(connect(mapStateToProps$RegionsPage, mapDispatchToProps$RegionsPage)(RegionsPage))



