import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './redux-actions.js';
import { DisappearedLoading } from 'react-loadingg';
import { useHistory } from 'react-router-dom';
import * as globals from './globals.js';
import * as helpers from './helpers.js';

import { InputOnlineGuide } from './FormInputComponents.jsx' 

const OnlineGuidePage = () => {
  const dispatch = useDispatch();
  const reduxDictionary = useSelector(state => state.dictionary);
  const reduxBooking = useSelector(state => state.online_guide_booking);
  const serverReachable = useSelector(state => state.serverStatus);
  const history = useHistory();
  const inputFormRef = useRef(null);
  const bookConfirmationRef = useRef(null);
  const [click, setClick] = useState(0);
  const [paylater, setPaylater] = useState(0);
  const [bookinID, setBookingID] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  useEffect(() => {
    if (click === 1 && reduxBooking?.validation.status === true) {
      console.log('Booking submitted')
      setClick(0)
      handleSubmit()
    }
  }, [reduxBooking]);
  
  useEffect(() => {
    if (paylater === 1 && bookConfirmationRef.current) {
      bookConfirmationRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [paylater]);
  

  const handleSubmit = () => {
    helpers.postAPI(
       { func: function(resp) {
            //After receiving response from server
            if (resp.status === 'OK') {
               console.log('OK', resp)
               
               if (reduxBooking.booking.payment_option === "BEFORE") {
                   history.push(`/payme/${reduxBooking.booking.total_price_azn}/AZN/${resp.booking_id}/${reduxBooking.booking.mail}/ONLINE_GUIDE`)
               } else {
                   setPaylater(1)
                   setBookingID(resp.booking_id)
               }
               
            } else if (resp.status === 'DUPLICATE') {
                 console.log(resp.message)
                 helpers.alertMessage('Sorry, a booking with overlapping dates has already been made for this email address. If you believe this is an error or need further assistance, please contact us at info@az-tur.com or our 24/7 WhatsApp.', globals.INFO, true, 60000)
            } else {
                 helpers.alertMessage(resp.message, globals.ERRO, true, 60000)
            }
        }.bind(this), 
         uri: globals.myAPIurl+'/bookguide',
         data: reduxBooking.booking
       })
  }


  const formatPricingInfo = () => {
    const parts = [];

    reduxDictionary.online_guide_price.forEach(({ min_days, max_days, price_azn, price_eur }) => {
      let daysRange;
      if (min_days === max_days) {
        daysRange = `${min_days} day`;
      } else if (max_days === 1000) {
        daysRange = `${min_days} days or more`;
      } else {
        daysRange = `${min_days} to ${max_days} days`;
      }

      parts.push(`<tr><td class="is-warning"><b>${daysRange}</b></td> <td class="is-warning"><b>${price_azn}</b> AZN <span class="is-size-7">(${(reduxDictionary.use_current_rates != "yes") ? price_eur : Math.ceil((price_azn / reduxDictionary.currency_exchange_rates.eur_price) * (1 + reduxDictionary.currency_exchange_rates.add_pct/100))} EUR)</span></td></tr>`);
    });

    return `<table class="table is-bordered is-striped is-narrow"><tbody> ${parts.join(' ')}</tbody></table>`;
  }

  const handleClick = async () => {
    setClick(1)
    await inputFormRef.current.saveData();
    let submit = await inputFormRef.current.validateData();
  };

  if (!reduxDictionary) {
    return (
      <div style={{ paddingBottom: '5rem' }} className="has-background-dark">
        <DisappearedLoading />
      </div>
    );
  }

  let booking_section

  /*
  if (reduxBooking?.validation.status === true) {
    handleSubmit()
  }
  */


  if (paylater === 1) {

    booking_section = (<div className="column">

    <div className="notification has-background-transparent has-text-centered is-size-5-desktop">
       <p>Thank you for your booking. Your booking number is <div class="tag">{bookinID}</div>.</p><br />
       <p>Check your email for confirmation and more details.</p><br />
       <p>We will send you payment instructions on the end of your last day.</p>
    </div>
    
  </div>)

  } else {

  booking_section = (<div className="column">

  <div
    className="notification has-background-transparent has-text-centered is-size-5-desktop"
    dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_19') }}
  ></div>

  <InputOnlineGuide forwardedRef={inputFormRef}/>

  <div className="field centered" style={{ margin: '3rem' }}>
    <div className="control">
      <button
        disabled={!serverReachable}
        className="button is-large is-light is-rounded is-outlined"
        onClick={handleClick}
      >
        <span className="icon is-large">
          <i className="fas fa-handshake"></i>
        </span>
        <span>Book it</span>
      </button>
    </div>
  </div>
</div>)

  }

  return (
    <div style={{ marginBottom: '5rem' }}>
      <section className="hero is-dark is-medium onlineguide-page-image">
        <div className="hero-head"></div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-size-4-mobile is-size-1-desktop">Explore Azerbaijan with Our Convenient Online Guide Service</h1>
            <h2 className="subtitle is-size-6-mobile"></h2>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            <div
              className="notification has-background-transparent has-text-centered is-size-4-desktop is-size-5-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_1') }}
            ></div>
            
            <div className="columns is-centered">
          <div className="column is-6-desktop is-4-widescreen">
          <div class="box has-background-warning">
               <div class="field centered has-text-dark is-size-5-desktop is-size-6-touch" dangerouslySetInnerHTML={{ __html: formatPricingInfo()}}></div>
            </div>
          </div>
             </div>
            
            <p class="has-text-dark is-size-5-desktop is-size-6-touch has-text-danger">You have the option to pay securely and without any risk on your last day, after our service has been completed.</p>

            
            <div className="field centered" style={{ marginTop: '2.3rem' }}>
           
                  <button disabled={!serverReachable} className="button is-large is-danger is-rounded "  onClick={()=>{bookConfirmationRef.current.scrollIntoView({ behavior: 'auto', block: 'nearest' })}}>
                    <span>Booking form</span>
                  </button>
           
           </div>


          </div>
          <div class="column is-2"></div>
        </div>
      </section>


      <section className="section background-white has-text-light">
        <div className="columns is-vcentered">
          <div className="column is-2"></div>
          <div className="column">
             <div className="notification has-background-transparent has-text-left is-size-4-desktop is-size-5-touch">
                

             <article className="message is-dark">
               <div className="message-header">
                  <div className="tag is-warning is-medium">STEP 1</div>
               </div>
               <div className="message-body has-background-dark">
                    <div className="content has-text-light is-size-5-desktop is-size-6-touch">
                          <span dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_3').replace('{price}', `${reduxDictionary.online_guide_price[0].price_azn} <span class="ios">₼</span> (or ${reduxDictionary.online_guide_price[0].price_eur} &euro;)`) }}></span>
                       </div>
               </div>
             </article>
               

             <article className="message is-dark">
               <div className="message-header">
                  <div className="tag is-warning is-medium">STEP 2</div>
               </div>
               <div className="message-body has-background-dark">
                    <div className="content has-text-light is-size-5-desktop is-size-6-touch">
                      <span dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_4') }}></span>
                       </div>
               </div>
             </article>

             <article className="message is-dark">
               <div className="message-header">
                  <div className="tag is-warning is-medium">STEP 3</div>
               </div>
               <div className="message-body has-background-dark">
                    <div className="content has-text-light is-size-5-desktop is-size-6-touch">
                      <span dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_5') }}></span>
                       </div>
               </div>
             </article>

               
                              
             </div>
          </div>
          <div class="column is-one-quarter">
          <figure class="image">
              <img src="https://aztur-global.s3.eu-central-1.amazonaws.com/whatsapp_cha2.png" />
          </figure>
          </div>
          <div class="column is-2"></div>
        </div>
      </section>


      <section className="section"  >
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">

          <div class="columns">
              <div class="column">

              <div
              className="block has-background-transparent has-text-centered is-size-4-desktop is-size-5-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_6') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_7') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_8') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_9') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_10') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_11') }}
            ></div>
       



              </div>
              <div class="column">

              <div
              className="block has-background-transparent has-text-centered is-size-4-desktop is-size-5-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_13') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_14') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_15') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_16') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_17') }}
            ></div>
            <div
              className="block has-background-transparent has-text-centered is-size-5-desktop is-size-6-touch"
              dangerouslySetInnerHTML={{ __html: helpers.cnt('oguide_18') }}
            ></div>


              </div>
          </div>

            
          </div>
          <div class="column is-2"></div>
        </div>
      </section>

      <section ref={bookConfirmationRef} className="section has-background-info has-text-light">
        <div className="columns">
          <div className="column is-2"></div>
          {booking_section}
          <div className="column is-2"></div>
        </div>
      </section>
    </div>
  );
};

export default OnlineGuidePage;
