import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import * as globals from './globals.js'

class MessageBar extends Component {

render() {
 
 
 if (! this.props.alert) {
 	return null
 }

 let ClassColor
 let barStyle = {position: "fixed", marginTop: "0px", zIndex: 1001, width: "100%"}

 if (this.props.alert.visible) {
      barStyle = Object.assign(barStyle, {display: 'block'})
 } else {
 	  barStyle = Object.assign(barStyle, {display: 'none'})
 }

 switch(this.props.alert.type) {
 	case globals.ERRO:
 	     ClassColor = 'is-danger lighten-1'
 	     break;
 	case globals.INFO:
 	     ClassColor = 'is-success'
 	     break;
 	case globals.WARN:
 	     ClassColor = 'is-warning'
 	     break;
 	default:
 	     ClassColor = 'is-success'
 }


  return (

      <div className="toppanel" style={barStyle}>

             <div className={'notification' + ' ' + ClassColor}>
                <button class="delete" onClick={this.props.clearAlert.bind(this)}></button>
                <span id="msg-bar-text">{this.props.alert.message}</span>
             </div> 

      </div>

    )

}

}

const mapStateToProps$MessageBar = function (state) {
   return { alert: state.alert }
}

const mapDispatchToProps$MessageBar = function(dispatch) {
  return {
    clearAlert: function() {
      dispatch(Actions.actionSetAlert({visible: false }))
    }
  }
}

export default connect(mapStateToProps$MessageBar, mapDispatchToProps$MessageBar)(MessageBar)
