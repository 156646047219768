import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'
import { DisappearedLoading } from 'react-loadingg';

import { withRouter } from "react-router-dom";


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

//import { getGeneralParameterValue } from './helpers.js'


import { InputTransferType, 
         InputClientInfo, 
         Destination,
         SpecialNotes,
         Simcards,
         Summary,
         EnterSum } from './FormInputComponents.jsx' 


class ATForm extends Component {
   constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.carTypeId = this.props.match.params.cartype;
  }



handleClick(event) {
   if (globals.ENV === 'dev') console.log('DEBUG','history',this.props.history)
   this.props.history.goBack()
}

 componentDidMount() {
   if ( ! this.props.reduxBookingStep ) {
      this.props.bookingStepStart()
   }
}

  render() {
  
   let content=[]
   let currentStep = this.props.reduxBookingStep
   this.booking = Object.assign({}, this.props.reduxBooking)
   this.dictionary = Object.assign({}, this.props.reduxDictionary)



   if (typeof this.booking === 'undefined' ||
       typeof this.dictionary === 'undefined' ||
       Object.keys(this.dictionary).length === 0 ||
       ! (currentStep >= 0)
       ) {
      return (<div><DisappearedLoading/></div>)
   }

   
    if (! this.booking.car_type) {
      this.props.history.replace('/')
   }  
  

   
   let OfferSimcards = helpers.getGeneralParameterValue('offer_simcards')


   switch (true) {

    case currentStep === 0:
       content = <InputTransferType />
       break;
    
    case currentStep === 1:
       content = <InputClientInfo />
       break;

    case currentStep >= 2 && currentStep <= 2 + (this.booking.transfer_types_selected.length - 1):

       if ( typeof this.booking.destinations === 'undefined' ) {
          this.booking.destinations = []
          this.props.updateBooking(this.booking)   
       }

       content = <Destination transf_type_id={this.booking.transfer_types_selected[currentStep - 2]} />

       break;

    case currentStep === 1 + 2 + (this.booking.transfer_types_selected.length - 1):
          content = <SpecialNotes />
          break; 


    case currentStep === 2 + 2 + (this.booking.transfer_types_selected.length - 1):
       if (OfferSimcards === "yes" && (this.booking.transfer_types_selected.includes(1) || this.booking.transfer_types_selected.includes(3)) ) {
          content = <Simcards />
          break; 
       }

    case currentStep === 3 + 2 + (this.booking.transfer_types_selected.length - 1):
       content = <Summary />
       break;


   }

/*          content.push(<div>
                  <button className="btn" onClick={this.handleClick}>
                      Back
                    </button>
            </div>)         */
 
   return ( 
       <div class="hero is-fullheight-with-navbar has-background-info has-text-white-ter">
         {content}         
       </div>
    )

  }
}


const mapStateToProps$ATForm = function (state) {
   return { reduxBooking: state.booking,
            reduxBookingStep: state.booking_step,
            reduxDictionary: state.dictionary }
}

const mapDispatchToProps$ATForm = function(dispatch) {
  return {
    updateBooking: function(booking) { dispatch(Actions.actionUpdateBooking(booking)) },
    bookingStepStart: function() { dispatch(Actions.actionBookingStepStart()) },
    bookingStepInc: function() { dispatch(Actions.actionBookingStepInc()) },
    bookingStepDec: function() { dispatch(Actions.actionBookingStepDec()) }
  }
}

ATForm = connect(mapStateToProps$ATForm, mapDispatchToProps$ATForm)(ATForm)


export default withRouter(ATForm)




