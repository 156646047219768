
import { createStore, applyMiddleware } from 'redux';
import update from 'immutability-helper'
import * as Actions from './redux-actions.js'
import * as helpers from './helpers.js'
import * as globals from './globals.js'

const Reducer = function (state={}, action) {
  let newState = {}
  switch(action.type) {

    case Actions.BOOKING_STEP_START:
         //newState = Object.assign({}, state)
         newState = JSON.parse(JSON.stringify(state))
         /*if (typeof state.booking_step !== 'number' ) {*/
           newState = update(state, {booking_step: {$set: action.value }} )
           if (newState.booking && newState.booking.destinations) {
             newState.booking.destinations.forEach((dest)=>{
                dest.step_id=0
             })
           }
         /*}*/
         return newState    
      break;

    case Actions.BOOKING_STEP_INC:
         if ( typeof state.booking_step === undefined ) { return state }
         newState = update(state, {booking_step: {$set: state.booking_step + 1 }} )
         window.sessionStorage.setItem('booking_step', newState.booking_step)
         return newState    
      break;

    case Actions.BOOKING_STEP_DEC:
         if ( ! state.booking_step || state.booking_step === 0 ) { return state }
         newState = update(state, {booking_step: {$set: state.booking_step - 1 }} )
         window.sessionStorage.setItem('booking_step', newState.booking_step)
         return newState    
      break;

    case Actions.CREATE_ONLINE_GUIDE_BOOKING:
         newState = update(state, {online_guide_booking: {$set: action.value }} )
         return newState
      break;
   
    case Actions.CREATE_BOOKING:
         if (typeof state.booking === 'undefined') {
            newState = update(state, {booking: {$set: {car_type: action.value.car_type} }} )
         } else {
            newState = update(state, {booking: {car_type: {$set: action.value.car_type} }} )
         }
         
         if ( action.value.transfer_type ) {
            newState.booking.transfer_types_selected = []
            newState.booking.transfer_types_selected.push(action.value.transfer_type)
         }

         return newState    
      break;

    case Actions.SET_BOOKING_INFO:
         newState = update(state, {booking_info: {$set: action.value }} )
         window.sessionStorage.setItem('booking_info', JSON.stringify(newState.booking_info))
         return newState    
      break;


    case Actions.CLEAR_BOOKING_INFO:
         newState = update(state, {booking_info: {$set: null }} )
         window.sessionStorage.removeItem('booking_info')
         return newState    
      break;


    case Actions.CLEAR_BOOKING:
         newState = update(state, {booking: {$set: {} }} )
         newState = update(newState, {booking_step: {$set: null }} )
         window.sessionStorage.removeItem('Booking')
         window.sessionStorage.removeItem('booking_step')
         helpers.bookingStorage(null,'destroy')
         return newState    
      break;

    case Actions.UPDATE_BOOKING:
         if ( ! state.booking ) { return state }
         newState = update(state, {booking: {$set: action.value }} )
         newState.booking.SUM=helpers.calculateBookingSum(newState)
         window.sessionStorage.setItem('Booking', JSON.stringify(newState.booking))
         return newState    
      break;

    case Actions.SET_CURRENCY:
         newState = update(state, {currency: {$set: action.currency }} )
         return newState    
      break;

    case Actions.SET_SUM:
         newState = update(state, {sum: {$set: action.sum }} )
         return newState
      break;

     case Actions.SET_VALIDITY:
         newState = update(state, {valid: {$set: action.valid }} )
         return newState
      break;

    case Actions.SET_ALERT:
         newState = update(state, {alert: {$set: action.alert }} )
         if (state.alert && state.alert.timeout && action.alert.visible === false) {
           clearTimeout(state.alert.timeout)
         }
         return newState    
    break;

    case Actions.LOAD_DICTIONARY:
         newState = update(state, {dictionary: {$set: action.value }} )
         let sessionBooking = JSON.parse(window.sessionStorage.getItem('Booking'))
         let sessionBookingInfo = JSON.parse(window.sessionStorage.getItem('booking_info'))
         if (sessionBooking) {
            newState.booking = sessionBooking
            newState.booking_step = Number(window.sessionStorage.getItem('booking_step'))
         }
         if (sessionBookingInfo) {
            newState.booking_info = sessionBookingInfo
         }
         return newState    
    break;


    case Actions.SET_SERVER_STATUS:
         if (state.serverStatus !== action ) {
            newState = update(state, {serverStatus: {$set: action.value }} )
            return newState    
          } else {
            return
          }
    break;

    case Actions.ADD_POPUP:
       /* Here we will check if there was no popups then we will set it. If there is already popup object then we will check it by id
          and if popup with requested id already exists then new one will not be added. */
       
       let id_exists = false
       if (! state.popups) { 
             newState = update(state, {popups: {$set: [action.what] }} )
         } else {
           state.popups.find((b) => { 
             if (b.id == action.what.id) {
                id_exists = true
                return null
               } })
             
             if ( ! id_exists ) {
               newState = update(state, {popups: {$push: [action.what] }} )
              } else {
                   return state
              }
        }

       return newState
    break;


    case Actions.REMOVE_POPUP:
       
       if (! state.popups) { 
             return state
         } else {
           // Just generate new object from existing one, then we will manipulate it and return
           // newState = update(state, {popups: {$merge: {} }} ) //Do not work after update of immutability-helper package
             newState = JSON.parse(JSON.stringify(state)) // Create new object from state
             newState.popups = state.popups.slice()  // Duplicate popups as new object from original state.popups
             helpers.delElement(newState.popups, action.what, "id")
        }

       return newState
    break;





    default:
        return state
  }
}



/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd(action.type)
  return result
}

var createStoreWithMiddleware = applyMiddleware(logger)(createStore)

var Store = (globals.ENV === 'dev') ? createStoreWithMiddleware(Reducer) : createStore(Reducer)

export default Store

