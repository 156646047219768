
// Can be dev or prod
export const ENV = 'prod'

export const myServer = (ENV === 'dev') ? 'https://az-tur.dev.sigvamo.com' : 'https://az-tur.com'
export const myURL = (ENV === 'dev') ? 'https://az-tur.dev.sigvamo.com' : 'https://az-tur.com'
export const myAPIurl = (ENV === 'dev') ? 'https://az-tur.dev.sigvamo.com/testapi' : 'https://az-tur.com/api'
export const myEmail = 'info@az-tur.com'

export const RECAPTCHA_SITE_KEY = '6Ld6_xUjAAAAANEcPwNxdlGuUHwEgWpwidwgsWwn'

export var WARN = 'WARN'
export var INFO = 'INFO'
export var ERRO = 'ERRO'

export const edit_mode_bg_color = '#00040d'
export const edit_mode_bg_image = 'url("https://www.transparenttextures.com/patterns/escheresque-dark.png")'

//export const edit_mode_bg_color = '#0f0f00'
//export const edit_mode_bg_image = 'url("https://aztur-global.s3.eu-central-1.amazonaws.com/grey-washed-wall.png")'

export var apiReqRetry = 15

/* Used to get wait timeouts for getting data from API */
export var apiReqTimeout = function (r=0) {
      if (r < 3) {
      	 return 3000
      } else if (r < 6) {
      	 return 7000
      } else if (r < 10) {
      	 return 15000
      } else if (r < 20) {
      	 return 60000
      } else if (r > 20) {
      	 return 180000
      }
}

export const EUR = 978
export const AZN = 944

export const getCurrency = function (curr) {
      switch (curr) {
         case EUR: return 'EUR'
         case AZN: return 'AZN'
         default: return 'unknown'   
      }
}


/* Popup IDs. Group of Popups has the same ID, it prevents opening two popups with same Id in parallel */
export var POPUP_ID_VARIABLES = 'VARS'
export var POPUP_ID_CKLSTEDITOR = 'CKLSTEDITOR'
