import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'
import { DisappearedLoading } from 'react-loadingg';
import { withRouter } from "react-router-dom";


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

import CarList from './CarList.jsx'
import ContactBanner from './ContactBanner.jsx'



class MainPage extends Component {
   constructor(props) {
    super(props);

  }


  render() {
  
   //window.scrollTo(0, 0);

   let content

   if (typeof this.props.reduxDictionary === 'undefined') {
       return (<div><DisappearedLoading color="#000000" size="large"/></div>)
     } 

   let messages

   if (this.props.reduxDictionary.messages.length > 0) {
      
      messages = this.props.reduxDictionary.messages.map((msg, idx) => {
         return (
           <div key={idx} className={ (msg.severity === "INFO") ? "notification is-info" : "notification is-danger" } 
               dangerouslySetInnerHTML={{__html: msg.message}}>
           </div>
          )

      })

   }
 
   return ( 
       <div>

<section class="hero is-dark is-medium start-page-image">

<div class="hero-head">
  </div>

  {/* Hero content: will be in the middle */}
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title is-size-4-mobile is-size-1-desktop">
        Azerbaijan Turizm
      </h1>
      <h2 class="subtitle is-size-6-mobile">
        Transfer and tourism services in Azerbaijan
      </h2>
      
      <div class="centered">
        <div style={{backgroundColor:"rgba(0,0,0,0.1)",borderRadius:"20px",padding:"10px"}}><ContactBanner /></div>
      </div>
  
    </div>
  </div>


</section>

<section class="section">

       <div class="columns is-centered">
         
         <div class="column is-four-fifths" style={{maxWidth: "900px"}}>

           {messages}

           <div class="notification has-background-transparent has-text-centered is-size-5-desktop" dangerouslySetInnerHTML={{__html: helpers.cnt('main_page_1')}}></div>

           <div class="has-text-centered is-size-3-desktop is-size-4-touch" dangerouslySetInnerHTML={{__html: helpers.cnt('title_1')}}></div>

           <div class="notification has-background-transparent is-size-7-touch" style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: helpers.cnt('main_page_2')}}></div>

           <CarList />         

         </div>
         
       </div>

</section>




<a class="anchor" id="about"></a>
<section class="hero is-dark is-medium about-page-image">

<div class="hero-head">
  </div>

  {/* Hero content: will be in the middle */}
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title is-size-4-mobile is-size-1-desktop">
        Who we are
      </h1>
      <h2 class="subtitle is-size-6-mobile">
      
      </h2>
        
    </div>
  </div>


</section>




<section class="section">

<div class="columns">
  <div class="column is-2"></div>

  <div class="column">

  <div class="columns">

  <div class="column">
    <div style={{height:"100%"}}>
      <div class="notification has-text-light" style={{backgroundColor:"rgba(0,0,0,0.3)"}}><h3 class="subtitle" style={{fontWeight:"400"}} dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_1')}}></h3></div>
    </div>
  </div>
  <div class="column">
    <div style={{height:"100%"}}>
      <div class="notification has-text-light" style={{backgroundColor:"rgba(0,0,0,0.3)"}}><h3 class="subtitle" style={{fontWeight:"400"}} dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_2')}}></h3></div>
    </div>
  </div>

  </div>
 

  <div class="columns">

  <div class="column">
    <div style={{height:"100%"}}>
      <div class="notification has-text-light" style={{backgroundColor:"rgba(0,0,0,0.3)",height:"100%"}}><h3 class="subtitle" style={{fontWeight:"400"}} dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_3')}}></h3></div>
    </div>
  </div>
  <div class="column">
    <div style={{height:"100%"}}>
      <div class="notification has-text-light" style={{backgroundColor:"rgba(0,0,0,0.3)"}}><h3 class="subtitle" style={{fontWeight:"400"}} dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_4')}}></h3></div>
    </div>
  </div>
  
  </div>
  </div>

  <div class="column is-2"></div>


</div>

</section>





<section class="section" style={{marginTop:"0rem", paddingTop:"0rem"}}>
  <div class="columns">
    <div class="column is-2"></div>
    <div class="column">

       <div class="has-text-centered is-size-3-desktop is-size-4-touch" dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_5')}}></div>
       <div class="has-text-centered is-size-5-desktop is-size-6-touch" style={{marginTop:"2rem"}} dangerouslySetInnerHTML={{__html: helpers.cnt('about_t_6')}}></div>

    </div>
    <div class="column is-2"></div>
   
  </div>
</section>






<a class="anchor" id="services"></a>
<section class="hero is-dark is-medium services-page-image">

<div class="hero-head">
  </div>

  {/* Hero content: will be in the middle */}
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title is-size-4-mobile is-size-1-desktop">
        Our services
      </h1>
      <h2 class="subtitle is-size-6-mobile">
      
      </h2>
        
    </div>
  </div>


</section>




<section class="section">

<div class="columns">
  <div class="column is-1"></div>
  <div class="column">
    <div>
      <h3 class="subtitle" style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_1')}}></h3>
      <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_1_b')}}></div>
    </div>
  </div>
  <div class="column">
    <div>
      <h3 class="subtitle" style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_5')}}></h3>
      <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_5_b')}}></div>
    </div>
  </div>
  <div class="column">
    <div>
      <h3 class="subtitle" style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_2')}}></h3>
      <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_2_b')}}></div>
    </div>
  </div>
  <div class="column">
    <div>
      <h3 class="subtitle" style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_3')}}></h3>
      <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_3_b')}}></div>
    </div>
  </div>
  <div class="column">
    <div>
      <h3 class="subtitle" style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_4')}}></h3>
      <div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: helpers.cnt('srvc_t_4_b')}}></div>
    </div>
  </div>
  <div class="column is-1"></div>
</div>

</section>



       </div>
    )

  }
}


const mapStateToProps$MainPage = function (state) {
   return { reduxDictionary: state.dictionary }
}

const mapDispatchToProps$MainPage = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

export default withRouter(connect(mapStateToProps$MainPage, mapDispatchToProps$MainPage)(MainPage))



