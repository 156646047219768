import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'

import './App.css';

//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'



class Contact extends Component {
   constructor(props) {
      super(props);
  }

  render() {

    return (

          <div id="contact">
  
             <h4 className="contact-item">We are available 24/7 on <b>+994 50 228 48 49</b>. <span className="no-wrap">WhatsApp us now <a href="https://wa.me/994502284849"><img id="whatsapp" src="/media/WhatsApp.svg"/></a></span></h4>
             <h5 className="contact-item">We speak English, Russian and Turkish.</h5>
            
          </div>        

      )

  }

}


export default Contact;