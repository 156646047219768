import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'

import './App.css';

//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

class CancelBooking extends Component {

   constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {loaded: false,
                  booking_id: this.props.match.params.bookid || null,
                  email: this.props.match.params.email || null,
                  lswitch: true,
                  cancel_status: null}
    this.handleClick = this.handleClick.bind(this);
    this.handleClickFind = this.handleClickFind.bind(this);
    this.loadBooking = this.loadBooking.bind(this);

  }

  
  handleClickFind() {
    if (! (this.state.booking_id === this.bookidInput.value && this.state.email ===  this.emailInput.value)) {
      this.setState({booking_id: this.bookidInput.value, email: this.emailInput.value, lswitch: true})
    }
  }

  handleClick(event) {
   let sendData = {booking_id: this.state.booking_id, email: this.state.email, update: [{what: "booking_status", value: "CANCELED", payload: this.state}]}
   helpers.postAPI(
         { func: function(resp) {
              //After receiving response from server
              if (resp.status === 'OK') {
                 this.setState({cancel_status: "OK"})
              } else {
                 helpers.alertMessage('Error message from server: '+resp.data, globals.ERRO);
              }
          }.bind(this), 
           uri: globals.myAPIurl+'/bookupd',
           data: sendData
         })

  }

 
 loadBooking() {
   if (!this.state.loaded && this.state.booking_id && this.state.email && this.state.lswitch) {  
     helpers.getAPI(
         { func: function(resp) {
              this.setState({response_status: resp.status})
              if (resp.status === 'ERROR') {
                 this.setState({lswitch: false})
                 helpers.alertMessage('Error message from server: '+resp.data, globals.ERRO);
              } else if (resp.status === 'NOTFOUND') {
                 this.setState({lswitch: false})
              } else {
                 this.setState({loaded: true})
                 this.setState(resp.booking_summary)
              }
            }.bind(this), 
           uri: globals.myAPIurl+'/bookinfo?bookid='+this.state.booking_id+'&email='+this.state.email
         })

    }
 }


 componentDidMount() {
    this.loadBooking()
  }

 componentDidUpdate() {
    this.loadBooking()
  }


  render() {
   window.scrollTo(0, 0);
   
   let content
   let payment_info
   let header1
   let button

   if (!this.state.booking_id || !this.state.email || this.state.response_status === "NOTFOUND") {

     content = (<div>
      
     <div class="columns">
      <div class="column is-2"></div>
      
      <div class="column is-2">  
       <div class="field" style={{marginLeft:"1rem"}}>
        <label class="label has-text-white-ter is-size-7-touch" style={{margin:"0px"}}>Booking ID</label>
         <div class="control has-text-grey">
            <input type="number" defaultValue={this.state.booking_id} className="input is-large is-size-6-touch is-size-4-desktop"
              ref={(x) => { this.bookidInput = x; }}/>
         </div>
       </div>
      </div>
      

      <div class="column">
       <div class="field" style={{marginLeft:"1rem"}}>
         <label class="label has-text-white-ter is-size-7-touch" style={{margin:"0px"}}>Your e-mail</label>
         <div class="control has-text-grey">
           <input type="email" defaultValue={this.state.email} className="input is-large is-size-6-touch is-size-4-desktop" 
              ref={(x) => { this.emailInput = x; }}/>
         </div>
       </div>
      </div>

      <div class="column is-2"></div>
     </div>

        { (this.state.response_status === "NOTFOUND") &&
           <div class="centered">
             <div class="notification is-danger" style={{width:"70%"}}>No such booking found on the system!</div>
           </div>
          }


         <div className="section" style={{textAlign:"center"}}>
          <button className="button is-large is-light is-rounded is-outlined" 
             onClick={this.handleClickFind} disabled={(this.props.reduxValididy === 0 || this.props.reduxServerStatus === false) ? true : false}>
                <span class="icon is-large"><div><i class="fas fa-search"></i></div></span>
                &nbsp; <span>Find booking</span>
          </button>
         </div>


     </div>)

   } else if (this.state.response_status === "OK") {

       header1 = (
          <div className="section" style={{textAlign:"center"}}>
            <span class="is-size-6-touch is-size-4-desktop">
              Booking Number <b class="booking-id num green">{this.state.booking_id}</b> for client <b>{this.state.email}</b>
            </span>
         </div>
        )

       if (this.state.payment_status === "PAYED") {
          payment_info = (
            <div className="section is-size-5-desktop is-size-6-touch centered" style={{paddingBottom:"1rem"}}>
               <div className="has-background-danger" style={{width:"95%", textAlign:"center", padding:"0.3rem"}}>Please be informed, that this Booking was already payed. The amount payed was {this.state.grand_total.AZN}AZN or 
                 &nbsp;{this.state.grand_total.EUR}EUR. This payment will not be refunded.</div>
            </div>)
       }


       if (this.state.cancel_status === "OK") {
          button = (<div class="section is-size-4-desktop is-size-5-touch" style={{textAlign:"center"}}>
                           <span class="has-background-info" style={{padding:"0.5rem"}}>Booking was successfully canceled.</span></div>)
       } else {
          
           if (this.state.booking_status !== "ACTIVE") {
              button = (<div class="section is-size-4-desktop is-size-5-touch has-text-warning" style={{textAlign:"center"}}>Only active bookings can be canceled.</div>)
           } else {
              button = (<div className="section" style={{textAlign:"center", paddingTop:"2rem"}}>
          <button className="button is-large is-light is-rounded is-outlined" 
             onClick={this.handleClick} disabled={(this.props.reduxValididy === 0 || this.props.reduxServerStatus === false) ? true : false}>
                <span>Cancel booking</span>
          </button>
         </div>)   
           }

       }

       
      

       content = (
        <div>
         
         {header1}
         <div class="centered">
         <div className="pay-status-detail-container" style={{borderStyle: "dashed", margin:"0.5rem"}}>  
            { this.state.create_time && <PrintDetail titel="Booking date:" value={this.state.create_time} /> }
            { this.state.booking_status && <PrintDetail titel="Booking status:" value={this.state.booking_status} /> }
            { this.state.car_type_id && <PrintDetail titel="Car type:" value={this.props.reduxDictionary.cars.find((c)=>c.type_id===this.state.car_type_id).name} /> }
            { this.state.payment_option && <PrintDetail titel="Payment option:" value={this.state.payment_option} /> }
            { this.state.client_name && <PrintDetail titel="Client name:" value={this.state.client_name} /> }
            { this.state.client_phone && <PrintDetail titel="Client phone:" value={this.state.client_phone} /> }
            { this.state.client_country && <PrintDetail titel="Country:" value={this.props.reduxDictionary.countries.find((c)=>c.code===this.state.client_country).name} /> }            
          </div>
          </div>

          {payment_info}

          {button}

       </div>

        )
     }
  



   return ( 
       <div class="hero is-fullheight-with-navbar has-background-success has-text-white-ter" style={{justifyContent:"start"}}>
         <div class="section">
           <h1 class="is-size-5-touch is-size-2-desktop"><b>AZ-TUR Booking cancelation</b></h1>
         </div>

         {content}

         <div className="section"> {} </div>
         
       </div>
    )

  }
}


const mapStateToProps$CancelBooking = function (state) {
   return { reduxDictionary: state.dictionary, 
            reduxValididy: state.valid,
            reduxServerStatus: state.serverStatus }
}

const mapDispatchToProps$CancelBooking = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

CancelBooking = connect(mapStateToProps$CancelBooking, mapDispatchToProps$CancelBooking)(CancelBooking)




class PrintDetail extends Component {
   constructor(props) {
    super(props);
  }
 
  render() {
    
   return ( 
       <div className="pay-status-detail-row-container">
              <div className="pay-status-data-column-item">
                 <div className="css-key-holder num">{this.props.titel}</div>
              </div>
              <div className="pay-status-data-value-item">
                  <div className="css-val-holder num">{this.props.value}</div>
              </div>
            </div>

    )

  }
}







export default CancelBooking



