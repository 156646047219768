import request from 'request'
import Store from './reduxStore.js'
import jsStringEscape from 'js-string-escape'
import * as globals from './globals.js'
import * as Actions from './redux-actions.js'

// parseUrl usage example:
// parseUrl('http://mysite:5050/pke45#23')
//    {origin: "http://mysite:5050", host: "mysite:5050", hostname: "mysite", pathname: "/pke45", port: "5050"…}
// parseUrl('http://mysite:5050/pke45#23', 'origin')
//   "http://mysite:5050"
export const parseUrl = (string, prop) =>  {
  const a = document.createElement('a'); 
  a.setAttribute('href', string);
  const {host, hostname, pathname, port, protocol, search, hash} = a;
  const origin = `${protocol}//${hostname}${port.length ? `:${port}`:''}`;
  return prop ? eval(prop) : {origin, host, hostname, pathname, port, protocol, search, hash}
}


var isMessageVisible = function () {
  return Store.getState().alert && Store.getState().alert.visible === true
}

export var alertMessage = function (message, type=globals.INFO, visible=true, hidein) {
 if (isMessageVisible()) { return } 
  let timeOut
  if (hidein > 0) {
    timeOut = setTimeout(closeMessage, hidein)
  }
  Store.dispatch(Actions.actionSetAlert({type: type, visible: visible, message: message, timeout: timeOut}))
}

export var closeMessage = function (toId) {
  Store.dispatch(Actions.actionSetAlert({visible: false}))
}


export var setServerOFF = function () {
  Store.dispatch(Actions.actionSetServerStatus(false))
}

export var setServerON = function () {
  Store.dispatch(Actions.actionSetServerStatus(true))
}


// getAPI is function used to get data from API, all data passed in JSON format.
// Function gets as argument "action" which is objects of next form:
//   {func: function(data, response, error) { actions with data}, uri: 'some uri', showLastMessage: true || false}
// For each new action handler must be added in this function.
var stopper = {}
export var getAPI = function (action, rCounter=0, retryCount=globals.apiReqRetry, retryTimeOut=globals.apiReqTimeout) {
	if (rCounter < retryCount ) {
	console.log('Getting data from ' + action.uri +', try:', rCounter);
	request
           .get({uri: action.uri, json: true},
           	   function (error, response, data) {
           	   	  if (error) {
                       parseUrl(action.uri, 'origin') === globals.myServer && setServerOFF() 
           	   	  	   console.log('Cannot get data from API, Error: ', error || response.body);
                       closeMessage()
                       alertMessage('Cannot get data from the server, will try after ' + retryTimeOut(rCounter)/1000 + ' sec. Try number: ' + rCounter, globals.ERRO)
                       rCounter++
                       // Executing recursively  with timeout. Passing arguments in setTimeout
                       setTimeout(getAPI, retryTimeOut(rCounter), action, rCounter, retryCount, retryTimeOut)
           	   	  	   return false;
           	   	  	} else {
                       parseUrl(action.uri, 'origin') === globals.myServer && setServerON() 
           	   	  	   closeMessage()
           	   	  	   action.func(data, response)
                       return true
                  } 
           	   })
      
    } else
      {
           if (action.showLastMessage === undefined || action.showLastMessage === true) {
              alertMessage('Cannot get data from the server after all ' + retryCount + ' tries! Please try the operation later or contact us '+globals.myEmail+'.', globals.ERRO)
           } else {
                closeMessage()
           }         
           console.log('RETRIES FINISHED!')
           parseUrl(action.uri, 'origin') === globals.myServer && setServerON() 
           action.func(null, null, 'FAILED')

       }
    
}



export var postAPI = function (action, rCounter=0, retryCount=globals.apiReqRetry, retryTimeOut=globals.apiReqTimeout) {
  if (rCounter < retryCount ) {
  console.log('Putting data to ' + action.uri +', try:', rCounter);
  request
           .post({url: action.uri, body:action.data, json: true},
               function (error, response, data) {
                  if (error) {
                       parseUrl(action.uri, 'origin') === globals.myServer && setServerOFF() 
                       console.log('Cannot put data to API, Error: ', error || response.body);
                       console.log('Try', rCounter)
                       closeMessage()
                       alertMessage('Cannot put data to the server, will try after ' + retryTimeOut(rCounter)/1000 + ' sec. Try number: ' + rCounter, globals.ERRO)
                       rCounter++
                       // Executing recursively  with timeout. Passing arguments in setTimeout
                       setTimeout(postAPI, retryTimeOut(rCounter), action, rCounter, retryCount, retryTimeOut)
                       return false;
                    } else {
                       parseUrl(action.uri, 'origin') === globals.myServer && setServerON() 
                       closeMessage()
                       action.func(data, response)
                       return true
                  } 
               })
      
    } else
      {
           if (action.showLastMessage === undefined || action.showLastMessage === true) {
              alertMessage('Cannot put data to the server after all ' + retryCount + ' tries! Please try the operation later or contact us '+globals.myEmail+'.', globals.ERRO)
           } else {
                closeMessage()
           }         
           console.log('RETRIES FINISHED!')
           parseUrl(action.uri, 'origin') === globals.myServer && setServerON() 
           action.func(null, null, 'FAILED')

       }
    
}






export var sleep = function(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}


// Remove element from array by value.
export var delElement = function (arr, val, key=null){
  
  if ( key != null ) {
     for(var i = arr.length - 1; i >= 0; i--) {
        if(arr[i][key] === val) {
          arr.splice(i, 1);
        }
     }
  }
  
}


export var printREDUXStore = function () {
  //Store.dispatch(Actions.actionRemoveCurrentChecklist())
  console.log(Store.getState())
}


export var getGeneralParameterValue = function (param) {
  let Dictionary
  if (Store.getState().dictionary) {
          Dictionary = Store.getState().dictionary } else { return null }
  if (Dictionary.general_parameters) {
      let RequiredParameterObject = Dictionary.general_parameters.find(e=>e.name===param)
      if (RequiredParameterObject !== undefined) {
         return RequiredParameterObject.value
      } else {
         return null
      }
      
  } else {
      return null
  }

}



export var calculateBookingSum = function (vState) {
  let Sum = {
    transfer_prices: []
  }
  let Booking
  let Dictionary 
  if (typeof vState !== 'undefined') {
     Booking = vState.booking
     Dictionary = vState.dictionary
  } else {
       if (Store.getState().booking) {
          Booking = Store.getState().booking 
          Dictionary = Store.getState().dictionary
          } else { return null }
  }
  let Transfer
  let TransferPrice
  let TransferPriceManualCalculationRequired = 0
  let TransferSummaryPriceAZN = 0
  let TransferSummaryPriceEUR = 0
  let PriceObject = {}
  let RegionID
  if (typeof Booking.destinations === 'undefined') {return null}
  Booking.transfer_types_selected.forEach(transfer_type => {
     PriceObject = {}
     PriceObject.transfer_type_id = transfer_type

     Transfer = Booking.destinations.find(i=>i.transfer_type_id==transfer_type)
     if (typeof Transfer === 'undefined') {return null}

     switch (transfer_type) {
       case 1: 
         
         TransferPrice = Dictionary.transfer_price.find(i=>i.ttype_id==transfer_type && i.region_id==Transfer.destination_region && i.car_type==Booking.car_type)
         if (typeof TransferPrice === 'undefined') {
            TransferPriceManualCalculationRequired++
            PriceObject.price_to = -1
         } else {
            TransferSummaryPriceAZN += TransferPrice.price_azn
            TransferSummaryPriceEUR += (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
            PriceObject.price_to = {AZN: TransferPrice.price_azn, EUR: (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))}
         }

         if (Transfer.return_trip === "YES") {
               RegionID = (Transfer.return_same_dest === "YES") ? Transfer.destination_region : Transfer.return_region
               TransferPrice = Dictionary.transfer_price.find(i=>i.ttype_id==2 && i.region_id==RegionID && i.car_type==Booking.car_type)
               if (typeof TransferPrice === 'undefined') {
                  TransferPriceManualCalculationRequired++
                  PriceObject.price_from = -1
               } else {
                 TransferSummaryPriceAZN += TransferPrice.price_azn
                 TransferSummaryPriceEUR += (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
                 PriceObject.price_from = {AZN: TransferPrice.price_azn, EUR: (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))}                
               }
         }
         
         Sum.transfer_prices.push(PriceObject)
         break;

       case 2: 
         
         TransferPrice = Dictionary.transfer_price.find(i=>i.ttype_id==transfer_type && i.region_id==Transfer.pickup_region && i.car_type==Booking.car_type)

         if (typeof TransferPrice === 'undefined') {
            TransferPriceManualCalculationRequired++
            PriceObject.price_to = -1
         } else {
            TransferSummaryPriceAZN += TransferPrice.price_azn
            TransferSummaryPriceEUR += (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
            PriceObject.price_to = {AZN: TransferPrice.price_azn, EUR: (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))}
         }

         Sum.transfer_prices.push(PriceObject)
         break;

       case 3: 
         
         if ((Transfer.pickup_region==1 || Transfer.destination_region==1) && (Transfer.pickup_region!==Transfer.destination_region)) {


            RegionID = Transfer.pickup_region==1 ? Transfer.destination_region : Transfer.pickup_region

            TransferPrice = Dictionary.transfer_price.find(i=>i.ttype_id==1 && i.region_id==RegionID && i.car_type==Booking.car_type)
            if (typeof TransferPrice === 'undefined') {
               TransferPriceManualCalculationRequired++
               PriceObject.price_to = -1
            } else {
               TransferSummaryPriceAZN += TransferPrice.price_azn
               TransferSummaryPriceEUR += (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
               PriceObject.price_to = {AZN: TransferPrice.price_azn, EUR: (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))}
            }
   
            if (Transfer.return_trip === "YES" && (Transfer.return_region==1 || Transfer.pickup_region==1)) {
                  RegionID = Transfer.pickup_region===1 ? (Transfer.return_region ? Transfer.return_region : Transfer.destination_region) : Transfer.pickup_region
                  TransferPrice = Dictionary.transfer_price.find(i=>i.ttype_id===1 && i.region_id===RegionID && i.car_type===Booking.car_type)

                  if (typeof TransferPrice === 'undefined') {
                     TransferPriceManualCalculationRequired++
                     PriceObject.price_from = -1
                  } else {
                    TransferSummaryPriceAZN += TransferPrice.price_azn
                    TransferSummaryPriceEUR += (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
                    PriceObject.price_from = {AZN: TransferPrice.price_azn, EUR: (Dictionary.use_current_rates != "yes") ? TransferPrice.price_eur : Math.ceil((TransferPrice.price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))}                
                  }
            }
         
         } else {
             TransferPriceManualCalculationRequired++
             PriceObject.price_to = -1
             PriceObject.price_from = -1
         }

         Sum.transfer_prices.push(PriceObject)
         break;

     }
  })

if (TransferPriceManualCalculationRequired > 0) {
  Sum.manual_price_calc = "YES"
} else {
  Sum.manual_price_calc = "NO"
}

let grandTotalAZN = (TransferSummaryPriceAZN > 0) ? TransferSummaryPriceAZN : 0
let grandTotalEUR = (TransferSummaryPriceEUR > 0) ? TransferSummaryPriceEUR : 0

if (Booking.simcards) {
let SimPriceSummaryAZN=0
let SimPriceSummaryEUR=0
Booking.simcards.forEach(simcard => {
   let dic_price_azn = Dictionary.sim_cards.find(i=>i.id===simcard.id).price_azn
   let dic_price_eur = Dictionary.sim_cards.find(i=>i.id===simcard.id).price_eur
   SimPriceSummaryAZN += simcard.count * dic_price_azn
   SimPriceSummaryEUR += simcard.count * (Dictionary.use_current_rates != "yes") ? dic_price_eur : Math.ceil((dic_price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
})

Sum.simcards = {AZN: SimPriceSummaryAZN, EUR: SimPriceSummaryEUR}

grandTotalAZN += SimPriceSummaryAZN
grandTotalEUR += SimPriceSummaryEUR

}

if (Booking.additional_options) {
let AddOptPriceSummaryAZN=0
let AddOptPriceSummaryEUR=0
Booking.additional_options.forEach(addOpt => {
   let dic_price_azn = Dictionary.additional_options_price.find(i=>i.car_type===addOpt.car_type && i.opt_id===addOpt.opt_id).price_azn
   let dic_price_eur = Dictionary.additional_options_price.find(i=>i.car_type===addOpt.car_type && i.opt_id===addOpt.opt_id).price_eur
   AddOptPriceSummaryAZN += dic_price_azn
   AddOptPriceSummaryEUR += (Dictionary.use_current_rates != "yes") ? dic_price_eur : Math.ceil((dic_price_azn / Dictionary.currency_exchange_rates.eur_price) * (1 + Dictionary.currency_exchange_rates.add_pct/100))
})

Sum.additional_options = {AZN: AddOptPriceSummaryAZN, EUR: AddOptPriceSummaryEUR}

grandTotalAZN += AddOptPriceSummaryAZN
grandTotalEUR += AddOptPriceSummaryEUR

}

Sum.grand_total = {AZN: grandTotalAZN, EUR: grandTotalEUR}

return Sum

}




export var animateBookingPage = function (welcome, body, ms) {
     if (! bookingStorage(welcome)) {
       let typer = document.getElementById(welcome);
       let typewriter = setupTypewriter(typer);
       typewriter.type();
       bookingStorage(welcome,'set',true)

       if (typeof body === "string") {
          let fadein = document.getElementById(body);
          fadein.style.visibility="hidden"
          setTimeout(function () {
               fadein.style.visibility = "visible";
                }, ms);
       } else {
           body.forEach(b=>{
             document.getElementById(b).style.visibility="hidden"
           })
           
             setTimeout(function () {
               body.forEach(b=>{
                 document.getElementById(b).style.visibility="visible"
               })
                }, ms);
      }
    }
}



/*
var typer = document.getElementById('typewriter');
typewriter = setupTypewriter(typer);
typewriter.type();
*/

export var setupTypewriter = function(t) {
      var HTML = t.innerHTML;

      t.innerHTML = "";

      var cursorPosition = 0,
          tag = "",
          writingTag = false,
          tagOpen = false,
          typeSpeed = 10,
          tempTypeSpeed = 0;

      var type = function() {
        
          if (writingTag === true) {
              tag += HTML[cursorPosition];
          }

          if (HTML[cursorPosition] === "<") {
              tempTypeSpeed = 0;
              if (tagOpen) {
                  tagOpen = false;
                  writingTag = true;
              } else {
                  tag = "";
                  tagOpen = true;
                  writingTag = true;
                  tag += HTML[cursorPosition];
              }
          }
          if (!writingTag && tagOpen) {
              tag.innerHTML += HTML[cursorPosition];
          }
          if (!writingTag && !tagOpen) {
              if (HTML[cursorPosition] === " ") {
                  tempTypeSpeed = 0;
              }
              else {
                  tempTypeSpeed = (/*Math.random() * */typeSpeed) //+ 50;
              }
              t.innerHTML += HTML[cursorPosition];
          }
          if (writingTag === true && HTML[cursorPosition] === ">") {
              tempTypeSpeed = (/*Math.random() * */typeSpeed) //+ 50;
              writingTag = false;
              if (tagOpen) {
                  var newSpan = document.createElement("span");
                  t.appendChild(newSpan);
                  newSpan.innerHTML = tag;
                  tag = newSpan.firstChild;
              }
          }

          cursorPosition += 1;
          if (cursorPosition < HTML.length - 1) {
              setTimeout(type, tempTypeSpeed);
          }

      };

      return {
          type: type
      };
  }






export var bookingStorage = function (key, op='get', val) {
  let booking_storage = {}
  let tmp
  switch (op) {
     case "get":
          tmp = window.sessionStorage.getItem('booking_storage')
          if (tmp) {
             booking_storage = JSON.parse(tmp)
             return booking_storage[key]
          }
       break;
     case "set":
         tmp = window.sessionStorage.getItem('booking_storage')
         if (tmp) {
             booking_storage = JSON.parse(tmp)           
          } 
          booking_storage[key] = val
          window.sessionStorage.setItem('booking_storage', JSON.stringify(booking_storage))
       break;
     case "destroy":
         window.sessionStorage.removeItem('booking_storage')
       break;
  }
  
}




export var onLoad = function() {

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}


  if ( ! window.sessionStorage.getItem('client_country_code') ) {
  request.get({uri: 'https://api.ipify.org?format=json', json: true},
               function (error, response, data) {


              request.get({uri:'https://api.ipgeolocation.io/ipgeo?apiKey=7e3e04f084b14f028fc5be721fe00648&ip='+data.ip, json: true},
                        function (error2, response2, data2) {
                                   window.sessionStorage.setItem('client_country_code', data2.country_code2)
                                   console.log('Country:', data2.country_code2)
                       }) 
                                

                  })



  }

}



export var validate = function(what, value) {
  let RE
  switch (what) {
    case 'full_name': case 'name': case 'startDate': case 'endDate': case 'pickup_date': case 'pickup_time': case 'destination': case 'return_from': case 'pickup_location':
         if (! value || value.length <= 0 || value.length === "null") {
           return false
         } else {
           return true
         }
      break;

    case 'mail':
        RE=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return RE.test(value)
     break;
    
    case 'phone':
        RE=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
        return RE.test(value) && value.length >= 10
      break;

    case 'flight_number':
        RE=/^[A-Za-z0-9]+$/g
        return RE.test(value)
      break;


    default:
      return true 
  }

}


// To print the text content. id is character!
export var cnt = function(id) {
  let content = Store.getState().dictionary.content
  return content.find((c)=>c.content_id===id).content
}
