import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import request from 'request'
import { DisappearedLoading } from 'react-loadingg';


//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

import ListCar from './ListCar.jsx'



class CarList extends Component {
   constructor(props) {
    super(props);

  }



  render() {
  
   let content

 
   if (typeof this.props.reduxDictionary === 'undefined') {
       content = <div><DisappearedLoading color="#000000" size="large"/></div>
     } 
   else {
       let cars = this.props.reduxDictionary.cars
       content = cars.map( car => {
         return (<ListCar typeid={car.type_id} tobook/>)
      })
   
   }
 
   return ( 
       <div>

           {content}

       </div>
     )

  }
}


const mapStateToProps$CarList = function (state) {
   return { reduxDictionary: state.dictionary }
}

const mapDispatchToProps$CarList = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) }
  }
}

CarList = connect(mapStateToProps$CarList, mapDispatchToProps$CarList)(CarList)


export default CarList



