import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import './index.css';
import queryString from 'query-string';

import * as globals from './globals.js'
import * as helpers from './helpers.js'

import MessageBar from './MessageBar.jsx'
import PopupManager from './PopupManager.jsx'

import Store from './reduxStore.js'
import * as Actions from './redux-actions.js'
import { connect } from 'react-redux'
import { DisappearedLoading } from 'react-loadingg';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";

import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import Payment from './Payment.jsx'
import CancelBooking from './CancelBooking.jsx'
import PayConfirm from './PayConfirm.jsx'
import MainPaig from './MainPaig.jsx'
import ATForm from './ATForm.jsx'
import { Summary, Confirmation } from './FormInputComponents.jsx'
import ContactBanner from './ContactBanner.jsx'
import ContactPage from './ContactPage.jsx'
import RegionsPage from './RegionsPage.jsx'
import OnlineGuidePage from './OnlineGuidePage.jsx'
import GalleryPage from './GalleryPage.jsx'


class App extends Component {
  constructor(props) {
    super(props);
    this.handleBurgerClick = this.handleBurgerClick.bind(this);
  }


  UNSAFE_componentWillMount() {
       helpers.onLoad()
       helpers.getAPI(
         { func: function(resp) {
                    Store.dispatch(Actions.actionLoadDictionary(resp))
                    if (globals.ENV === 'dev' ) console.log('DEBUG', 'DICTIONARY', resp); 
                }, 
           uri: globals.myAPIurl+'/getdict'
         })

  }


  handleBurgerClick(e) {
     this.navbarBurger.classList.toggle('is-active')
     this.navbarMenuHero.classList.toggle('is-active') 

  }

  render() {


   if (! this.props.reduxDictionary) {
      return (<div>
                 <PopupManager />
                 <MessageBar alert={{visible: false}}/>
          <div style={{paddingBottom:"5rem"}}><DisappearedLoading color="#000000" size="large" /></div>
          </div>)
   }
 

    return (
      <div class="main-page-content">
      <a class="anchor" id="top"></a>
       

      <div class="icon-bar">
        <a href='https://wa.me/994502284849'>
          <figure class="image is-rounded" style={{maxWidth: "3rem", height: "auto"}}>
                <img src="https://aztur-global.s3.eu-central-1.amazonaws.com/WhatsApp.svg" />
          </figure>
          <div class="has-text-centered has-text-light is-size-7"><b>24/7</b></div>
        </a>
      </div>

      <PopupManager />
      <MessageBar alert={{visible: false}}/>
 
      <Router>


    <nav class="navbar is-fixed-top is-black is-spaced">
      <div class="container">
        <div class="navbar-brand">
          <div class="navbar-item">
             <Link to="/" style={{color:"white"}}><span class="logo-text">Az-Tur</span></Link>
          </div>
          <span class="navbar-item">
             <a className="button is-link is-small is-rounded" href='tel:+994502284849'>
                <b>
                 <span class="icon"><i class="fas fa-mobile-alt"></i></span>
                 <span>+994502284849</span>
                </b>
             </a>
          </span>

          <span class="navbar-burger burger" data-target="navbarMenuHero" aria-label="menu" aria-expanded="false" ref={e => {this.navbarBurger = e}} onClick={this.handleBurgerClick} >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div id="navbarMenuHero" class="navbar-menu has-background-transparent" ref={e => {this.navbarMenuHero = e}}>
          <nav class="navbar-end">
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/#top" onClick={this.handleBurgerClick}>
              Home
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/online-guide" onClick={this.handleBurgerClick}>
              Online Guide <span class="tag is-danger is-normal" style={{marginLeft: "5px"}}>New</span>
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/regions" onClick={this.handleBurgerClick}>
              Regions
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/gallery" onClick={this.handleBurgerClick}>
              Gallery
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/#services" onClick={this.handleBurgerClick}>
              Services
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/cancel" onClick={this.handleBurgerClick}>
              Cancelation
            </NavLink>
              <NavLink className="navbar-item" style={{textAlign:"center"}} to="/#about" onClick={this.handleBurgerClick}>
              About
            </NavLink>
            <NavLink className="navbar-item" style={{textAlign:"center"}} to="/contact" onClick={this.handleBurgerClick}>
              Contact us
            </NavLink>

            <div class="only-touch">
             <footer class="footer has-background-transparent centered">
               <ContactBanner />
             </footer>
            </div>
          </nav>
        </div>
      </div>
    </nav>



  
        <Switch>
          <Route path="/payme/:sum?/:curr?/:bookid?/:email?/:service?" component={Payment} />
          <Route path="/cancel/:bookid?/:email?" component={CancelBooking} />
          <Route path="/paycf" component={PayConfirm} />
          <Route path="/atform" component={ATForm} />
          <Route path="/bookcf" component={Confirmation} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/regions" component={RegionsPage} />
          <Route path="/online-guide" component={OnlineGuidePage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/" component={MainPaig} />

        </Switch>          
      </Router>



  <footer class="footer has-background-dark">
    <div class="container">
     <div class="content has-text-centered has-text-light">
    <p>
      Created by <b>Az-Tur Azerbaijan (c)</b>
    </p>
    </div>
  </div>

  
  <div class="section centered has-text-light">
        <ContactBanner />
  </div>
   
  <div class="container">
    <div class="centered">
      <div style={{maxWidth:"300px"}}>
        <div id="TA_selfserveprop974" class="TA_selfserveprop"><ul id="XwhtPK" class="TA_links WOCYDsWk"><li id="fk6vGl" class="z6jx43Rbh"><a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g293934-d15017775-Reviews-AZ_Tur-Baku_Absheron_Region.html"><img src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg" alt="TripAdvisor"/></a></li></ul></div>
      </div>
    </div>
  </div>

  </footer>

      

      </div>
    );
  }
}


const mapStateToProps$App = function (state) {
   return { reduxDictionary: state.dictionary }
}

export default connect(mapStateToProps$App, null)(App)



