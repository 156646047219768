import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Actions from './redux-actions.js'
import moment from 'moment'
import request from 'request'
import queryString from 'query-string';

//import './App.css';

//import { Link, Route } from 'react-router-dom'
import * as globals from './globals.js'
import * as helpers from './helpers.js'

import Contact from './Contact.jsx'


class PayConfirm extends Component {
   
   constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);

    this.reference = queryString.parse(this.props.location.search).reference
    this.respFromAPI = null
    this.state = {processing: true, sendConfirmation: 0}

  }


  handleClick(event) {
    this.setState({sendConfirmation: 1})
  }
   

  handleSendClick(event) {
    let bgdef = this.emailInputField.style.backgroundColor
    if (! this.emailInputField.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.emailInputField.focus();
       this.emailInputField.style.backgroundColor="#ff9b96"
       setTimeout(()=>(this.emailInputField.style.backgroundColor=bgdef), 300)
      helpers.alertMessage('Enter correct value for email', globals.WARN, true, 5000)
      return false
    }

    helpers.getAPI(
         { func: function(body, response, error) {
             if (response.statusCode === 200) {  
              helpers.alertMessage('Payment confirmation was sent to '+this.emailInputField.value+'. Check also your spam folder if not received.', globals.INFO, true, 15000)
              this.setState({sendConfirmation: 2})
            } else if (response.statusCode === 900) {
              helpers.alertMessage('Payment confirmation already sent on '+body.sent_on, globals.WARN, true)
              this.setState({sendConfirmation: 2})
            } else if (response.statusCode === 901) {
              helpers.alertMessage('Failed to send confirmation email. Check your email address, is it correct?', globals.ERRO, true)
            } else {
              helpers.alertMessage('Failed to send confirmation email. We will check the issue. You can try sending again or contact us using our contact details.', globals.ERRO)               
            }
              
           }.bind(this), 
           uri: globals.myAPIurl+'/paymail?reference='+this.reference+'&mail='+this.emailInputField.value
         })


/*
     request.get({uri: 'https://az-tur.sigvamo.com/testapi/pay?sum='+Sum+'&curr='+Curr},
               function (error, response) {
                  if (error) { 
                       console.log('Cannot get data from API, Error: ', error);
                       return false
                    }

                  if (response.statusCode === 200) {
                       console.log('Received URL', response.body);
                       window.location.href = response.body;

                  } else {
                       console.log('Cannot get data from API: ', response.body)
                       return false
                  }
               })  */


  }


//  componentDidMount() {
//     this.reference = queryString.parse(this.props.location.search).reference
//     if (gloabls.ENV === 'dev') console.log('DEBUG', this.reference)
//  }

  componentDidMount(prevProps, prevState) {
      
      helpers.getAPI(
         { func: function(resp, r, error) {
               if (error) {
                  console.log('Failed', error)
                  return false
               }
               this.respFromAPI = resp.data;
               this.setState({processing: false})              
             }.bind(this), 
           uri: globals.myAPIurl+'/paystatus?reference=' + this.reference
         })
  

  /* Repeat with interval and timeout
     var retryCount = 2
     var retries = 1
     var getPayStatus = function() {
       var result = null
       helpers.getAPI(
         { func: function(resp, error) {
              
              console.log('REP=', resp)
              if (! resp) {

                 console.log('REP=', resp)
                 
                 if (retries <= retryCount) {
                   retries++
                   setTimeout(getPayStatus.bind(this), 500)
                 } else {
                     console.log('All attempts to get data failed!')
                 }

              }

        }, 
           uri: globals.paymentAPIurl+'/gateway/payment/status?mid=aztur&reference=233',
           showLastMessage: false
         })
     };

    getPayStatus() */

  }


  componentWillUnmount() {
    if (globals.ENV === 'dev') console.log('DEBUG', 'CLEAR BOOKING')
    this.props.clearBookingInfo()
  }


  render() {
   window.scrollTo(0, 0);
    
   this.bookingInfo = this.props.reduxBookingInfo
   let content = null

   if (this.state.processing === true) {
      content = <div className="div-center"><h1>Processing . . .</h1></div>
   } else {
      switch (this.respFromAPI.code) {
        case -1:
                content = (<div className="pay-status-body-container">
                             <div className="section pay-status-header-container">
                                <h1 className="pay-status-header-notok is-size-3-desktop is-size-6-touch">No related payment found.</h1>
                                <h3>Please, contact us on {globals.myEmail}</h3>
                             </div>
            
                           </div>)

                break;
         
         case 0: 
                let mail_confirmation

                if (this.bookingInfo) {
                  mail_confirmation = (
                      <div><p className="green-text text-darken-4">Mail confirmation was sent to your email <b>{this.bookingInfo.client_email}</b></p></div>
                  )
                
                } else {
                  mail_confirmation = (<div className="section" style={{width:"100%", maxWidth:"500px"}}>
                    {this.state.sendConfirmation === 1 &&
                                <div>
                                  <div className="field">
                                   <input name="email" type="email" className="input is-medium is-rounded has-text-grey is-size-6-touch" style={{textAlign:"center"}} 
                                                          ref={(x) => { this.emailInputField = x; }} />
                                  </div>
                                  <div className="field" style={{textAlign:"center", marginTop:"2rem"}}>
                                  <button className="button is-large is-light is-rounded is-outlined" onClick={this.handleSendClick}>
                                     <span class="icon is-large"><i class="far fa-paper-plane"></i></span>
                                 &nbsp; <span>Send</span>
                                   </button>
                                   </div>


                                </div> }
                             { this.state.sendConfirmation === 0 && <div><p><a className="a-simple green-text text-darken-4" onClick={this.handleClick}>Click to get email confirmation.</a></p>
                                </div> }
                    </div>
                  )
                }


                content = (<div className="pay-status-body-container">
                             <div className="section pay-status-header-container">
                                <h1 className="pay-status-header-ok is-size-3-desktop is-size-6-touch">Payment was successfully processed.</h1>
                             </div>
                              
                             <PaymentDetails timestamp={this.respFromAPI.timestamp} 
                                             amount={this.respFromAPI.amount} 
                                             currency={this.respFromAPI.currency}
                                             card={this.respFromAPI.pan} />
            
                             {mail_confirmation}

                           </div>
                           
                           )

                break;      

        case 1 || 4: 
                
                content = (<div className="pay-status-body-container">
                             <div className="section pay-status-header-container">
                                <h1 className="pay-status-header-notok is-size-3-desktop is-size-6-touch">Payment failed to be processed.</h1>
                                {this.respFromAPI.RC === 101 && <h3>Your card is expired.</h3>}
                                {this.respFromAPI.RC === 119 && <h3>Payment was declined because it is not permitted to cardholder.</h3>}
                                {this.respFromAPI.RC === 100 && <h3>Payment was declined.</h3>}
                             </div>
                              
                             <PaymentDetails timestamp={this.respFromAPI.timestamp} 
                                             amount={this.respFromAPI.amount} 
                                             currency={this.respFromAPI.currency}
                                             card={this.respFromAPI.pan} />
            
                             <h3>You can try the payment again <a className="a-simple" href={'/payme/'+this.respFromAPI.amount/100+'/'+globals.getCurrency(this.respFromAPI.currency)}>Pay again</a></h3>


                           </div>)

                break;


        case 7: 
                content = (<div className="pay-status-body-container">
                             <div className="section pay-status-header-container">
                                <h1 className="pay-status-header-notok is-size-3-desktop is-size-6-touch">Payment timed out.</h1>
                                {this.respFromAPI.RC === 101 && <h3>Your card is expired.</h3>}
                                {this.respFromAPI.RC === 119 && <h3>Payment was declined because it is not permitted to cardholder.</h3>}
                                {this.respFromAPI.RC === 100 && <h3>Payment was declined.</h3>}
                                <h2>You can try the payment again <a className="a-simple" href={'/payme/'+this.respFromAPI.amount/100+'/'+globals.getCurrency(this.respFromAPI.currency)}>Pay again</a></h2>
                             </div>
                              
                             <PaymentDetails amount={this.respFromAPI.amount} 
                                             currency={this.respFromAPI.currency}
                                             timestamp={this.respFromAPI.timestamp}
                                             />
            
                           </div>)

                break;        

         case 2: 
                content = (<div className="pay-status-body-container">
                             <div className="section pay-status-header-container">
                                <h1 className="pay-status-header-notok is-size-3-desktop is-size-6-touch">Payment is still not completed.</h1>
                                {this.respFromAPI.RC === 101 && <h3>Your card is expired.</h3>}
                                {this.respFromAPI.RC === 119 && <h3>Payment was declined because it is not permitted to cardholder.</h3>}
                                {this.respFromAPI.RC === 100 && <h3>Payment was declined.</h3>}
                                <h3>Please, refresh this page after few minutes or contact us on {globals.myEmail}</h3>
                             </div>
                              
                             <PaymentDetails amount={this.respFromAPI.amount} 
                                             currency={this.respFromAPI.currency}
                                             card={this.respFromAPI.pan} />
            
                           </div>)

                break;               

         default:
               content = <h1>{this.respFromAPI.code}</h1> 

     
      }

   }

   return ( 
       <div class="hero is-fullheight-with-navbar has-background-success has-text-white-ter" style={{justifyContent:"start"}}>
         
         {content}
         
         <Contact />
       </div>
    )

  }
}


const mapStateToProps$PayConfirm = function (state) {
   return { reduxCurrency: state.currency, 
            reduxSum: state.sum,
            reduxBookingInfo: state.booking_info,  
            reduxValididy: state.valid,
            reduxServerStatus: state.serverStatus }
}

const mapDispatchToProps$PayConfirm = function(dispatch) {
  return {
    setValidity: function(valid) { dispatch(Actions.actionSetValidity(valid)) },
    clearBooking: function() { dispatch(Actions.actionClearBooking())},
    clearBookingInfo: function() { dispatch(Actions.actionClearBookingInfo()) }
  }
}







class PaymentDetails extends Component {
   constructor(props) {
      super(props);
  }

  render() {

    return (

          <div className="pay-status-detail-container">
  
            { this.props.timestamp &&
            <div className="pay-status-detail-row-container">
              <div className="pay-status-data-column-item">
                 <div className="css-key-holder num">Timestamp:</div>
              </div>
              <div className="pay-status-data-value-item">
                  <div className="css-val-holder num">{moment(this.props.timestamp, 'YYYYMMDDHHmmss', true).format('DD.MM.YYYY HH:mm:ss')}</div>
              </div>
            </div>
            }

            { this.props.amount &&
            <div className="pay-status-detail-row-container">
              <div className="pay-status-data-column-item">
                 <div className="css-key-holder num">Amount:</div>                                            
              </div>
              <div className="pay-status-data-value-item">
                  <div className="css-val-holder num">{this.props.amount/100} {globals.getCurrency(this.props.currency)}</div>
              </div>
            </div>
            }
            
            { this.props.card &&
            <div className="pay-status-detail-row-container">
              <div className="pay-status-data-column-item">
                 <div className="css-key-holder num">Card:</div>
              </div>
              <div className="pay-status-data-value-item">
                  <div className="css-val-holder num">{this.props.card}</div>
              </div>
            </div>
            }

          </div>        

      )

  }

}


export default connect(mapStateToProps$PayConfirm, mapDispatchToProps$PayConfirm)(PayConfirm)

